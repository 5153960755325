import useMyStore from '@/stores/me/my'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default function setupBugsnag(app) {
    if (! import.meta.env.VITE_BUGSNAG_API_KEY) return
    
    Bugsnag.start({
        appVersion: import.meta.env.VITE_RELEASE_COMMIT,
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        releaseStage: import.meta.env.VITE_BUGSNAG_ENV,
        plugins: [ new BugsnagPluginVue() ],
        onError: event => {
            let ignoredErrors = [ 'AlertCancelled', 'SessionLostError' ]
            if (ignoredErrors.includes(event.originalError)) return false

            event.setUser(useMyStore().user?.id, useMyStore().user?.email, useMyStore().user?.name)
        }
    })

    app.use(Bugsnag.getPlugin('vue'))
}
