import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const defineStreamMediaLightbox = settings => {
    return defineStore(settings.id, {
        state: () => ({
            name: settings.name,

            regions: [],
            sidebar: true,

            media: null,
            currentIndex: 0,
            content: null,

            showRawMetadata: false,

            inline: false,

            onClose: () => {},

            pendingDetailsRequests: {},
            completedDetailsRequests: {}
        }),

        getters: {
            currentMedia(store) { return store.media ? store.media[store.currentIndex] : {} },
            isFirst(store) { return store.currentIndex == 0 },
            isLast(store) { return store.media && store.currentIndex == store.media.length - 1 }
        },

        actions: {
            open(media, index, content, options = {}) {
                this.media = [ ...media ]
                this.currentIndex = index
                this.content = content

                this.onClose = options?.onClose || (() => {})

                useModal().show(this.name)

                this.loadCurrentMediaDetails()
            },

            close() {
                this.abortMediaDetailsLoading()
                this.onClose()
                this.$reset()
            },

            showInline(media) {
                this.inline = true
                this.media = media

                this.loadCurrentMediaDetails()
            },

            next() {
                if (this.currentIndex + 1 <= this.media.length - 1) {
                    this.currentIndex++
                    this.loadCurrentMediaDetails()
                }
            },

            previous() {
                if (this.currentIndex - 1 >= 0) {
                    this.currentIndex--
                    this.loadCurrentMediaDetails()
                }
            },

            loadCurrentMediaDetails() {
                let id = this.currentMedia.id
                let index = this.currentIndex

                if (! id || this.pendingDetailsRequests[id] || this.completedDetailsRequests[id]) return

                this.pendingDetailsRequests[id] = api.route('monitor media details', id).get().controller()
                this.pendingDetailsRequests[id][1]
                    .json(res => {
                        this.media[index] = { ...this.media[index], ...res.data, text: this.media[index].text }
                        this.completedDetailsRequests[id] = true
                    })
                    .then(() => delete this.pendingDetailsRequests[id])
            },

            abortMediaDetailsLoading() {
                Object.values(this.pendingDetailsRequests).forEach(r => r[0].abort())
            }
        }
    })
}

export default defineStreamMediaLightbox
