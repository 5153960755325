import { defineStore } from 'pinia'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js"
import NodesTableActions from "@/components/connections/partials/nodes-table-actions.vue"
import EdgesTableActions from "@/components/connections/partials/edges-table-actions.vue"
import { nodeType } from "@/helpers/types.js"
import { useModal } from "@/helpers.js"
import api from "@/api.js"
import useQuickSearchStore from "@/stores/me/quick-search.js"

import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'
ModuleRegistry.registerModules([AllCommunityModule])

const defaultNodeHeaders = [
    { field: "label", headerName: 'Name', headerTooltip: 'Name', minWidth: 180, flex: 1, filter: "agTextColumnFilter" },
    { field: "class", headerName: 'Class', headerTooltip: 'Class', width: 100, filter: "agTextColumnFilter" },
    { field: "subclass", headerName: 'Family', headerTooltip: 'Family', width: 100, filter: "agTextColumnFilter" },
    { field: "object_type", headerName: 'Type', headerTooltip: 'Type', filter: "agTextColumnFilter", valueFormatter: p => nodeType(p.value).name || '' },
    { field: "followers",  headerName: 'Followers', headerTooltip: 'Followers',  width: 100, type: "number", filter: "agNumberColumnFilter" },
    { field: "nodeDegree", headerName: 'Degree', headerTooltip: 'Degree', width: 100, type: "number", filter: "agNumberColumnFilter" },
    { field: "actions", headerName: 'Actions', headerTooltip: 'Actions', cellRenderer: NodesTableActions, pinned: 'right', width: 85, resizable: false, sortable: false }
]

export default defineStore('connections-data', {
    state: () => ({
        perspectiveLoading: false,
        loadedPerspectives: {},

        graphStore: null,

        graph: null,
        renderer: null,

        nodesGridApi: null,
        edgesGridApi: null,

        nodeHeaders: [ ...defaultNodeHeaders ],

        edgeHeaders: [
            { field: "source", headerName: 'Source', headerTooltip: 'Source',  filter: "agTextColumnFilter", flex: 1 },
            { field: "target", headerName: 'Target', headerTooltip: 'Target',  filter: "agTextColumnFilter", flex: 1 },
            { field: "label", headerName: 'Type', headerTooltip: 'Type', filter: "agTextColumnFilter" },
            { field: "weight", headerName: 'Weight', headerTooltip: 'Weight',  filter: "agNumberColumnFilter", type: "number" },
            { field: "actions", headerName: 'Actions', headerTooltip: 'Actions', cellRenderer: EdgesTableActions, pinned: 'right', width: 85, resizable: false, sortable: false }
        ],

        statsSuffixes: [ 'content', 'interactions', 'views', 'reactions', 'comments', 'shares' ],
        statsLoadingSuccess: false,
        statsNodesAffected: 0
    }),

    actions: {
        reset() {
            this.nodeHeaders = [ ...defaultNodeHeaders ]
        },

        initialize() {
            this.reset()
            this.graphStore = defineConnectionsGraph({ id: 'connectionsGraph' })()
        },

        getSettings() {
            return {
                nodeHeaders: this.nodeHeaders,
                loadedPerspectives: this.loadedPerspectives,
            }
        },

        setSettings(settings) {
            let nodeHeaders = this.nodeHeaders

            if (settings.nodeHeaders) {
                nodeHeaders = settings.nodeHeaders.filter(c => c.field !== 'actions')

                nodeHeaders.push({ field: "actions", headerName: 'Actions', headerTooltip: 'Actions', cellRenderer: NodesTableActions, pinned: 'right', width: 85, resizable: false, sortable: false })
            }

            this.nodeHeaders = nodeHeaders
            this.loadedPerspectives = settings.loadedPerspectives || this.loadedPerspectives
        },

        onNodesGridReady(params) {
            this.nodesGridApi = params.api
        },

        onEdgesGridReady(params) {
            this.edgesGridApi = params.api
        },

        addNodesMetricsColumn(metrics) {
            const nodeColumns = this.nodesGridApi.getGridOption('columnDefs')
            let field = null

            switch (metrics) {
                case 'centrality':
                    field = nodeColumns.find(c => c.field === 'centrality')
                    if (!field) {
                        nodeColumns.push({ field: "centrality", width: 100, headerName: 'Betweenness centrality', headerTooltip: 'Betweenness centrality', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
                case 'pagerank':
                    field = nodeColumns.find(c => c.field === 'pagerank')
                    if (!field) {
                        nodeColumns.push({ field: "pagerank", width: 100, headerName: 'Pagerank', headerTooltip: 'Pagerank', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
                case 'eigenvector':
                    field = nodeColumns.find(c => c.field === 'eigenvector')
                    if (!field) {
                        nodeColumns.push({ field: "eigenvector", width: 100, headerName: 'Eigenvector centrality', headerTooltip: 'Eigenvector centrality', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
            }

            this.nodesGridApi.setGridOption('columnDefs', nodeColumns)
            this.nodeHeaders = nodeColumns
        },

        onRowSelected(e) {
            if (e.source === 'checkboxSelected') {
                this.graphStore.selectNodes([e.data.node_id], false, false)
            }
        },

        selectionChanged(e) {
            if (e.source === 'uiSelectAllFiltered') {
                const ids = e.api.getSelectedNodes().map(n => n.data.node_id)
                this.graphStore.selectNodes(ids, true, false)
            }
        },

        exportAs(format) {
            switch (format) {
                case 'nodes-csv':
                    this.exportNodesAsCsv()
                    break

                case 'edges-csv':
                    this.exportEdgesAsCsv()
                    break

                case 'gexf':
                    this.graphStore.export()
                    break
            }
        },

        exportNodesAsCsv() {
            const blacklist = ['actions']
            const columnKeys = this.nodeHeaders.filter(c => !blacklist.includes(c.field)).map(c => c.field)
            this.nodesGridApi.exportDataAsCsv({ columnKeys })
        },

        exportEdgesAsCsv() {
            const blacklist = ['actions']
            const columnKeys = this.edgeHeaders.filter(c => !blacklist.includes(c.field)).map(c => c.field)
            this.edgesGridApi.exportDataAsCsv({ columnKeys })
        },

        checkSelectedNodes(selectedNodes) {
            const selected = []
            const deselected = []

            this.nodesGridApi.forEachNode((node) => {
                if (selectedNodes.includes(node.data.node_id)) {
                    selected.push(node)
                } else {
                    deselected.push(node)
                }
            })

            this.nodesGridApi.setNodesSelected({ nodes: selected, newValue: true })
            this.nodesGridApi.setNodesSelected({ nodes: deselected, newValue: false })
        },

        openPerspectiveModal() {
            useQuickSearchStore().show({
                families: [ 'perspectives' ],
                limit: 50,
                onSelect: result => {
                    this.loadPerspectiveStats(result)
                    useModal().show('import-perspective')
                }
            })
        },

        async loadPerspectiveStats(perspective) {
            this.perspectiveLoading = true
            this.statsLoadingSuccess = false
            this.statsNodesAffected = 0
            const loaded = { ...this.loadedPerspectives }

            const channelIds = this.graphStore.nodes.filter(n => n.attributes?.class === 'Channel').map(n => n.key)

            await api.route('me perspectives stats', { id: perspective.id })
                .json({ channelIds })
                .post()
                .json(async res => {
                    const nodeColumns = this.nodesGridApi.getGridOption('columnDefs')

                    const stats = {}
                    res.data.forEach((stat) => {
                        stats[stat.key] = stat
                    })

                    const store = this.graphStore
                    const attr_key_prefix =  perspective.id + '_'

                    const attr_prefix = perspective.title.slice(0, 10) + '. '

                    loaded[perspective.id] = perspective.title.slice(0, 10)
                    this.loadedPerspectives = loaded

                    const processedStats = Object.entries(stats).reduce((acc, [key, value]) => {
                        const filtered = { ...value }
                        // Remove unnecessary keys beforehand
                        delete filtered.node_id
                        delete filtered.key
                        delete filtered.title
                        delete filtered.channelId
                        acc[key] = filtered
                        return acc
                    }, {})

                    // hacky deep copy
                    const newStoreNodes = JSON.parse(JSON.stringify(store.nodes))

                    store.nodes = newStoreNodes.map((node) => {
                        const nodeStats = processedStats[node.key]
                        if (nodeStats) {
                            const newAttrs = { ...node.attributes }

                            // Add new attributes with the prefixed keys
                            for (const [key, value] of Object.entries(nodeStats)) {
                                newAttrs[`${attr_key_prefix}${key}`] = value
                            }

                            // Update the node's attributes
                            node.attributes = newAttrs
                        }

                        return node
                    })

                    store.graph.forEachNode((node_key, attrs) => {
                        if (stats[node_key]) {
                            this.statsNodesAffected += 1
                            for (const stat_key of Object.keys(stats[node_key])) {
                                store.graph.setNodeAttribute(node_key, attr_key_prefix + stat_key, stats[node_key][stat_key])
                            }
                        }
                    })

                    if (res.data.length) {
                        for (const key of Object.keys(res.data[0])) {
                            if (!['nodeId', 'key', 'title', 'channelId'].includes(key)) {
                                const column_name = attr_prefix + key.replace('_', ' ')
                                nodeColumns.push({
                                    field: attr_key_prefix + key,
                                    width: 100,
                                    headerName: column_name,
                                    type: "number",
                                    filter: "agNumberColumnFilter",
                                    headerTooltip: column_name
                                })
                            }
                        }
                    }

                    this.perspectiveLoading = false
                    this.statsLoadingSuccess = true
                })
        },

        getStylingOptions() {
            const options = []
            const nodes = this.graphStore.nodes.length ? this.graphStore.nodes : []

            const keys = []
            for (const node of nodes) {
                const node_keys = Object.keys(node.attributes)

                for (const key of node_keys) {
                    if (!keys.includes(key)) {
                        keys.push(key)
                    }
                }
            }

            for (const key of keys) {
                const suffix = this.statsSuffixes.find(e => key.endsWith(e))
                if (suffix) {
                    const column_name = this.loadedPerspectives[key.split('_')[0]] + ' ' + suffix.replace(/_/g, ' ')
                    options.push({ id: key, name: column_name })
                }
            }

            return options
        }
    }
})
