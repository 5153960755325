<template>
    <div class="py-1 text-sm" v-if="filteredMentions.length">
        <div class="flex flex-wrap px-3">
            <router-link v-for="feature in shownMentions" :key="feature.id" :to="{ name: 'features.details', params: { id: feature.id } }" target="_blank"
                class="flex shrink-0 items-center px-2 py-1 text-gray-800 max-w-full overflow-hidden rounded-sm hover:bg-gray-100 transition-all cursor-pointer group"
                v-tooltip="$nodeType(feature.type).name + ': ' + feature.name">
                <ui-icon :name="$nodeType(feature.type).icon" class="text-lg text-gray-500 shrink-0 group-hover:text-gray-700"></ui-icon>
                <span class="ml-1 line-clamp-1">{{ feature.name }}</span>
            </router-link>

            <a href="#"
               class="flex shrink-0 items-center px-2 py-1 text-blue-600 max-w-full overflow-hidden rounded-xs hover:bg-gray-100 transition-all cursor-pointer group text-sm"
               @click.exact.prevent="showMore" @click.alt.exact.prevent="showAll"
               v-if="hiddenMentionsCount">
                <ui-icon name="plus" class="text-lg text-blue-500 shrink-0"></ui-icon>
                <span class="ml-1"><span class="font-semibold">{{hiddenMentionsCount}}</span> Mentions</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'features', 'streamStore' ],

    data: () => ({
        shownLimit: 5
    }),

    computed: {
        filteredMentions() {
            return this.features.filter(f => [
                'place', 'bank-account', 'domain', 'email', 'hashtag', 'person-name', 'phone-number', 'username'
            ].includes(f.type))
        },

        shownMentions() {
            return this.filteredMentions.slice(0, this.shownLimit)
        },

        hiddenMentionsCount() {
            return Math.max(this.filteredMentions.length - this.shownLimit, 0)
        }
    },

    methods: {
        showMore() {
            this.shownLimit += 10
            this.streamStore.triggerLayoutUpdate()
        },

        showAll() {
            this.shownLimit = this.filteredMentions.length
            this.streamStore.triggerLayoutUpdate()
        }
    }
}
</script>
