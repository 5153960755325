<template>
    <div class="bg-white border border-gray-200 shadow-xs rounded-lg flex flex-col text-sm px-6 py-4">
        <div class="flex items-center space-x-1 mb-3">
            <div class="shrink-0 relative">
                <a :href="$router.resolve({ name: 'channels.channel.information', params: { id: content.publishedBy.id } }).href" target="_blank">
                    <ui-avatar :item="content.publishedBy" class="h-4 w-4"></ui-avatar>
                    <ui-icon :name="content.publishedBy.monitored ? `badges.channel-${content.publishedBy.type}` : `badges.channel-${content.publishedBy.type}-inactive`" style="height: 0.7em; width: 0.7em" class="absolute -right-0.5 -bottom-0.5 text-xs ring-1 ring-white rounded-full"></ui-icon>
                </a>
            </div>
            <div class="font-medium truncate min-w-0">
                <a :href="$router.resolve({ name: 'channels.channel.information', params: { id: content.publishedBy.id } }).href" v-tooltip="content.publishedBy.name" target="_blank">
                    {{ content.publishedBy.name }}
                </a>
            </div>
            <div class="truncate text-gray-700 text-2xs tracking-tight">
                <a :href="$router.resolve({ name: 'content.details', params: { id: content.id } }).href" target="_blank">
                    {{ $dateTime(content.publishedAt) }}
                </a>
            </div>
        </div>

        <h1 class="font-medium" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-if="content.name" v-html="content.name"></h1>
        <div class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></div>

        <slot name="bottom"></slot>
    </div>
</template>

<script>
import language from '@/helpers/language'

export default {
    props: [ 'content' ],

    computed: {
        text() {
            return this.content.body.replace(/\n\s*\n/g, "\n").substr(0, 200) + '...'
        },

        isRTL() {
            return language(this.content.language[0]).rtl
        }
    }
}
</script>
