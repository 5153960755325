import useMyStore from '@/stores/me/my'
import useMyNotificationsStore from '@/stores/me/notifications'
import useTaggingStore from '@/stores/tagging/tagging'

import { defineForm } from '@/stores/reusable/form'

import { default as api, asyncResource, content } from '@/api'
import { useModal } from '@/helpers'

export const useContentModalsSmartTagStore = defineForm({
    id: 'contentModalsSmartTag',

    inputs: () => ({
        type: 'yes-or-no',

        yesOrNo: {
            question: null,
            yesTag: null,
            noTag: null,
            context: null
        },

        multiCategory: {
            categories: [],
            context: null,
            multipleCategories: false
        }
    }),

    submitRequest() {
        return api.route('monitor content smart-tags store')
            .json({
                filters: this.filters.toPerspective(),
                sorting: this.sorting.api,
                type: this.inputs.type,
                options: this.inputs.type == 'multi-category'
                    ? { ...this.inputs.multiCategory, categories: this.inputs.multiCategory.categories.map(category => ({ ...category, tag: category.tag?.id })) }
                    : { ...this.inputs.yesOrNo, yesTag: this.inputs.yesOrNo.yesTag?.id, noTag: this.inputs.yesOrNo.noTag?.id },
                preview: ! this.previewTask
            })
            .post()
    },

    async onResponse(res) {
        if (! this.previewTask) {
            this.previewTask = useMyNotificationsStore().resolveTask(res.taskId)

            useMyNotificationsStore().onTaskCompletion(res.taskId, () => {
                if (Object.keys(this.previewTask.payload.results).length) {
                    this.content.fetch(this)
                }
            })
        } else {
            useModal().hide('content-smart-tag')
        }
    },
    
    state: () => ({
        filters: null,
        sorting: null,
        itemsCount: null,
        previewTask: null,

        content: asyncResource({
            method: 'post',
            request: (api, store) => content().ids(Object.keys(store.previewTask.payload.results)).toRequest(),
            onResponse: (items, _, store) => {
                items.forEach(item => item.tags = (store.previewTask.payload.results[item.id] ?? []).map(tag => useTaggingStore().find(tag)))
            }
        })
    }),

    getters: {
        hasBudgetAvailable: store => store.itemsCount
            && useMyStore().budgets?.smartTags
            && useMyStore().budgets.smartTags.limit - useMyStore().budgets.smartTags.usage - store.itemsCount >= 0
    },

    actions: {
        open(filters, sorting) {
            this.reset()
            
            this.filters = filters
            this.sorting = sorting
            this.previewTask = null

            this.addCategory()

            this.content.reset()
            
            useMyStore().loadBudgets()
            this.loadItemsCount()

            useModal().show('content-smart-tag')
        },

        back() {
            this.previewTask = null

            this.content.reset()
        },

        loadItemsCount() {
            content().filters(this.filters.toPerspective()).count(count => this.itemsCount = count)
        },

        addCategory() {
            let placeholders = [
                { name: 'Politics', description: 'Anything regards to politics, foreign affairs and social topics.' },
                { name: 'Economy', description: 'This should include finance, investing, businesses, stories about money, job market and spending' },
                { name: 'Pop culture', description: 'This should include fun topics regarding music, celebrities, culture, fun, memes, rumors. These are light topics.' },
                { name: 'Sports', description: 'Sports include everything related to sport and sport personalities.' }
            ]

            this.inputs.multiCategory.categories.push({
                name: null, description: null, tag: null, placeholder: placeholders[Math.floor(Math.random() * placeholders.length)]
            })
        }
    }
})

export default useContentModalsSmartTagStore
