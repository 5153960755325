<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-xs rounded-lg mb-4 divide-y divide-gray-200 px-4">
        <div class="pt-4">
            <div class="flex items-center justify-between pb-2">
                <h3 class="text-lg font-medium text-gray-700">
                    Top published by..
                </h3>

                <ui-help-link size="small" v-tooltip="'Channels publishing most content into this Channel.'"></ui-help-link>
            </div>

            <ui-async :guards="topSources.data || topSources.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3 mt-1" v-if="shownTopSources.length">
                    <tr :key="source.channel.id" v-for="source in shownTopSources">
                        <td class="py-1 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <ui-avatar :item="source.channel" class="w-6 h-6"></ui-avatar>
                                <ui-icon :name="source.channel.monitored ? `badges.channel-${source.channel.type}` : `badges.channel-${source.channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-xs"></ui-icon>
                            </div>

                            <div class="truncate px-2">
                                <router-link :to="{ name: 'channels.channel.information', params: { id: source.channel.id } }" v-tooltip="source.channel.name">{{ source.channel.name }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 font-semibold text-right">
                            {{$number(source.count)}}
                        </td>
                    </tr>
                </table>

                <div class="pt-4 pb-6 text-center space-y-1 text-sm text-gray-700" v-else-if="topSources.error">
                    <p>We were unable to load connections for this Channel.</p>
                    <p>
                        <a href="#" @click.prevent="reload('topSources')" class="text-blue-500 hover:text-blue-800">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No sources found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showTopSourcesShowMoreLink">
                    <a @click.prevent="showDetails('topSources')" href="#" class="text-blue-500 hover:text-blue-800">
                        Show More
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center py-10 pt-8 w-full h-full">
                        <ui-spinner></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>

        <div class="pt-4">
            <div class="flex items-center justify-between pb-2">
                <h3 class="text-lg font-medium text-gray-700">
                    Top published in..
                </h3>

                <ui-help-link size="small" v-tooltip="'Channels where this Channel publishes most content to.'"></ui-help-link>
            </div>

            <ui-async :guards="topTargets.data || topTargets.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3 mt-1" v-if="shownTopTargets.length">
                    <tr :key="target.channel.id" v-for="target in shownTopTargets">
                        <td class="py-1 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <ui-avatar :item="target.channel" class="w-6 h-6"></ui-avatar>
                                <ui-icon :name="target.channel.monitored ? `badges.channel-${target.channel.type}` : `badges.channel-${target.channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-xs"></ui-icon>
                            </div>
    
                            <div class="truncate px-2">
                                <router-link :to="{ name: 'channels.channel.information', params: { id: target.channel.id } }" v-tooltip="target.channel.name">{{ target.channel.name }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 font-semibold text-right">
                            {{$number(target.count)}}
                        </td>
                    </tr>
                </table>

                <div class="pt-4 pb-6 text-center space-y-1 text-sm text-gray-700" v-else-if="topTargets.error">
                    <p>We were unable to load connections for this Channel.</p>
                    <p>
                        <a href="#" @click.prevent="reload('topTargets')" class="text-blue-500 hover:text-blue-700">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No targets found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showTopTargetsShowMoreLink">
                    <a @click.prevent="showDetails('topTargets')" href="#" class="text-blue-500 hover:text-blue-800">
                        Show More
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center pb-10 pt-8 w-full h-full">
                        <ui-spinner></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>

        <!--<div class="pt-4">-->
        <!--    <div class="flex items-center justify-between pb-2">-->
        <!--        <h3 class="text-lg font-medium text-gray-700">-->
        <!--            Members-->
        <!--        </h3>-->

        <!--        <ui-help-link size="small" v-tooltip="'Members of this channel.'"></ui-help-link>-->
        <!--    </div>-->

        <!--    <ui-async :guards="members.data || members.error">-->
        <!--        <table class="min-w-full w-full table-fixed text-sm mb-3 mt-1" v-if="shownMembers.length">-->
        <!--            <tr :key="channel.id" v-for="channel in shownMembers">-->
        <!--                <td class="py-1 whitespace-nowrap flex items-center break-all">-->
        <!--                    <div class="shrink-0 relative">-->
        <!--                        <ui-avatar :item="channel" class="w-6 h-6"></ui-avatar>-->
        <!--                        <ui-icon :name="channel.monitored ? `badges.channel-${channel.type}` : `badges.channel-${channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-xs"></ui-icon>-->
        <!--                    </div>-->

        <!--                    <div class="truncate px-2">-->
        <!--                        <router-link :to="{ name: 'channels.channel.information', params: { id: channel.id } }" v-tooltip="channel.name">{{ channel.name }}</router-link>-->
        <!--                    </div>-->
        <!--                </td>-->
        <!--                <td class="w-12 font-semibold text-right">-->
        <!--                </td>-->
        <!--            </tr>-->
        <!--        </table>-->

        <!--        <div class="pt-4 pb-6 text-center space-y-1 text-sm text-gray-700" v-else-if="members.error">-->
        <!--            <p>We were unable to load members for this Channel.</p>-->
        <!--            <p>-->
        <!--                <a href="#" @click.prevent="reload('members')" class="text-blue-500 hover:text-blue-700">Retry</a>-->
        <!--            </p>-->
        <!--        </div>-->

        <!--        <div class="text-sm text-gray-600 text-center mb-4" v-else>-->
        <!--            No members found yet.-->
        <!--        </div>-->

        <!--        <div class="text-center text-sm mb-4" v-if="showMembersShowMoreLink">-->
        <!--            <a @click.prevent="showDetails('members')" href="#" class="text-blue-500 hover:text-blue-800">-->
        <!--                Show More-->
        <!--            </a>-->
        <!--        </div>-->

        <!--        <template v-slot:loading>-->
        <!--            <div class="flex items-center justify-center pb-10 pt-8 w-full h-full">-->
        <!--                <ui-spinner></ui-spinner>-->
        <!--            </div>-->
        <!--        </template>-->
        <!--    </ui-async>-->
        <!--</div>-->
    </div>
</template>

<script>
import useAudienceStore from '@/stores/channels/channel/audience'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useAudienceStore, [
            'members', 'topSources', 'topTargets'
        ]),

        shownTopSources() {
            return this.topSources.data ? [ ...this.topSources.data ].slice(0, 5) : []
        },

        showTopSourcesShowMoreLink() {
            return this.topSources.data?.length > 3
        },

        shownTopTargets() {
            return this.topTargets.data ? [ ...this.topTargets.data ].slice(0, 5) : []
        },

        showTopTargetsShowMoreLink() {
            return this.topTargets.data?.length > 3
        },

        shownMembers() {
            return this.members.data ? [ ...this.members.data ].slice(0, 5) : []
        },

        showMembersShowMoreLink() {
            return this.members.data?.length > 3
        }
    },

    methods: {
        ...mapActions(useAudienceStore, [ 'reload' ]),

        showDetails(type) {
            useAudienceStore().open(type)
        }
    }
}
</script>
