<template>
    <div class="pb-20 px-6 xl:px-16 max-w-12xl mx-auto">
        <div class="flex items-center justify-between my-6">
            <h1 class="text-[24px] font-semibold px-1">
                Help
            </h1>
        </div>

        <div class="flex gap-x-3">
            <div class="w-80">
                <div class="flex items-center gap-x-1.5 my-3 mt-0">
                    <ui-icon name="book" class="text-gray-700"></ui-icon>
                    <h1 class="text-gray-700 text-xl font-medium leading-none">User Guide</h1>
                </div>

                <div v-for="page in pages" :key="page.id">
                    <router-link :to="{ name: 'help.user-guide', params: { page: page.id } }" class="flex gap-x-1.5 text-gray-800 hover:text-gray-900 hover:ml-1.5 transition-all">
                        <h1 class="text-xl font-semibold py-0.5">{{page.title}}</h1>
                    </router-link>
                    <ul v-if="page.sections.length" class="pl-3">
                        <li v-for="section in page.sections" :key="section.id">
                            <router-link  :to="{ name: 'help.user-guide', params: { page: page.id, section: section.id } }" href="#" class="block text-gray-700 hover:text-gray-900 hover:ml-1.5 transition-all">
                                <h2 class="font-medium">{{section.title}}</h2>
                            </router-link>
                            <ul v-if="section.children.length" class="pl-3">
                                <li v-for="section in section.children" :key="section.id">
                                    <router-link :to="{ name: 'help.user-guide', params: { page: page.id, section: section.id } }" href="#" class="block text-gray-700 hover:text-gray-900 hover:ml-1.5 transition-all">
                                        <h3>{{section.title}}</h3>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="flex items-center gap-x-1.5 my-3 mt-4">
                    <ui-icon name="star" class="text-gray-700"></ui-icon>
                    <h1 class="text-gray-700 text-xl font-medium leading-none">What's New</h1>
                </div>

                <ul>
                    <li v-for="release in releases" :key="release.id">
                        <router-link :to="{ name: 'help.whats-new', params: { release: release.id } }" class="block text-gray-700 hover:text-gray-900 hover:ml-1.5 transition-all">
                            Juno {{release.id}}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="flex-1 rounded-xl bg-white border border-gray-200 shadow-xs p-12">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import useSupportUserGuideStore from '@/stores/support/user-guide'
import useSupportWhatsNewStore from '@/stores/support/whats-new'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useSupportUserGuideStore, [ 'pages' ]),
        ...mapState(useSupportWhatsNewStore, [ 'releases' ])
    },

    methods: {
        ...mapActions(useSupportUserGuideStore, [ 'show' ])
    }
}
</script>
