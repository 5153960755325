import { defineStore } from 'pinia'

// Explicitly listing all used color classes to make sure they are generated
// bg-red-100 bg-red-500 text-red-500
// bg-yellow-100 bg-yellow-600 text-yellow-600
// bg-pink-100 bg-pink-500 text-pink-500
// bg-indigo-100 bg-indigo-500 text-indigo-500
// bg-purple-100 bg-purple-500 text-purple-500

export default defineStore('myLabels', {
    state: () => ({
        labels: [
            { id: 'overperforming', title: 'Overperforming', color: { light: 'yellow-100', dark: 'yellow-600' } },
            { id: 'underperforming', title: 'Underperforming', color: { light: 'pink-100', dark: 'pink-500' } },
        ]
    }),

    actions: {
        find(id) {
            return this.labels.find(t => t.id == id)
        }
    }
})
