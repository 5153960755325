import useModalsWorkspaceSwitchConfirmationStore from '@/stores/modals/workspace-switch-confirmation'
import useMyStore from '@/stores/me/my'
import useMyWorkspacesStore from '@/stores/me/workspaces'

import api from '@/api'
import useRouter from '@/router'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export default defineStore('myQuickSearch', {
    state: () => ({
        isShown: false,
        inMaintenance: false,

        search: '',
        limit: 3,

        initialResults: [],
        initialResultsPending: false,

        results: [],
        resultsPending: false,
        resultsPromise: null,

        hasFilterableWorkspaces: true,
        workspace: null,

        hasFilterableFamilies: true,
        filteredFamilies: [],
        families: [],

        multiSelect: false,
        selectedResults: [],

        onSelect: null,
        onHide: null
    }),

    getters: {
        filteredResults(state) {
            return state.filteredFamilies.length ? this.results.filter(r => state.filteredFamilies.includes(r.family)) : this.results
        },

        resultTypes: store => ({
            'analysis': {
                name: 'Analysis', family: 'analyses',
                to: result => ({ name: 'analysis.analysis.details', params: { type: result.type, id: result.id } })
            },
            'app': {
                to: result => ({ name: result.route })
            },
            'channel': {
                family: 'channels',
                to: result => ({ name: 'channels.channel.information', params: { id: result.id } }),
                toStream: result => ({ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': 'past:1|months', 'filter[publishedInto]': `channel|${result.id}|${result.title}` } })
            },
            'dashboard': {
                name: 'Dashboards', family: 'dashboards',
                to: result => ({ name: 'dashboard.boards.board', params: { id: result.id } })
            },
            'feature': {
                family: 'features',
                to: result => ({ name: 'features.details', params: { id: result.id } })
            },
            'list': {
                name: 'List', family: 'lists',
                to: result => ({ name: 'channels', query: { show: `list|id:${result.id}` } }),
                toStream: result => ({ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': 'past:1|months', 'filter[publishedInto]': `list|${result.id}|${result.title}` } })
            },
            'perspective-new': {
                to: (result, search) => ({ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${search}|${useMyStore().searchLanguage}|` } })
            },
            'perspective': {
                name: 'Perspective', family: 'perspectives',
                to: result => ({ name: 'content.perspectives.perspective', params: { perspectiveId: result.id } })
            },
            'topic': {
                name: 'Topics', family: 'topics',
                to: result => ({ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${result.query}|${result.language}|`, 'filter[date]': 'past:1|months', topic: result.id } })
            }
        })
    },

    actions: {
        initialize() {
        },

        show(options) {
            this.$reset()

            this.isShown = true

            this.hasFilterableFamilies = options?.hasFilterableFamilies || false
            this.hasFilterableWorkspaces = options?.hasFilterableWorkspaces || false
            this.workspace = options?.workspace ?? useMyStore().currentWorkspace
            this.families = options?.families || [ 'analyses', 'app', 'channels', 'dashboards', 'features', 'lists', 'perspectives', 'topics' ]
            this.limit = options?.limit || 3
            this.multiSelect = options?.multiSelect || false
            this.onSelect = options?.onSelect
            this.onHide = options?.onHide
            this.results = this.initialResults = []

            this.resolveInitialResults(options)
        },

        hide() {
            if (this.onHide) this.onHide()

            this.isShown = false
        },

        loadDebounced: debounce(function () { this.load() }, 150),

        async load() {
            this.selectedResults = []

            if (this.search.length == 0) this.results = this.initialResults
            if (this.search.length < 3) return

            if (this.resultsPromise) {
                this.abortGeneralLoad?.abort()
                this.abortWorkspaceLoad?.abort()
            }

            this.resultsPending = true
            this.inMaintenance = false

            let generalSearch = Promise.resolve()
            let userSearch = Promise.resolve()

            if (this.shouldSearch([ 'app', 'channels', 'features' ])) {
                generalSearch = api.url(`${import.meta.env.VITE_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortGeneralLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchGeneral })
                    .options({ credentials: 'same-origin' })
                    .json({
                        searches: [
                            this.shouldSearch('app') ? {
                                collection: 'app',
                                q: this.search,
                                query_by: 'title',
                                per_page: this.limit
                            } : null,
                            this.shouldSearch('channels') ? {
                                collection: 'channels',
                                q: this.search,
                                query_by: 'title,username',
                                sort_by: 'followers:desc,_text_match:desc',
                                group_by: 'type',
                                per_page: 100,
                                group_limit: this.limit
                            } : null,
                            this.shouldSearch('features') ? {
                                collection: 'features',
                                q: this.search,
                                query_by: 'title',
                                group_by: 'type',
                                per_page: 100,
                                group_limit: this.limit
                            } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            if (this.shouldSearch([ 'analyses', 'dashboards', 'lists', 'perspectives', 'topics' ])) {
                let query = { 'query_by': 'title' }

                if (this.workspace) {
                    query.filter_by = `workspaceId:[${this.workspace.id}]`
                }

                userSearch = api.url(`${import.meta.env.VITE_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortWorkspaceLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().user.keys['quickSearchUser:juno'] })
                    .options({ credentials: 'same-origin' })
                    .query(query)
                    .json({
                        searches: [
                            this.shouldSearch('analyses') ? { collection: 'analyses', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('dashboards') ? { collection: 'dashboards', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('lists') ? { collection: 'lists', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('perspectives') ? { collection: 'perspectives', q: this.search, per_page: this.limit, filter_by: 'product:juno' } : null,
                            this.shouldSearch('topics') ? { collection: 'topics', q: this.search, per_page: this.limit } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            return this.resultsPromise = Promise.all([ generalSearch, userSearch ])
                .then(([ generalData, workspaceData ]) => {
                    if (! generalData && ! workspaceData) return

                    this.results = this.enrichResults([
                        ...(! this.families.length ? [{ resultType: 'perspective-new' }] : []),
                        ...this.familyResults(workspaceData, 'analyses').hits.map(result => ({ ...result.document, resultType: 'analysis', family: 'analyses' })),
                        ...this.familyResults(generalData, 'app').hits.map(result => ({ ...result.document, resultType: 'app', family: 'app' })),
                        ...this.familyResults(workspaceData, 'dashboards').hits.map(result => ({ ...result.document, resultType: 'dashboard', family: 'dashboards' })),
                        ...this.familyResults(workspaceData, 'perspectives').hits.map(result => ({ ...result.document, resultType: 'perspective', family: 'perspectives' })),
                        ...this.familyResults(workspaceData, 'lists').hits.map(result => ({ ...result.document, resultType: 'list', family: 'lists' })),
                        ...this.familyResults(workspaceData, 'topics').hits.map(result => ({ ...result.document, resultType: 'topic', family: 'topics' })),
                        ...this.familyResults(generalData, 'channels').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'channel', family: 'channels' }))),
                        ...this.familyResults(generalData, 'features').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'feature', family: 'features' })))
                    ])

                    this.resultsPending = false
                    this.resultsPromise = null
                    this.abortGeneralLoad = this.abortWorkspaceLoad = null
                })
        },

        enrichResults(results) {
            return results.map(result => ({
                ...result,
                resultTypeName: this.resultTypes[result.resultType].name,
                family: this.resultTypes[result.resultType].family,
                to: this.resultTypes[result.resultType].to?.(result, this.search),
                toStream: this.resultTypes[result.resultType].toStream?.(result, this.search)
            }))
        },

        resolveInitialResults(options) {
            if (options?.initialResults instanceof Array) {
                this.results = this.enrichResults(options.initialResults)
            }

            if (options?.initialResults instanceof Promise) {
                this.initialResultsPending = true

                options.initialResults.then(results => {
                    this.initialResultsPending = false
                    this.initialResults = results

                    if (! this.results.length) this.results = this.enrichResults(this.initialResults)
                })
            }
        },

        submitResult(result) {
            if (! result) return

            if (this.onSelect && ! result.alwaysOpen) {
                this.onSelect(this.multiSelect ? [ result ] : result)
            } else if (! result.workspaceId || useMyStore().currentWorkspace.id === result.workspaceId) {
                useRouter().push(result.to)
            } else {
                const toWorkspace = useMyWorkspacesStore().find(result.workspaceId)
                useModalsWorkspaceSwitchConfirmationStore().open(useMyStore().currentWorkspace, toWorkspace, result.to)
                    .then(() => {
                        useMyStore().currentWorkspace = toWorkspace
                        useMyStore().updatePreferences({ 'lastWorkspace:juno': toWorkspace.id }).then(() => {
                            window.location = useRouter().resolve(result.to).href
                        })
                    })
            }

            this.hide()
        },

        clear() {
            this.$reset()
        },

        resetFilteredFamilies() {
            this.filteredFamilies = []
            this.limit = 3

            this.load()
        },

        setFilteredFamilies(families) {
            this.filteredFamilies = families.filter(family => this.families.includes(family))

            this.limit = 50
            this.load()
        },

        isFamilyIncludedInFilter(family) {
            return this.filteredFamilies.includes(family)
        },

        resetWorkspace() {
            this.workspace = false

            this.loadDebounced()
        },

        setWorkspace(workspace) {
            this.workspace = workspace

            this.loadDebounced()
        },

        isSelected(result) {
            return this.selectedResults.includes(result.id)
        },

        toggleResult(result) {
            this.selectedResults = this.isSelected(result)
                ? this.selectedResults.filter(id => id != result.id)
                : [ ...this.selectedResults, result.id ]
        },

        submitSelection() {
            this.onSelect(this.results.filter(result => this.isSelected(result)))
        },

        shouldSearch(family) {
            if (family instanceof Array) return family.some(f => this.shouldSearch(f))
            return this.families.includes(family) && (! this.filteredFamilies.length || this.filteredFamilies.includes(family))
        },

        familyResults(data, family) {
            if (! data) return { hits: [], grouped_hits: [] }
            return data.results.find(r => r.request_params.collection_name == family) || { hits: [], grouped_hits: [] }
        }
    }
})
