import useMyStore from '@/stores/me/my'
import useMyNotificationsStore from '@/stores/me/notifications'

import api from '@/api'

import language from '@/helpers/language'

export function translate(item, params = {}) {
    item.translation = { isLoading: true, text: undefined, incomplete: false, language: useMyStore().preferences.translateLanguage }

    return api.route('monitor content translate', { id: params.id ?? item.id })
        .json({ language: useMyStore().preferences.translateLanguage, ...params })
        .post()
        .error(422, e => {
            if (e.json.code === 'invalid_translation_language_pair_error') {
                return useMyNotificationsStore().pushToast({
                    title: e.json.title,
                    body: e.json.message,
                    level: 'error'
                })
            }

            return e
        })
        .json(data => {
            item.translation.text = data.text
            item.translation.incomplete = data.incomplete
        })
        .finally(() => {
            item.translation.isLoading = false
        })
}

export function removeTranslation(item) {
    item.translation = undefined
}

export function isTranslatable(item) {
    return language(item.language?.[0])?.translatable
        && item.language?.[0] != useMyStore().preferences.translateLanguage
}

export default translate
