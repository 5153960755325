<template>
    <search-filter name="History" :ids="['edited', 'deleted']" :filters="filters" align="right" help-section="history-filter" @opened="reset">
        <template v-slot:applied>
            {{ valueEdited ? 'Edited' : '' }} {{ valueEdited && valueDeleted ? ' & ' : '' }} {{ valueDeleted ? 'Deleted' : '' }}
        </template>

        <template v-slot:options>
            <div class="w-64">
                <ui-dropdown-link :icon="true" checkbox :selected="localEdited" @click="toggleEdited">
                    <template v-slot:icon>
                        <span class="inline-block w-3 h-3 text-xs rounded-full bg-blue-500"></span>
                    </template>
                    <template v-slot:default>
                        Edited
                    </template>
                </ui-dropdown-link>
                <ui-dropdown-link :icon="true" checkbox :selected="localDeleted" @click="toggleDeleted">
                    <template v-slot:icon>
                        <span class="inline-block w-3 h-3 text-xs rounded-full bg-red-500"></span>
                    </template>
                    <template v-slot:default>
                        Deleted
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('edited'); filters.remove('deleted'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        localEdited: false,
        localDeleted: false
    }),

    computed: {
        valueEdited() {
            return this.filters.value('edited') || false
        },

        valueDeleted() {
            return this.filters.value('deleted') || false
        }
    },

    methods: {
        toggleEdited() {
            this.localEdited = ! this.localEdited
        },

        toggleDeleted() {
            this.localDeleted = ! this.localDeleted
        },

        reset() {
            this.localEdited = this.filters.value('edited') || false
            this.localDeleted = this.filters.value('deleted') || false
        },

        apply() {
            this.localEdited ? this.filters.set('edited', true) : this.filters.remove('edited')
            this.localDeleted ? this.filters.set('deleted', true) : this.filters.remove('deleted')
        }
    }
}
</script>
