import StreamLayoutTimeline from '@/components/content/perspective/stream/layouts/timeline'

import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'

import { asyncResource, content } from '@/api'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export default defineStore('channelStream', {
    state: () => ({
        filters: searchFilters(),

        items: asyncResource({
            method: 'post',
            request: (api, store, payload) => store.contentQuery().query({ includeTotal: 1, ...payload }).toRequest(),
            paginated: true
        }),
        itemsCount: null,

        isInitialized: false,

        lastReloadTime: +new Date(),
        lastLayoutUpdate: { time: +new Date(), items: [] },

        sortingOptions: [
            { id: 'latest', value: i => ({ value: i.publishedAt, id: i.id }), api: 'date-desc', type: 'date' },
            { id: 'oldest', value: i => ({ value: i.publishedAt, id: i.id }), api: 'date-asc', type: 'date' },
            { id: 'ids', value: (i, store) => ({ value: store.items.data.indexOf(i), id: i.id }), api: 'ids' },
            { id: 'interactions', value: i => ({ value: i.latestStats.interactions, id: i.id }), api: 'interactions-desc' },
//            { id: 'trending', value: i => ({ value: i.details.metrics.benchmark, id: i.id }), api: 'trending-desc' },
            { id: 'reactions', value: i => ({ value: i.latestStats.reactions, id: i.id }), api: 'reactions-desc' },
            { id: 'comments', value: i => ({ value: i.latestStats.comments, id: i.id }), api: 'comments-desc' },
            { id: 'shares', value: i => ({ value: i.latestStats.shares, id: i.id }), api: 'shares-desc' },
            { id: 'views', value: i => ({ value: i.latestStats.views, id: i.id }), api: 'views-desc' }
        ],

        mediaLightbox: defineStreamMediaLightbox({
            id: `contentPerspectiveStreamMediaLightbox`,
            name: `stream-content-lightbox-contentPerspectiveStream`
        })()
    }),

    getters: {
        sorting(store) {
            return store.sortingOptions.find(o => o.id == store.selectedSorting) || store.sortingOptions[0]
        },

        layout(store) {
            return { id: 'timeline', component: markRaw(StreamLayoutTimeline) }
        },

        lastItem(store) {
            if (store.items.data) return store.items.data[store.items.data.length - 1]
        }
    },

    actions: {
        async initialize(channel) {
            if (this.isInitialized) return

            this.filters.onChange = ((action, payload) => this.reload())

            this.isInitialized = true
        },

        abort() {
            this.items.abort()
        },

        async load() {
            if (! this.isInitialized) return

            let items = await this.items.fetchFirst(this)

            this.itemsCount = this.items.res?.total
            this.lastReloadTime = +new Date()

            this.triggerLayoutUpdate(items)
        },

        async reload() {
            this.abort()
            this.load()
        },

        async loadMore(infiniteScroll = null) {
            if (! this.lastItem) return infiniteScroll?.complete()

            let items = await this.items.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()

            this.triggerLayoutUpdate(items)
        },

        contentQuery() {
            return content()
                .filters(this.filters.toPerspective())
                .sorting(this.sorting.api)
                .limit(25)
        },

        setSorting(option) {
            this.selectedSorting = option

            this.load()
        },

        triggerLayoutUpdate(items = []) {
            this.lastLayoutUpdate = { time: +new Date(), items: [ ...items ] }
        }
    }
})
