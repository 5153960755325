<template>
    <a @click.prevent="store.submitResult(result)" :href="$router.resolve(result.to).href" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }">
        <div class="flex items-center">
            <div v-if="store.multiSelect" class="shrink-0 mr-3"  @click.prevent.stop="store.toggleResult(result)">
                <input v-if="result.id" type="checkbox" class="h-4 w-4 text-blue-600 border-gray-300 rounded-sm pointer-events-none" :checked="store.isSelected(result)">
            </div>

            <div class="shrink-0 relative">
                <ui-avatar v-if="result.resultType == 'feature'" :icon="$nodeType(result.type).icon" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-8 h-8 drop-shadow-xs shrink-0"></ui-avatar>
                <ui-avatar v-else-if="result.resultType == 'perspective-new'" icon="search" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-8 h-8 drop-shadow-xs shrink-0"></ui-avatar>
                <ui-avatar v-else :item="avatar" class="w-8 h-8 drop-shadow-xs shrink-0"></ui-avatar>

                <ui-icon v-if="result.resultType == 'channel'" :name="result.flags.includes('monitored') ? `badges.channel-${result.type}` : `badges.channel-${result.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-base"></ui-icon>
            </div>

            <div class="mx-3 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-900">
                    <template v-if="result.resultType == 'app'">
                        <div class="truncate">Go to <span class="text-gray-900 font-medium">{{result.title}}</span></div>
                    </template>
                    <template v-else-if="result.resultType == 'channel'">
                        <div class="truncate">{{result.title}}</div>
                        <div v-if="result.flags.includes('verified')" class="h-3 w-3 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xs inline-flex ml-1">
                            <ui-icon name="check"></ui-icon>
                        </div>
                    </template>
                    <template v-else-if="result.resultType == 'perspective-new'">
                        <div class="truncate">Search <span class="text-gray-900 font-medium">"{{search}}"</span> in All Content</div>
                    </template>
                    <template v-else>
                        <div class="truncate">{{result.title}}</div>
                    </template>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    <template v-if="result.resultType == 'channel'">
                        {{$nodeType(result).name}} · {{result.username ? `@${result.username} · ` : ''}} {{$number(result.followers)}} audience
                    </template>
                    <template v-else-if="result.resultType == 'feature'">
                        {{$nodeType(result.type).name}}
                    </template>
                    <template v-else>
                        {{result.resultTypeName}}
                    </template>
                </div>
            </div>

            <div v-if="result.toStream" class="shrink-0 mr-2">
                <a :href="$router.resolve(result.toStream).href" @click.prevent.stop="openStream(result)">
                    <ui-icon name="stream" class="text-gray-600 hover:text-gray-900 text-3xl" v-tooltip="'Show all published content'"></ui-icon>
                </a>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result', 'search' ],

    computed: {
        avatar() {
            if (this.result.resultType == 'channel') {
                return {
                    name: this.result.title,
                    family: 'channels',
                    avatar: { image: this.result.avatarImageUrl ? `${import.meta.env.VITE_IMAGES_CDN_URL}/${this.result.avatarImageUrl}` : null }
                }
            } else if (this.result.resultType == 'feature') {
                return { avatar: { icon: this.$nodeType(this.result.type).icon } }
            }

            return {
                name: this.result.title,
                family: this.result.family,
                avatar: {
                    color: this.result.avatarColor,
                    icon: this.result.avatarIcon,
                    image: this.result.avatarImageUrl
                }
            }
        }
    },

    methods: {
        openStream(result) {
            this.$router.push(result.toStream)
            this.store.hide()
        }
    }
}
</script>
