import { asyncResource, content } from '@/api'

import { defineStore } from 'pinia'

export default defineStore('alertsTriggerEveryMatch', {
    state: () => ({
        trigger: null,

        content: asyncResource({
            method: 'post',
            request: (api, store, payload) => content()
                .ids(store.trigger.meta.content)
                .query(payload)
                .sorting('date-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date)
    }),

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.content.reset()
            this.content.fetchFirst(this)
        },

        async loadMore(infiniteScroll) {
            if (this.content.isFetching) return infiniteScroll?.loaded()

            let items = await this.content.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})
