<template>
    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: perspective.id } }" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-200 shadow-xs hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4">
                <ui-avatar :item="perspective" class="w-8 h-8 drop-shadow-xs shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 flex gap-x-2 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{ perspective.name }}
                    </h1>

                    <span class="text-gray-500 text-sm" v-tooltip="'Pinned'" v-if="perspective.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>

                    <span class="text-gray-500 text-sm" v-tooltip="'Notifications enabled'" v-if="hasNotificationsEnabled">
                        <ui-icon name="bell"></ui-icon>
                    </span>
                </div>

                <div class="shrink-0">
                    <contextual-menu :perspective="perspective" plain></contextual-menu>
                </div>
            </div>

            <div class="relative h-16">
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>

                <analysis class="h-16" :options="analysisOptions" ref="analysis" @mousemove="chartMouseMove" @mouseleave="chartMouseLeave" v-else-if="shouldRenderChart"></analysis>

                <div class="absolute -bottom-px z-10" :style="pointMarkerStyle" v-if="pointMarkerStyle">
                    <div class="w-0 h-0 absolute left-0 bottom-px border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-gray-200"></div>
                    <div class="w-0 h-0 absolute left-0 bottom-0 border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-white"></div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-14 border-t border-gray-100 rounded-b">
                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Content
                    </div>
                    <div class="font-semibold text-sm">
                        {{stats.publishedTotal !== null ? $number(stats.publishedTotal) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900/50 rounded-sm hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(perspective)" v-if="cardsStore?.chooseCallback">
                <div class="rounded-sm border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>

    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: perspective.id } }" class="block" v-else-if="mode == 'row' || mode == 'simple'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <ui-avatar :item="perspective" class="w-7 h-7 drop-shadow-xs shrink-0"></ui-avatar>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{ perspective.name }}
                    </h1>
                </div>

                <span class="text-gray-500 text-sm" v-tooltip="'Pinned'" v-if="perspective.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                </span>

                <span class="text-gray-500 text-sm" v-tooltip="'Notifications enabled'" v-if="hasNotificationsEnabled">
                    <ui-icon name="bell"></ui-icon>
                </span>
            </div>

            <div v-if="mode != 'simple'" class="flex items-center w-64 px-2 py-2 relative">
                <div class="h-full w-full flex flex-col items-center justify-center text-center text-gray-600" v-if="! analysisSeries">
                    <div class="text-sm font-medium leading-tight">Analysis in progress.</div>
                    <div class="text-2xs">Activity chart will be available shortly.</div>
                </div>
                <analysis class="h-full w-full" :options="analysisOptions" ref="analysis" @mousemove="chartMouseMove" @mouseleave="chartMouseLeave" v-else-if="shouldRenderChart"></analysis>
            </div>

            <div v-if="mode != 'simple'" class="flex items-center space-x-3 px-3">
                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Content
                    </div>
                    <div class="font-semibold text-sm">
                        {{stats.publishedTotal !== null ? $number(stats.publishedTotal) : '-'}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :perspective="perspective" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900/50 rounded-sm hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(perspective)" v-if="cardsStore?.chooseCallback">
                <div class="rounded-sm border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import ContextualMenu from '@/components/content/contextual-menus/perspective'

import { Chart as Analysis } from 'highcharts-vue'

import colorHash from '@/helpers/color-hash'
import { removeHTML } from '@/helpers/html'
import { utcTimestamp } from '@/helpers/datetime'

import { group } from 'd3-array'
import { utcMondays } from 'd3-time'
import { format, startOfWeek, sub, parseISO } from 'date-fns'
import debounce from 'just-debounce-it'
import mapValues from 'just-map-values'

export default {
    props: { cardsStore: {}, mode: { default: 'card' }, perspective: {} },

    components: { Analysis, ContextualMenu },

    data: () => ({
        shouldRenderChart: false,
        intersectionObserver: null,
        selectedPoint: null
    }),

    computed: {
        analysisOptions() {
            return {
                chart: {
                    backgroundColor: false,
                    spacing: [0, 15, 0, 15]
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                    visible: false,
                    min: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 })),
                    max: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 1 })),
                    left: this.mode == 'card' ? '10%' : null,
                    width: this.mode == 'card' ? '80%' : null
                },
                yAxis: [
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.perspective.metrics.recent.performance,
                            align: 'left',
                            padding: 30,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.perspective.metrics.recent.performance ? null : 10,
                        visible: this.mode == 'card'
                    },
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.perspective.metrics.recent.performance,
                            align: 'right',
                            padding: 0,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        opposite: true,
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.perspective.metrics.recent.performance ? null : 10,
                        visible: this.mode == 'card'
                    }
                ],
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    distance: 0,
                    padding: 4,
                    formatter: function () { return `<span style="font-size:10px;font-weight:600;">${format(this.x, 'yyyy-MM-dd')}</span>` },
                    shadow: false,
                    shape: 'rect',
                    style: { color: '#fff', textAlign: 'center' }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            fillColor: '#FFFFFF',
                            radius: 4,
                            lineWidth: 2,
                            lineColor: null,
                            enabled: this.mode == 'card'
                        }
                    },
                    series: {
                        states: {
                            inactive: {
                                enabled: false
                            }
                        }
                    }
                },
                series: [
                    {
                        type: 'spline',
                        name: 'Interactions',
                        color: this.analysisColor,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[1],
                        yAxis: 0
                    },
                    {
                        type: 'spline',
                        name: 'Activity',
                        color: this.analysisColor,
                        opacity: 0.4,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[0],
                        yAxis: 1
                    }
                ],
                credits: {
                    enabled: false
                }
            }
        },

        analysisColor() {
            return this.perspective.metrics.recent.performance
                ? (this.perspective.color || colorHash(this.perspective.name))
                : '#e0e5e8'
        },

        analysisSeries() {
            if (! (this.perspective.metrics.recent.performance?.[0] instanceof Array)) return

            return this.perspective.metrics.recent.performance.map(series => {
                let data = series.map(({x, y}) => ({
                    x: parseISO(x), y: y ? Math.round(parseFloat(y) * 10000) / 10000 : 0
                }))

                let m = group(data, ({x, y}) => x)
                let timeRange = utcMondays(
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 }),
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 0 }),
                    1
                )

                return timeRange.map(date => m.get(date)?.[0] || { x: date, y: 0 })
            })
        },

        stats() {
            if (this.selectedPoint == null || ! this.analysisSeries) return this.averageStats

            let stats = {
                publishedTotal: this.perspective.metrics.allTime.publishedTotal,
                publishedPerWeek: this.analysisSeries?.[1]?.[this.selectedPoint]?.y,
                interactionsPerWeek: this.analysisSeries?.[0]?.[this.selectedPoint]?.y
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        averageStats() {
            let stats = {
                publishedTotal: this.perspective.metrics.allTime.publishedTotal,
                publishedPerWeek: this.perspective.metrics.recent.publishedPerWeek,
                interactionsPerWeek: this.perspective.metrics.recent.interactionsPerWeek
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        pointMarkerStyle() {
            if (! this.analysisSeries || this.selectedPoint == null) return

            return `left: calc(10px + (${this.selectedPoint} * 2 + 1) * ((100% - 30px) / 10));`
        },

        hasNotificationsEnabled() {
            return this.perspective.alerts?.some(s => s.enabled)
        },

        tooltip() {
            return { content: `<b>${this.perspective.name}</b><br>${removeHTML(this.perspective.description)}`, html: true }
        }
    },

    methods: {
        chartMouseMove(ev) {
            this.updateSelectedPoint(ev.offsetX, ev.currentTarget) // this is intentionally done in two steps to preserve the currentTarget reference
        },

        chartMouseLeave() {
            this.selectedPoint = null
        },

        updateSelectedPoint: debounce(function(offsetX, currentTarget) {
            let chartContainerWidth = currentTarget.offsetWidth

            if (offsetX <= 15) return this.selectedPoint = 0
            if (offsetX >= chartContainerWidth - 15) return this.selectedPoint = 4

            this.selectedPoint = Math.floor((offsetX - 15) / (chartContainerWidth - 30) * 5)
        }, 5)
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(e => e.isIntersecting)) {
                this.shouldRenderChart = true
                this.intersectionObserver.disconnect()
            }
        })
        this.intersectionObserver.observe(this.$el)
    },

    unmounted() {
        this.intersectionObserver?.disconnect()
    }
}
</script>
