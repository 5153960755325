export const languages = [
    { code: 'aa', name: 'Afar' },
    { code: 'ab', name: 'Abkhazian' },
    { code: 'ae', name: 'Avestan' },
    { code: 'af', name: 'Afrikaans', translatable: true },
    { code: 'ak', name: 'Akan', translatable: true },
    { code: 'am', name: 'Amharic', translatable: true },
    { code: 'an', name: 'Aragonese' },
    { code: 'ar', name: 'Arabic', icon: 'flags.sa', rtl: true, translatable: true },
    { code: 'as', name: 'Assamese', translatable: true },
    { code: 'av', name: 'Avaric' },
    { code: 'ay', name: 'Aymara', translatable: true },
    { code: 'az', name: 'Azerbaijani', translatable: true },
    { code: 'ba', name: 'Bashkir', translatable: true },
    { code: 'be', name: 'Belarusian', icon: 'flags.by', translatable: true },
    { code: 'bg', name: 'Bulgarian',icon: 'flags.bg', translatable: true },
    { code: 'bh', name: 'Bihari languages' },
    { code: 'bi', name: 'Bislama' },
    { code: 'bm', name: 'Bambara', translatable: true },
    { code: 'bn', name: 'Bengali', translatable: true },
    { code: 'bo', name: 'Tibetan', translatable: true },
    { code: 'br', name: 'Breton' },
    { code: 'bs', name: 'Bosnian', translatable: true },
    { code: 'ca', name: 'Catalan', translatable: true },
    { code: 'ce', name: 'Chechen' },
    { code: 'ch', name: 'Chamorro' },
    { code: 'co', name: 'Corsican' },
    { code: 'cr', name: 'Cree' },
    { code: 'cs', name: 'Czech', icon: 'flags.cz', translatable: true },
    { code: 'cu', name: 'Church Slavic' },
    { code: 'cv', name: 'Chuvash' },
    { code: 'cy', name: 'Welsh', translatable: true },
    { code: 'da', name: 'Danish', icon: 'flags.dk', translatable: true },
    { code: 'de', name: 'German', icon: 'flags.de', translatable: true },
    { code: 'dv', name: 'Divehi', rtl: true },
    { code: 'dz', name: 'Dzongkha', translatable: true },
    { code: 'ee', name: 'Ewe', translatable: true },
    { code: 'el', name: 'Greek', icon: 'flags.gr', translatable: true },
    { code: 'en', name: 'English', icon: 'flags.uk', translatable: true },
    { code: 'eo', name: 'Esperanto', translatable: true },
    { code: 'es', name: 'Spanish', icon: 'flags.es', translatable: true },
    { code: 'et', name: 'Estonian', icon: 'flags.ee', translatable: true },
    { code: 'eu', name: 'Basque', translatable: true },
    { code: 'fa', name: 'Persian', icon: 'flags.ir', rtl: true, translatable: true },
    { code: 'ff', name: 'Fulah' },
    { code: 'fi', name: 'Finnish', icon: 'flags.fi', translatable: true },
    { code: 'fj', name: 'Fijian', translatable: true },
    { code: 'fo', name: 'Faroese', translatable: true },
    { code: 'fr', name: 'French', icon: 'flags.fr', translatable: true },
    { code: 'fy', name: 'Western Frisian' },
    { code: 'ga', name: 'Irish', icon: 'flags.ie', translatable: true },
    { code: 'gd', name: 'Gaelic', translatable: true },
    { code: 'gl', name: 'Galician', translatable: true },
    { code: 'gn', name: 'Guarani', translatable: true },
    { code: 'gu', name: 'Gujarati', translatable: true },
    { code: 'gv', name: 'Manx' },
    { code: 'ha', name: 'Hausa', rtl: true, translatable: true },
    { code: 'he', name: 'Hebrew', icon: 'flags.il', rtl: true, translatable: true },
    { code: 'hi', name: 'Hindi', icon: 'flags.in', translatable: true },
    { code: 'ho', name: 'Hiri Motu' },
    { code: 'hr', name: 'Croatian', translatable: true },
    { code: 'ht', name: 'Haitian', translatable: true },
    { code: 'hu', name: 'Hungarian', icon: 'flags.hu', translatable: true },
    { code: 'hy', name: 'Armenian', icon: 'flags.am', translatable: true },
    { code: 'hz', name: 'Herero' },
    { code: 'ia', name: 'Interlingua' },
    { code: 'id', name: 'Indonesian', icon: 'flags.id', translatable: true },
    { code: 'ie', name: 'Interlingue' },
    { code: 'ig', name: 'Igbo', translatable: true },
    { code: 'ii', name: 'Sichuan Yi' },
    { code: 'ik', name: 'Inupiaq' },
    { code: 'io', name: 'Ido' },
    { code: 'is', name: 'Icelandic', translatable: true },
    { code: 'it', name: 'Italian', icon: 'flags.it', translatable: true },
    { code: 'iu', name: 'Inuktitut' },
    { code: 'ja', name: 'Japanese', icon: 'flags.jp', translatable: true },
    { code: 'jv', name: 'Javanese', translatable: true },
    { code: 'ka', name: 'Georgian', translatable: true },
    { code: 'kg', name: 'Kongo', translatable: true },
    { code: 'ki', name: 'Kikuyu', translatable: true },
    { code: 'kj', name: 'Kuanyama' },
    { code: 'kk', name: 'Kazakh', translatable: true },
    { code: 'kl', name: 'Kalaallisut' },
    { code: 'km', name: 'Central Khmer', translatable: true },
    { code: 'kn', name: 'Kannada', translatable: true },
    { code: 'ko', name: 'Korean', icon: 'flags.kr', translatable: true },
    { code: 'kr', name: 'Kanuri' },
    { code: 'ks', name: 'Kashmiri', rtl: true, translatable: true },
    { code: 'ku', name: 'Kurdish', rtl: true },
    { code: 'kv', name: 'Komi' },
    { code: 'kw', name: 'Cornish' },
    { code: 'ky', name: 'Kirghiz', translatable: true },
    { code: 'la', name: 'Latin' },
    { code: 'lb', name: 'Luxembourgish', translatable: true },
    { code: 'lg', name: 'Ganda', translatable: true },
    { code: 'li', name: 'Limburgan', translatable: true },
    { code: 'ln', name: 'Lingala', translatable: true },
    { code: 'lo', name: 'Lao', translatable: true },
    { code: 'lt', name: 'Lithuanian', icon: 'flags.lt', translatable: true },
    { code: 'lu', name: 'Luba-Katanga' },
    { code: 'lv', name: 'Latvian', icon: 'flags.lv', translatable: true },
    { code: 'mg', name: 'Malagasy' },
    { code: 'mh', name: 'Marshallese' },
    { code: 'mi', name: 'Maori', translatable: true },
    { code: 'mk', name: 'Macedonian', translatable: true },
    { code: 'ml', name: 'Malayalam', translatable: true },
    { code: 'mn', name: 'Mongolian', translatable: true },
    { code: 'mr', name: 'Marathi', translatable: true },
    { code: 'ms', name: 'Malay', translatable: true },
    { code: 'mt', name: 'Maltese', translatable: true },
    { code: 'my', name: 'Burmese', translatable: true },
    { code: 'na', name: 'Nauru' },
    { code: 'nb', name: 'Bokmål', translatable: true },
    { code: 'nd', name: 'Ndebele' },
    { code: 'ne', name: 'Nepali', translatable: true },
    { code: 'ng', name: 'Ndonga' },
    { code: 'nl', name: 'Dutch', icon: 'flags.nl', translatable: true },
    { code: 'nn', name: 'Norwegian Nynorsk', translatable: true },
    { code: 'no', name: 'Norwegian', icon: 'flags.no' },
    { code: 'nr', name: 'Ndebele' },
    { code: 'nv', name: 'Navajo' },
    { code: 'ny', name: 'Chichewa', translatable: true },
    { code: 'oc', name: 'Occitan', translatable: true },
    { code: 'oj', name: 'Ojibwa' },
    { code: 'om', name: 'Oromo', translatable: true },
    { code: 'or', name: 'Oriya', translatable: true },
    { code: 'os', name: 'Ossetian' },
    { code: 'pa', name: 'Panjabi', translatable: true },
    { code: 'pi', name: 'Pali' },
    { code: 'pl', name: 'Polish', icon: 'flags.pl', translatable: true },
    { code: 'ps', name: 'Pushto', rtl: true, translatable: true },
    { code: 'pt', name: 'Portuguese', icon: 'flags.pt', translatable: true },
    { code: 'qu', name: 'Quechua', translatable: true },
    { code: 'rm', name: 'Romansh' },
    { code: 'rn', name: 'Rundi', translatable: true },
    { code: 'ro', name: 'Romanian', icon: 'flags.ro', translatable: true },
    { code: 'ru', name: 'Russian', icon: 'flags.ru', translatable: true },
    { code: 'rw', name: 'Kinyarwanda', translatable: true },
    { code: 'sa', name: 'Sanskrit', translatable: true },
    { code: 'sc', name: 'Sardinian', translatable: true },
    { code: 'sd', name: 'Sindhi', translatable: true },
    { code: 'se', name: 'Northern Sami' },
    { code: 'sg', name: 'Sango', translatable: true },
    { code: 'si', name: 'Sinhala', translatable: true },
    { code: 'sk', name: 'Slovak', icon: 'flags.sk', translatable: true },
    { code: 'sl', name: 'Slovenian', icon: 'flags.si', translatable: true },
    { code: 'sm', name: 'Samoan', translatable: true },
    { code: 'sn', name: 'Shona', translatable: true },
    { code: 'so', name: 'Somali', translatable: true },
    { code: 'sq', name: 'Albanian', translatable: true },
    { code: 'sr', name: 'Serbian', icon: 'flags.rs', translatable: true },
    { code: 'ss', name: 'Swati', translatable: true },
    { code: 'st', name: 'Sotho', translatable: true },
    { code: 'su', name: 'Sundanese', translatable: true },
    { code: 'sv', name: 'Swedish', icon: 'flags.se', translatable: true },
    { code: 'sw', name: 'Swahili', translatable: true },
    { code: 'ta', name: 'Tamil', translatable: true },
    { code: 'te', name: 'Telugu', translatable: true },
    { code: 'tg', name: 'Tajik', translatable: true },
    { code: 'th', name: 'Thai', icon: 'flags.th', translatable: true },
    { code: 'ti', name: 'Tigrinya', translatable: true },
    { code: 'tk', name: 'Turkmen', translatable: true },
    { code: 'tl', name: 'Tagalog', translatable: true },
    { code: 'tn', name: 'Tswana', translatable: true },
    { code: 'to', name: 'Tonga' },
    { code: 'tr', name: 'Turkish', icon: 'flags.tr', translatable: true },
    { code: 'ts', name: 'Tsonga', translatable: true },
    { code: 'tt', name: 'Tatar', translatable: true },
    { code: 'tw', name: 'Twi', translatable: true },
    { code: 'ty', name: 'Tahitian' },
    { code: 'ug', name: 'Uighur', translatable: true },
    { code: 'uk', name: 'Ukrainian', icon: 'flags.ua', translatable: true },
    { code: 'ur', name: 'Urdu', rtl: true, translatable: true },
    { code: 'uz', name: 'Uzbek', translatable: true },
    { code: 've', name: 'Venda' },
    { code: 'vi', name: 'Vietnamese', translatable: true },
    { code: 'vo', name: 'Volapük' },
    { code: 'wa', name: 'Walloon' },
    { code: 'wo', name: 'Wolof', translatable: true },
    { code: 'xh', name: 'Xhosa', translatable: true },
    { code: 'yi', name: 'Yiddish', rtl: true, translatable: true },
    { code: 'yo', name: 'Yoruba', translatable: true },
    { code: 'za', name: 'Zhuang' },
    { code: 'zh', name: 'Chinese', icon: 'flags.cn', translatable: true },
    { code: 'zu', name: 'Zulu', translatable: true },
    { code: '00', name: 'Unknown' }
]

export default function language(code) {
    if (code instanceof Array) return code.map(code => language(code))

    return languages.find(l => l.code == code)
}
