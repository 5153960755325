import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'
import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'
import useTaggingStore from '@/stores/tagging/tagging'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useStreamStore from '@/stores/content/perspective/stream'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useMyModalsTagsManageTagEditStore = defineForm({
    id: 'myModalsTagsManageTagEdit',

    inputs: () => ({
        tag: {},
        group: {},
        parent: {},
        name: null,
        color: null
    }),

    submitRequest() {
        return api.route(this.inputs.tag?.id ? 'me tags update' : 'me tags store', { id: this.inputs.tag.id })
            .formData({
                _method: this.inputs.tag.id ? 'put' : 'post',
                groupId: this.inputs.group.id,
                parentId: this.inputs.parent.id,
                name: this.inputs.name,
                color: this.inputs.color
            })
            .post()
    },

    onResponse() {
        useTaggingStore().reload().then(() => useMyModalsTagsManageStore().reset(this.inputs.group.id))
    },

    actions: {
        show(tag, group, parent) {
            this.reset()

            this.inputs.tag = tag || {}
            this.inputs.group = group || {}
            this.inputs.parent = parent || {}
            this.inputs.name = tag?.name
            this.inputs.color = tag?.color

            useMyModalsTagsManageStore().mode = 'edit-tag'
        },

        cancel() {
            useMyModalsTagsManageStore().reset()
        },

        async delete() {
            return useDeleteConfirmationModal().open('Tag', { id: this.inputs.tag.id }, 'me tags used-by').then(async () => {
                await api.route('me tags delete', { id: this.inputs.tag.id }).delete().res()

                useTaggingStore().reload().then(() => useMyModalsTagsManageStore().reset())
                useMyPerspectivesStore().reload()

                if (useStreamStore().filters.value('tags')?.tags.includes(this.inputs.tag.id)) {
                    useStreamStore().filters.set('tags', {
                        mode: useStreamStore().filters.value('tags').mode,
                        tags: useStreamStore().filters.value('tags').tags.filter(tag => tag != this.inputs.tag.id)
                    })

                    if (! useStreamStore().filters.value('tags').tags.length) {
                        useStreamStore().filters.remove('tags')
                    }
                }
            })
        }
    }
})

export default useMyModalsTagsManageTagEditStore
