<template>
    <div class="w-full shrink-0 bg-white shadow-lg rounded-lg pointer-events-auto border border-gray-200 flex items-center overflow-hidden mb-4 group relative">
        <div class="shrink-0 flex items-center justify-center text-xl py-3 ml-3">
            <ui-progress v-if="notification.taskId && level == 'info' && notification.progress.completedUnits < notification.progress.totalUnits" :total-steps="notification.progress.totalUnits" :completed-steps="notification.progress.completedUnits"></ui-progress>
            <ui-spinner v-else-if="notification.taskId && level == 'info'" color="#3b82f6" type="clip"></ui-spinner>
            <ui-avatar v-else :item="{ avatar, name: title }" :class="{'text-green-600': level == 'success', 'text-red-600': level == 'error', 'text-blue-600': level == 'info'}" class="w-7 h-7"></ui-avatar>
        </div>
        <div class="w-0 flex-1 py-3.5 ml-2 mr-3">
            <div class="flex justify-between items-start">
                <div class="text-sm font-medium" :class="{'text-green-600': level == 'success', 'text-red-600': level == 'error', 'text-blue-600': level == 'info'}" v-html="title"></div>
                <div class="text-2xs text-gray-500 ml-2">{{createdAt}}</div>
            </div>
            <p class="text-sm text-gray-800" v-if="notification.body" v-html="body"></p>
        </div>
        <div class="hidden group-hover:flex justify-end bg-linear-to-r from-transparent to-white absolute inset-0">
            <div class="flex flex-col justify-center py-1">
                <button v-if="! noDismiss" class="w-full px-3 py-1 text-sm font-medium text-blue-600 hover:text-blue-900" @click="dismissToast(notification)">
                    Dismiss
                </button>
                <button v-for="action, index in notification.actions" :key="index" class="w-full px-3 py-1 text-sm font-medium text-blue-600 hover:text-blue-900" @click="action.onAction(notification)">
                    {{action.label}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import useMyNotificationsStore from '@/stores/me/notifications'

import { sanitizeHTML } from '@/helpers/html'

import { format, parseISO } from 'date-fns'
import { mapActions } from 'pinia'

export default {
    props: { notification: {}, noDismiss: Boolean },

    data: () => ({
        currentDate: +(new Date)
    }),

    mounted() {
        setInterval(() => this.currentDate = +(new Date), 60000)
    },

    computed: {
        avatar() {
            return this.notification.avatar
                ? {
                    color: this.notification.avatar.icon ? { icon: { success: 'text-green-600', error: 'text-red-600', info: 'text-blue-600' }[this.notification.level], background: 'bg-none' } : null,
                    ...this.notification.avatar
                }
                : {
                    icon: { 'success': 'check-full-circle', 'error': 'error-full-circle' }[this.notification.level] || 'info',
                    color: { 'success': { icon: 'text-green-600', background: 'bg-none' }, 'error': { icon: 'text-red-600', background: 'bg-none' }, 'info': { icon: 'text-blue-600', background: 'bg-none' } }[this.level]
                }
        },

        createdAt() {
            if (! this.notification.createdAt) return

            let date = this.notification.createdAt instanceof Date ? this.notification.createdAt : parseISO(this.notification.createdAt)
            let diffInSeconds = (this.currentDate - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do')
            }
        },
        
        body() {
            return sanitizeHTML(this.notification.body)
        },

        level() {
            return this.notification.level || 'info'
        },

        title() {
            return sanitizeHTML(this.notification.title)
        }
    },
    
    methods: {
        ...mapActions(useMyNotificationsStore, [ 'dismissToast' ])
    }
}
</script>
