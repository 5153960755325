import api from '@/api'
import { useModal } from '@/helpers'
import { dateTime } from '@/helpers/datetime'
import { downloadBlob } from '@/helpers/download'

import { defineStore } from 'pinia'

export default defineStore('modalsExportOptions', {
    state: () => ({
        streamStore: null,
        query: null,
        filters: null,
        sorting: null,
        endpoint: null,
        isExporting: false,

        limit: 1000,
        filename: '',
        format: 'xlsx',
        utc: false,
        includeNotes: false,
        includePersonalNotes: false
    }),

    actions: {
        reset() {
            this.$reset()
        },

        open({ streamStore, query, filters, sorting, endpoint }) {
            this.reset()

            this.filename = `export_${dateTime(new Date(), 'd_MMM_yyyy_H_mm')}`

            if (streamStore) this.setStreamStore(streamStore)
            if (query) this.query = query
            this.filters = filters
            this.sorting = sorting
            this.endpoint = endpoint

            useModal().show('export-options')
        },

        cancel() {
            useModal().hide('export-options')
        },

        setStreamStore(streamStore) {
            this.streamStore = streamStore

            const name = this.streamStore.appliedPerspective?.name || this.streamStore.table || 'Content'
            const slug = name.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '_')
                .replace(/^-+|-+$/g, '')
                .slice(0, 40)

            this.filename = `${slug}_export_${dateTime(new Date(), 'd_MMM_yyyy_H_mm')}`
        },

        doExport() {
            this.isExporting = true

            let callback = res => {
                downloadBlob(res, this.filename, this.format)

                this.cancel()

                this.isExporting = false
            }

            let options = {
                includeNotes: this.includeNotes,
                includePersonalNotes: this.includePersonalNotes
            }

            this.query ? this.query.query({ utc: this.utc, options }).limit(this.limit || 1000).export(this.format, callback) : this.makeRequest().blob(callback)
        },

        makeRequest() {
            return api.route(this.endpoint || this.streamStore.exportEndpoint).json({
                ...(this.filters ? { filters: this.filters.toJson() } : {}),
                sorting: this.sorting,
                ...(this.streamStore ? this.streamStore.apiQuery : {}),
                include: '',
                limit: this.limit || 1000,
                format: this.format,
                utc: this.utc,
                options: {
                    includeNotes: this.includeNotes,
                    includePersonalNotes: this.includePersonalNotes
                }
            }).post()
        }
    }
})
