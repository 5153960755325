import useAuthLoginForm from '@/stores/auth/login-form'

import { defineStore } from 'pinia'

export default defineStore('authLoginPage', {
    actions: {
        beforeEnter(to) {
            useAuthLoginForm().setActivationToken('')
        }
    }
})
