import { defineStore } from 'pinia'

export default defineStore('authPasswordRecoveryRequestPage', {
    state: () => ({
        submitted: false
    }),

    actions: {
        beforeEnter(to) {
            this.$reset()
        }
    }
})
