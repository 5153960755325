<template>
    <router-link :to="{ name: 'channels.channel.information', params: { id: channel.id } }" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-200 shadow-xs hover:shadow-lg cursor-pointer relative group" :class="{ 'bg-gray-50': ! channel.monitored }">
            <div class="flex items-center relative p-4">
                <div class="shrink-0 relative">
                    <ui-avatar :item="channel" class="w-8 h-8"></ui-avatar>
                    <ui-icon :name="channel.monitored ? `${$nodeType(channel).icon}` : `${$nodeType(channel).icon}-inactive`" v-tooltip="$nodeType(channel).name" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        <span v-tooltip="channel.name">{{ channel.name }}</span>
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        {{$nodeType(channel).name}}
                        <span class="h-3 w-3 rounded-full bg-blue-400 text-white flex items-center justify-center text-xs inline-flex ml-0.5 -mb-1" v-if="channel.verified">
                            <ui-icon name="check" class="text-[9px]"></ui-icon>
                        </span>
                        <span v-if="channel.username" v-tooltip="channel.username">
                            · @{{channel.username}}
                        </span>
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :channel="channel" :list="list" plain></contextual-menu>
                </div>
            </div>

            <div class="relative h-16">
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-if="! channel.monitored">
                    <div class="font-semibold">Channel not monitored.</div>
                    <div class="text-xs">Please enable monitoring to start data download.</div>
                </div>
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-else-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>

<!--                <analysis class="h-16" :options="analysisOptions" ref="analysis" @mousemove="chartMouseMove" @mouseleave="chartMouseLeave" v-else-if="shouldRenderChart"></analysis>-->

                <div class="absolute -bottom-px z-10" :style="pointMarkerStyle" v-if="pointMarkerStyle">
                    <div class="w-0 h-0 absolute left-0 bottom-px border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-gray-200"></div>
                    <div class="w-0 h-0 absolute left-0 bottom-0 border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-white"></div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-14 border-t border-gray-100 rounded-b">
                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Audience
                    </div>
                    <div class="font-semibold text-sm">
                        {{stats.followers !== null ? $number(stats.followers) : (stats.members !== null ? $number(stats.members) : '-')}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="mt-auto divide-y divide-gray-100 bg-gray-50 border-t border-gray-100 rounded-b-lg">
                <div class="flex items-center relative px-4 h-8 space-x-3 text-xs text-gray-700" v-if="isDisplayedViaCuratedList || isMonitored(channel)">
                    <span class="flex items-center space-x-1" v-if="isMonitored(channel)">
                        <ui-icon name="navigation.channels"></ui-icon>
                        <span>My Channels</span>
                    </span>

                    <span class="flex items-center space-x-1" v-if="isDisplayedViaCuratedList">
                        <ui-icon name="curated-list"></ui-icon>
                        <span>Via curated list</span>
                    </span>
                </div>

                <!-- Notes -->
                <card-notes :item="channel"></card-notes>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore?.chooseCallback ? 'div' : 'router-link'" :to="{ name: 'channels.channel.information', params: { id: channel.id } }" class="block hover:bg-linear-to-r from-transparent via-gray-50 to-transparent transition" v-else-if="mode == 'row' || mode == 'simple'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <div class="shrink-0 relative">
                    <ui-avatar :item="channel" class="w-7 h-7 drop-shadow-xs shrink-0"></ui-avatar>
                    <ui-icon :name="channel.monitored ? `${$nodeType(channel).icon}` : `${$nodeType(channel).icon}-inactive`" v-tooltip="$nodeType(channel).name" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! channel.monitored }"></ui-icon>
                </div>

                <div class="min-w-0 px-3 space-x-2">
                    <span class="text-xl font-semibold truncate leading-tight" v-tooltip="channel.name">{{ channel.name }}</span>
                    <div class="h-4 w-4 rounded-full bg-blue-500 text-white flex items-center justify-center text-xs inline-flex -mb-1"
                         v-if="channel.verified">
                        <ui-icon name="check" class="text-2xs"></ui-icon>
                    </div>
                    <span v-if="channel.username" v-tooltip="channel.username" class="text-sm text-gray-700">
                        {{channel.username}}
                    </span>
                </div>
            </div>

            <div class="flex items-center gap-x-2 px-2">
                <ui-icon name="navigation.channels" class="text-gray-700" v-tooltip="'Monitored'" v-if="isMonitored(channel)"></ui-icon>
                <ui-icon name="curated-list" class="text-gray-700" v-tooltip="'Via curated list'" v-if="isDisplayedViaCuratedList"></ui-icon>

                 <div class="flex space-x-2 items-center text-gray-900" v-if="channel.monitoringOptions && channel.monitoringOptions.length">
                    <span class="px-1 bg-gray-700 text-white text-2xs rounded-sm" :key="option" v-for="option in channel.monitoringOptions" v-tooltip="monitoringOptionInfo(option).name">
                        {{monitoringOptionInfo(option).short}}
                    </span>
                </div>

                <div class="flex space-x-2 items-center text-gray-900" v-if="channel.lists && channel.lists.length">
                    <ui-avatar class="w-5 h-5" :item="list" :key="list.id" v-tooltip="list.name" v-for="list in lists"></ui-avatar>
                </div>
            </div>

            <div v-if="mode != 'simple' && analysisSeries" class="flex items-center w-64 px-2 py-2 relative">
                <analysis class="h-full w-full" :options="analysisOptions" ref="analysis" v-if="shouldRenderChart"></analysis>
            </div>

            <div v-if="mode != 'simple'" class="flex items-center space-x-3 px-3">
                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Audience
                    </div>
                    <div class="font-semibold text-sm">
                        {{stats.followers !== null ? $number(stats.followers) : (stats.members !== null ? $number(stats.members) : '-')}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="w-20">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :channel="channel" :list="cardsStore?.list" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900/50 rounded-sm hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(channel)" v-if="cardsStore?.chooseCallback">
                <div class="rounded-sm border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import CardNotes from '@/components/me/notes/card-notes'
import ContextualMenu from '@/components/channels/contextual-menus/channel'

import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'
import useMyChannelListsStore from '@/stores/me/channel-lists'

import { utcTimestamp } from '@/helpers/datetime'

import { Chart as Analysis } from 'highcharts-vue'

import { group } from 'd3-array'
import { utcMondays } from 'd3-time'
import { format, parseISO, startOfWeek, sub } from 'date-fns'
import debounce from 'just-debounce-it'
import { mapActions } from 'pinia'
import mapValues from 'just-map-values'

export default {
    props: { cardsStore: {}, list: {}, mode: { default: 'card' }, channel: {} },

    components: { Analysis, CardNotes, ContextualMenu },

    data: () => ({
        shouldRenderChart: false,
        intersectionObserver: null,
        selectedPoint: null,

        monitoringOptions: [
            { name: 'Image Text Extraction', short: 'ITR', id: 'imageTextExtraction' },
            { name: 'Video Retention', short: 'VR', id: 'videoDownload' },
            { name: 'Video Transcription', short: 'VT', id: 'videoTranscription' }
        ]
    }),

    computed: {
         analysisOptions() {
             return {
                 chart: {
                     backgroundColor: false,
                     spacing: [0, 15, 0, 15]
                 },
                 boost: {
                     useGPUTranslations: true
                 },
                 title: {
                     text: '',
                 },
                 xAxis: {
                     type: 'datetime',
                     visible: false,
                     min: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 })),
                     max: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 1 }))
                 },
                 yAxis: [
                     {
                         title: { text: '' },
                         labels: {
                             enabled: !! this.channel.metrics.performance,
                             align: 'left',
                             padding: 0,
                             x: 0,
                             y: 14,
                             zIndex: 1,
                             style: { color: '#b8c3c9', fontSize: '9px' }
                         },
                         tickPixelInterval: 50,
                         gridLineColor: '#f0f3f5',
                         max: this.channel.metrics.performance ? null : 10
                     },
                     {
                         title: { text: '' },
                         labels: {
                             enabled: !! this.channel.metrics.performance,
                             align: 'right',
                             padding: 0,
                             x: 0,
                             y: 14,
                             zIndex: 1,
                             style: { color: '#b8c3c9', fontSize: '9px' }
                         },
                         opposite: true,
                         tickPixelInterval: 50,
                         gridLineColor: '#f0f3f5',
                         max: this.channel.metrics.performance ? null : 10
                     }
                 ],
                 tooltip: {
                     backgroundColor: 'rgba(107, 114, 128, 0.8)',
                     borderColor: 'rgb(156, 163, 175)',
                     borderRadius: 7,
                     distance: 0,
                     padding: 4,
                     formatter: function () { return `<span style="font-size:10px;font-weight:600;">${format(this.x, 'yyyy-MM-dd')}</span>` },
                     shadow: false,
                     shape: 'rect',
                     style: { color: '#fff', textAlign: 'center' }
                 },
                 legend: {
                     enabled: false
                 },
                 plotOptions: {
                     line: {
                         marker: {
                             fillColor: '#FFFFFF',
                             radius: 4,
                             lineWidth: 2,
                             lineColor: null,
                         }
                     },
                     series: {
                         states: {
                             inactive: {
                                 enabled: false
                             }
                         }
                     }
                 },
                 series: [
                     {
                         type: 'line',
                         name: 'Interactions',
                         color: this.analysisColor,
                         fillOpacity: 0.1,
                         data: this.analysisSeries[0],
                         yAxis: 0
                     },
                     {
                         type: 'column',
                         name: 'Activity',
                         color: this.analysisColor,
                         opacity: 0.4,
                         fillOpacity: 0.1,
                         data: this.analysisSeries[1],
                         yAxis: 1
                     }
                 ],
                 credits: {
                     enabled: false
                 }
             }
         },

         analysisColor() {
             if (! this.channel.metrics.performance) return '#e0e5e8'

             return {
                 'facebook-group': '#4267B2',
                 'facebook-page': '#4267B2',
                 'facebook-profile': '#4267B2',
                 'telegram-channel': '#4798BB',
                 'telegram-group': '#4798BB',
                 'telegram-user': '#4798BB',
                 'x-user': '#01C5D3',
                 'web-feed': '#F49E28',
                 'youtube-channel': '#F14539'
             }[this.channel.type] || '#e0e5e8'
         },

         analysisSeries() {
             if (! (this.channel.metrics.performance?.[0] instanceof Array)) return

             return this.channel.metrics.performance.map(series => {
                 let data = series.map(({x, y}) => ({
                     x: parseISO(x), y: y ? Math.round(parseFloat(y) * 10000) / 10000 : 0
                 }))

                 let m = group(data, ({x, y}) => x)
                 let timeRange = utcMondays(
                     sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 }),
                     sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 0 }),
                     1
                 )

                 return timeRange.map(date => m.get(date)?.[0] || { x: date, y: 0 })
             })
         },

        stats() {
            if (this.selectedPoint == null || ! this.analysisSeries) return this.averageStats

            let stats = {
                followers: this.channel?.metrics?.followers,
                members: this.channel?.metrics?.members,
                publishedPerWeek: this.analysisSeries?.[1]?.[this.selectedPoint]?.y,
                interactionsPerWeek: this.analysisSeries?.[0]?.[this.selectedPoint]?.y
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        averageStats() {
            let stats = {
                followers: this.channel?.metrics?.followers,
                members: this.channel?.metrics?.members,
                publishedPerWeek: this.channel.metrics.publishedPerWeek,
                interactionsPerWeek: this.channel.metrics.interactionsPerWeek
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        pointMarkerStyle() {
            if (! this.analysisSeries || ! this.channel.monitored) return

            return `left: calc(10px + (${this.selectedPoint} * 2 + 1) * ((100% - 30px) / 10));`
        },

        healthClass() {
            if (this.channel.health == 'bad') {
                return 'text-red-400'
            } else if (this.channel.health == 'poor') {
                return 'text-orange-400'
            }

            return 'text-green-400'
        },

        isDisplayedViaCuratedList() {
            return this.list && (this.isCuratedList(this.list) || (! this.isCuratedList(this.list) && this.list.parentList && ! this.channel.lists.some(l => l.id === this.list.id)))
        },

        lists() {
            return this.channel.lists.filter(l => l.type == 'list')
        }
    },

    methods: {
        ...mapActions(useMyMonitoredChannelsStore, [ 'isMonitored' ]),

        ...mapActions(useMyChannelListsStore, {
            'isCuratedList': 'isCurated'
        }),

        chartMouseMove(ev) {
            this.updateSelectedPoint(ev.offsetX, ev.currentTarget) // this is intentionally done in two steps to preserve the currentTarget reference
        },

        chartMouseLeave() {
            this.selectedPoint = null
        },

        updateSelectedPoint: debounce(function(offsetX, currentTarget) {
            let chartContainerWidth = currentTarget.offsetWidth

            if (offsetX <= 15) return this.selectedPoint = 0
            if (offsetX >= chartContainerWidth - 15) return this.selectedPoint = 4

            this.selectedPoint = Math.floor((offsetX - 15) / (chartContainerWidth - 30) * 5)
        }, 5),

        monitoringOptionInfo(option) {
            return this.monitoringOptions.find(o => o.id == option)
        }
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(e => e.isIntersecting)) {
                this.shouldRenderChart = true
                this.intersectionObserver.disconnect()
            }
        })
        this.intersectionObserver.observe(this.$el)
    },

    unmounted() {
        this.intersectionObserver?.disconnect()
    }
}
</script>
