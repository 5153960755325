<template>
    <transition
        class="transition duration-150"
        enter-from-class="opacity-0"
        enter-active-class="opacity-full"
        leave-active-class="opacity-0"
    >
        <div class="fixed bottom-0 right-0 max-w-lg pl-16 w-full h-full p-4 pt-0 pb-20 z-40 flex flex-col justify-end bg-linear-to-r from-transparent to-[#2c404c75]" v-show="isShown">
            <template v-if="thread">
                <div class="overflow-y-auto flex-1 py-4" ref="messages">
                    <div class="flex flex-col justify-end space-y-4 min-h-full">
                        <message-card v-for="message in thread.messages" :key="message.id" :message="message"></message-card>
                    </div>
                </div>

                <div v-if="thread.name === `I'd like to report a problematic or illegal content`" class="flex items-center gap-x-3 rounded-xl p-3 border shadow-xs bg-gray-100 border-gray-200 text-sm mb-4 text-red-700">
                    <ui-icon name="warning" class="text-2xl text-red-700 shrink-0"></ui-icon>

                    <div class="flex flex-col gap-y-3">
                        <p>While we try to process the collected content and avoid harvesting any disturbing or illegal material, we may miss some of it.</p>
                        <p>If you happen to discover any illegal or dangerous content (eg. child or non-consensual pornography, violent gore etc.), please let us know and we'll manually review it as soon as possible.</p>
                        <p>Thank you for helping us improve!</p>
                    </div>
                </div>

                <div v-if="! thread.messages.length && ! isLoadingMessages" class="rounded-xl p-3 border shadow-xs bg-gray-100 border-gray-200 text-sm space-y-2 mb-4">
                    <div class="flex items-center gap-x-3">
                        <ui-icon name="message-circle" class="text-2xl text-gray-600 shrink-0"></ui-icon>
                        <p>Please describe your problem and press Enter to send your message. Use Shift+Enter to enter a new-line.</p>
                    </div>
                    <div class="flex items-center gap-x-3">
                        <ui-icon name="bell" class="text-2xl text-gray-600 shrink-0"></ui-icon>
                        <p>We will respond as soon as possible, you will receive a notification on reply. You can continue using the app in the meantime.</p>
                    </div>
                    <div class="flex items-center gap-x-3">
                        <ui-icon name="check" class="text-2xl text-gray-600 shrink-0"></ui-icon>
                        <p>Once your issue is resolved, please close the chat using the Resolve button and rate your support experience.</p>
                    </div>
                </div>

                <div v-if="! thread.messages.length && isLoadingMessages" class="py-4">
                    <ui-spinner></ui-spinner>
                </div>

                <form v-if="! thread.resolvedAt" @submit.prevent="sendMessage" class="bg-white border border-gray-200 rounded-lg shadow-xs p-4">
                    <template v-if="isResolvingThread">
                        <p class="text-center">Please rate your support experience.</p>
                        <div class="flex gap-x-2 justify-center text-[28px] text-gray-700 mt-2">
                            <a @click.prevent="resolveThread(5)" class="cursor-pointer hover:text-blue-600" href="#" v-tooltip="'Very satisfied'">
                                <ui-icon name="face-laugh-solid"></ui-icon>
                            </a>
                            <a @click.prevent="resolveThread(4)" class="cursor-pointer hover:text-blue-600" href="#" v-tooltip="'Satisfied'">
                                <ui-icon name="face-smile-solid"></ui-icon>
                            </a>
                            <a @click.prevent="resolveThread(3)" class="cursor-pointer hover:text-blue-600" href="#" v-tooltip="'Neutral'">
                                <ui-icon name="face-meh-solid"></ui-icon>
                            </a>
                            <a @click.prevent="resolveThread(2)" class="cursor-pointer hover:text-blue-600" href="#" v-tooltip="'Not satisfied'">
                                <ui-icon name="face-frown-solid"></ui-icon>
                            </a>
                            <a @click.prevent="resolveThread(1)" class="cursor-pointer hover:text-blue-600" href="#" v-tooltip="'Very unhappy'">
                                <ui-icon name="face-dizzy-solid"></ui-icon>
                            </a>
                        </div>
                    </template>
                    <template v-else>
                        <ui-input
                            type="textarea"
                            rows="2"
                            v-model="message"
                            @keydown.enter.exact.prevent="sendMessage"
                            @keydown.enter.shift.exact.prevent="addNewlineToMessage"
                            ref="messageInput"
                            placeholder="Message..."
                        ></ui-input>
                        <div class="flex items-center mt-3 gap-x-3">
                            <a @click.prevent="resetThread()" class="text-blue-600 hover:text-blue-900" href="#">
                                <ui-icon name="arrow-left" class="text-xl"></ui-icon> Back
                            </a>
                            <a @click.prevent="isResolvingThread = true" class="text-blue-600 hover:text-blue-900 ml-auto" href="#" v-tooltip="'Mark as resolved'">
                                <ui-icon name="check" class="text-xl"></ui-icon> Resolve
                            </a>
                            <label class="text-blue-600 hover:text-blue-900 inline-flex items-center gap-x-1" href="#" v-tooltip="'Upload file'">
                                <ui-spinner v-if="isUploadingFile" type="clip" color="rgb(33,114,240)"></ui-spinner>
                                <ui-icon v-else name="paperclip" class="text-xl"></ui-icon>
                                Upload
                                <input type="file" :name="name" hidden :disabled="isUploadingFile" @change="uploadFile($event)">
                            </label>
                            <ui-button type="submit" color="blue" size="sm">Send</ui-button>
                        </div>
                    </template>
                </form>

                <div v-else class="bg-white border border-gray-200 rounded-lg shadow-xs p-4">
                    <div class="flex items-center gap-x-2">
                        <ui-icon name="check" class="text-3xl text-gray-600"></ui-icon>
                        <div class="text-sm">This chat has been marked as resolved at {{$dateTime(thread.resolvedAt)}}.</div>
                    </div>
                    <div class="flex items-center mt-3 gap-x-3">
                        <a @click.prevent="resetThread()" class="text-blue-600 hover:text-blue-900" href="#">
                            <ui-icon name="arrow-left" class="text-xl"></ui-icon> Back
                        </a>
                    </div>
                </div>
            </template>

            <template v-else-if="isCreatingNewThread">
                <div v-for="topic in topics" :key="topic" class="bg-white border border-gray-200 rounded-lg flex shadow-xs h-12 p-1 mt-4">
                    <a @click.prevent="createThread(topic)" href="#" class="text-blue-600 hover:text-blue-900 hover:bg-gray-50 rounded-lg px-4 w-full flex items-center gap-x-1">
                        <div class="flex-1 font-medium text-lg px-3">
                            {{topic}}
                        </div>
                        <ui-icon name="chevron-right" class="text-xl"></ui-icon>
                    </a>
                </div>
            </template>

            <template v-else>
                <div class="overflow-y-auto flex-1 py-4" ref="threads">
                    <div class="flex flex-col justify-end min-h-full">
                        <div class="flex justify-center">
                            <a v-if="! isShowingAllThreads" href="#" @click.prevent="showAllThreads()" class="bg-gray-800 text-gray-100 rounded-full py-1 px-4 font-medium text-xs mb-4">
                                Show older chats
                            </a>
                        </div>

                        <div class="space-y-4">
                            <thread-card v-for="thread in shownThreads" :key="thread.id" :thread="thread"></thread-card>
                        </div>
                    </div>
                </div>

                <div class="bg-white border border-gray-200 rounded-lg flex shadow-xs h-12 p-1">
                    <a @click.prevent="newThread()" href="#" class="text-blue-600 hover:text-blue-900 hover:bg-gray-50 rounded-lg px-4 w-full flex items-center gap-x-1">
                        <ui-icon class="text-3xl" name="message-circle"></ui-icon>
                        <div class="flex-1 font-medium text-lg px-3">
                            New Chat
                        </div>
                        <ui-icon name="chevron-right" class="text-xl"></ui-icon>
                    </a>
                </div>
            </template>
        </div>
    </transition>
</template>

<script>
import ThreadCard from '@/components/support/chat/partials/thread-card'
import MessageCard from '@/components/support/chat/partials/message-card'

import useSupportChatStore from '@/stores/support/chat'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        MessageCard, ThreadCard
    },

    computed: {
        ...mapState(useSupportChatStore, [
            'isCreatingNewThread', 'isLoadingMessages', 'isShown', 'isShowingAllThreads', 'isUploadingFile', 'shownThreads', 'thread', 'topics'
        ]),

        ...mapWritableState(useSupportChatStore, [
            'isResolvingThread', 'message', 'rating'
        ])
    },

    methods: {
        ...mapActions(useSupportChatStore, [
            'createThread', 'newThread', 'resetThread', 'resolveThread', 'sendMessage', 'showAllThreads', 'uploadFile'
        ]),

        scrollToBottom(ref) {
            this.$refs[ref]?.scroll(0, 999999)
        },

        addNewlineToMessage() {
            this.message += '\n'
        }
    },

    watch: {
        thread() {
            this.$nextTick(() => this.scrollToBottom('messages'))
            setTimeout(() => this.scrollToBottom('messages'), 500)
        },

        'thread.messages.length'() {
            this.$nextTick(() => this.scrollToBottom('messages'))
            setTimeout(() => this.scrollToBottom('messages'), 500)
        },

        shownThreads() {
            this.$nextTick(() => this.scrollToBottom('threads'))
            setTimeout(() => this.scrollToBottom('threads'), 500)
        }
    }
}
</script>
