<template>
    <div class="rounded-lg bg-white border border-gray-200 shadow-xs">
        <div class="flex items-center justify-center bg-gray-50 h-52 rounded-t-lg border-b border-gray-100">
            <img :src="`/img/analyses/${type.id}.jpg`" :alt="type.name" class="object-left-top object-cover h-52 rounded-t-lg w-full">
        </div>
        <div class="p-6 pt-4">
            <div class="font-semibold text-xl">{{type.name}}</div>
            <div class="text-gray-700 text-xs font-medium mt-1">{{type.description}}</div>
            <ui-button light color="blue" :to="{ name: 'analysis.analysis.details', params: { type: type.id, id: 'new' } }" class="mt-3">
                Choose
            </ui-button>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'type' ]
}
</script>
