import UIAlert from '@/components/ui/alert'
import UIModal from '@/components/ui/modal'

import useMyStore from '@/stores/me/my'

import { isFuture } from 'date-fns'
import { $vfm } from 'vue-final-modal'

export function modal(options = {}) {
    // Named modal
    if (! (options instanceof Object)) {
        return $vfm.show(options)
    }

    // Dynamic modal
    $vfm.show({
        component: options.modalComponent || UIModal,
        bind: options.modalProps || {},
        on: options.modalListeners || {},
        slots: {
            default: {
                component: options.component,
                bind: options.props || {},
            }
        }
    })
}

export function alert(options = {}) {
    return new Promise((accept, reject) => {
        if (options.muteable && isFuture(useMyStore().preferences.mutedAlerts[options.muteable.id])) {
            return accept()
        }

        return modal({
            component: options.component,
            props: options.props,
            modalComponent: UIAlert,
            modalProps: {
                icon: options.icon || 'alert-triangle',
                title: options.title,
                body: options.body,
                muteable: options.muteable,
                color: options.color
            },
            modalListeners: {
                cancel: props => reject('AlertCancelled'),
                confirm: props => accept(props)
            }
        })
    })
}
