import useTriggerStore from '@/stores/alerts/trigger'

import { defineStore } from 'pinia'

export default defineStore('alertsTriggerPage', {
    getters: {
        title: store => store.trigger ? `${store.trigger.alert.name} | Alerts` : ''
    },

    actions: {
        async afterEnter(to) {
            useTriggerStore().load({ alertId: to.params.alertId, id: to.params.id })
        }
    }
})
