<template>
    <div class="flex items-center justify-center gap-x-1.5 text-3xl font-semibold text-center mb-1">
        {{$titleCase(trigger.alert.settings.frequency)}} digest for
        <span class="flex items-center gap-x-1">
            <ui-avatar :item="trigger.meta.subject" class="w-7 h-7"></ui-avatar> {{trigger.meta.subject.name}}
        </span>
    </div>

    <div class="flex items-center justify-center gap-x-1.5 mb-10 text-gray-700">
        {{date}}
    </div>

    <div class="flex justify-between mb-6">
        <div class="h-8 flex items-center gap-x-3">
            <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                <a @click.prevent="activeTab = 'stream'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'stream' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                    Stream
                </a>
                <a @click.prevent="activeTab = 'inspect'" href="#" class="flex items-center justify-center px-3 --inspect-button" :class="activeTab == 'inspect' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                    Inspect
                </a>
            </div>
            <span>
                <span class="font-medium">{{trigger.meta.content.length}}</span> new items
            </span>
        </div>
        <div class="flex items-center gap-x-3">
            <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[trigger]': `${trigger.id}|content|${trigger.createdAt}|${trigger.alert.name}` } }" target="_blank" class="flex items-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                <ui-icon name="navigation.content"></ui-icon>
                <span>Show all</span>
            </router-link>
        </div>
    </div>

    <ui-async v-show="activeTab == 'stream'" :guards="content.data" class="space-y-6 max-w-2xl mx-auto">
        <content-card v-for="content in content.data" :content="content" :stream-store="streamStore" :key="content.id"></content-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event)"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-120">
                <ui-spinner></ui-spinner>
            </div>
        </template>
    </ui-async>

    <div v-show="activeTab == 'inspect'" class="max-w-6xl mx-auto mt-6 flex flex-col gap-4 --inspect-tab">
        <div class="w-full">
            <overview-timeline :store="widgets.timeline()"></overview-timeline>
        </div>

        <div class="w-full">
            <overview-top-channels :store="widgets.topChannels()"></overview-top-channels>
        </div>

        <div class="flex gap-x-4">
            <div class="w-1/2">
                <overview-platforms-share :store="widgets.platformsShare()"></overview-platforms-share>
            </div>
            <div class="w-1/2">
                <overview-sentiment-share :store="widgets.sentimentShare()"></overview-sentiment-share>
            </div>
        </div>

        <div class="w-full">
            <overview-sentiment-timeline :store="widgets.sentimentTimeline()"></overview-sentiment-timeline>
        </div>

        <div class="w-full">
            <overview-top-hashtags :store="widgets.topHashtags()"></overview-top-hashtags>
        </div>
        <div class="w-full">
            <overview-top-links :store="widgets.topLinks()"></overview-top-links>
        </div>
        <div class="w-full">
            <overview-top-domains :store="widgets.topDomains()"></overview-top-domains>
        </div>
    </div>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'
import OverviewPlatformsShare from '@/components/content/perspective/overview/widgets/platforms-share'
import OverviewSentimentShare from '@/components/content/perspective/overview/widgets/sentiment-share'
import OverviewSentimentTimeline from '@/components/content/perspective/overview/widgets/sentiment-timeline'
import OverviewTimeline from '@/components/content/perspective/overview/widgets/timeline'
import OverviewTopChannels from '@/components/content/perspective/overview/widgets/top-channels'
import OverviewTopDomains from '@/components/content/perspective/overview/widgets/top-domains'
import OverviewTopHashtags from '@/components/content/perspective/overview/widgets/top-hashtags'
import OverviewTopLinks from '@/components/content/perspective/overview/widgets/top-links'

import useTriggerStore from '@/stores/alerts/trigger'
import useTriggerDigestStore from '@/stores/alerts/trigger-digest'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ContentCard, OverviewPlatformsShare, OverviewSentimentShare, OverviewSentimentTimeline, OverviewTimeline,
        OverviewTopChannels, OverviewTopDomains, OverviewTopHashtags, OverviewTopLinks
    },

    data: () => ({
        activeTab: 'stream'
    }),

    computed: {
        ...mapState(useTriggerStore, [ 'streamStore' ]),
        ...mapState(useTriggerDigestStore, [ 'content', 'lastReloadTime', 'trigger', 'widgets' ]),

        date() {
            return this.trigger.alert.settings.frequency == 'daily'
                ? this.$date(this.trigger.meta.dateRange.from)
                : `${this.$date(this.trigger.meta.dateRange.from)} - ${this.$date(this.trigger.meta.dateRange.to)}`
        },

        link() {
            return this.$router.resolve({ name: 'alerts.trigger', params: { alertId: this.trigger.alert.id, id: this.trigger.id } }).href
        }
    },

    methods: {
        ...mapActions(useTriggerDigestStore, [ 'loadMore' ]),

        copyLink() {
            navigator.clipboard.writeText(window.location.origin + this.link)
        }
    }
}
</script>
