<template>
    <div>
        <ui-header title="Analysis">
            <template v-slot:actions>
                <ui-button color="blue" icon="plus-square" :to="{ name: 'analysis.my-analyses.new' }">
                    New Analysis
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search analyses..."
                           class="block w-full h-8 pl-8 pr-2 rounded-xs placeholder-gray-700 focus:outline-hidden focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>
            </template>
        </ui-header>

        <div class="pb-8 px-6 xl:px-16 max-w-12xl mx-auto">
            <div class="h-120 flex flex-col items-center justify-center text-gray-600" v-if="noSearchResults">
                <ui-icon name="wind" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-center text-sm mt-3">There are no analyses here, yet.</div>
            </div>

            <div class="mb-8" v-else>
                <ui-cards-toolbar :store="analysisMyAnalysesCardsStore"></ui-cards-toolbar>

                <ui-cards :store="analysisMyAnalysesCardsStore">
                </ui-cards>
            </div>
        </div>
    </div>
</template>

<script>
import useAnalysisMyAnalysesCardsStore from '@/stores/analysis/my-analyses-cards'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAnalysisMyAnalysesCardsStore),

        noSearchResults() {
            return this.analysisMyAnalysesCardsStore.isInitialized && this.analysisMyAnalysesCardsStore.isEmpty
        }
    }
}
</script>
