<template>
    <div>
        <ui-header :title="$page.perspective ? $page.perspective.name : null" avatar-type="perspective">
            <template v-slot:title>
                <a href="#" class="flex items-center space-x-2" @click.prevent="$page.showFilters = ! $page.showFilters">
                    <ui-icon :name="$page.showFilters ? 'chevron-down' : 'chevron-right'" class="text-3xl"></ui-icon>

                    <ui-avatar :item="$page.perspective" class="w-8 h-8"></ui-avatar>

                    <h1 class="text-3xl font-semibold leading-none">{{$page.perspective.name}}</h1>
                </a>

                <div class="flex items-center space-x-1.5 ml-4">
                    <a href="#" class="inline-flex items-center justify-center transition text-base" :class="{ 'text-blue-600 hover:text-blue-900': $page.perspective.pinned, 'text-gray-400 hover:text-gray-900': ! $page.perspective.pinned }" @click.prevent="updatePerspective($page.perspective, { pinned: $page.perspective.pinned ? 0 : 1 })" v-tooltip="$page.perspective.pinned ? 'Un-pin' : 'Pin'">
                        <ui-icon :name="$page.perspective.pinned ? 'pin-on' : 'pin-off'"></ui-icon>
                    </a>

                    <alerts-menu :subject="$page.perspective" z-index="60"></alerts-menu>
                </div>

                <ui-contextual-menu plain color="black" class="ml-3" z-index="60">
                    <ui-dropdown-link icon="pin-off" @click="updatePerspective($page.perspective, { pinned: 1 })" v-if="! $page.perspective.pinned">
                       Pin
                    </ui-dropdown-link>
                    <ui-dropdown-link icon="pin-off" @click="updatePerspective($page.perspective, { pinned: 0 })" v-if="$page.perspective.pinned">
                       Un-pin
                    </ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link icon="settings" @click="openEditModal($page.perspective, $page.streamStore)">Settings</ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link icon="copy" @click="duplicatePerspective">Duplicate...</ui-dropdown-link>
                </ui-contextual-menu>
            </template>

            <template v-slot:actions>
                <ui-button color="blue" icon="check-circle" :action="savePerspective" v-if="$page.streamStore.hasUnsavedChanges">
                    Save
                </ui-button>

                <ui-help-link page="content" class="text-2xl"></ui-help-link>
            </template>

            <template v-slot:subsection>
                <div class="px-6">
                    <perspective-search :store="$page.streamStore"></perspective-search>
                </div>
            </template>
        </ui-header>

        <perspective-overview v-if="$page.mode == 'overview'"></perspective-overview>
        <perspective-stream v-else></perspective-stream>
    </div>
</template>

<script>
import AlertsMenu from '@/components/me/alerts/alerts-menu'
import PerspectiveOverview from './perspective/overview'
import PerspectiveSearch from './perspective/stream/search'
import PerspectiveStream from './perspective/stream'

import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'
import useContentModalsPerspectiveDuplicateStore from '@/stores/content/modals/perspective-duplicate'
import useContentPerspectiveOverviewStore from '@/stores/content/perspective/overview'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        AlertsMenu, PerspectiveOverview, PerspectiveSearch, PerspectiveStream
    },

    computed: {
        ...mapState(useContentPerspectiveOverviewStore, [ 'showStream' ])
    },

    methods: {
        ...mapActions(useMyPerspectivesStore, {
            'updatePerspective': 'update'
        }),

        ...mapActions(useContentModalsPerspectiveEditStore, { openEditModal: 'open' }),
        ...mapActions(useContentModalsPerspectiveDuplicateStore, { openDuplicateModal: 'open' }),

        async savePerspective() {
            if (this.$page.perspective && this.$page.perspective.id) {
                await this.$page.streamStore.savePerspective()
            } else {
                await this.openEditModal(this.$page.perspective, this.$page.streamStore)
            }
        },

        duplicatePerspective() {
            this.openDuplicateModal(this.$page.perspective)
        }
    }
}
</script>
