import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('mySearchHistory', {
    state: () => ({
        items: [],

        isLoading: false
    }),

    actions: {
        async load(type = 'all') {
            this.isLoading = true

            return this.loadingPromise = api.route('me search-history').query({ type }).get().json(res => {
                this.items = res.data

                this.isLoading = false
            })
        }
    }
})
