import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api, { asyncResource } from '@/api'

import { defineStore } from 'pinia'

export default defineStore('myAlerts', {
    state: () => ({
        resource: asyncResource({
            method: 'get',
            request: (api, store, payload) => api.route('me alerts').query({ filters: JSON.stringify(store.filters), ...payload })
        }),

        loadingPromise: null,

        filters: {},

        types: [
            {
                id: 'every-match',
                name: 'Every Match',
                description: 'Triggers on every new content published in a Perspective, List or Channel.',
                icon: 'subscription',
                settings: {},
                subjectFamilies: [ 'channels', 'lists', 'perspectives' ]
            },
            {
                id: 'digest',
                name: 'Digest',
                description: 'Schedule daily, weekly or monthly overview of a Perspective, List or Channel.',
                icon: 'clipboard',
                settings: { frequency: 'daily', deliveryTime: 9, deliverySecondTime: null, sorting: 'interactions-desc' },
                subjectFamilies: [ 'channels', 'lists', 'perspectives' ]
            },
            {
                id: 'spike-detection',
                name: 'Spike Detection',
                description: 'Triggers on a significant change in content published in a Perspective, List or Channel.',
                icon: 'activity',
                settings: { frequency: 'hourly', metric: 'results' },
                subjectFamilies: [ 'channels', 'lists', 'perspectives' ]
            },
            {
                id: 'new-publishers',
                name: 'New Publishers',
                description: 'Triggers when a new Channel starts publishing content in a Perspective, List or Channel.',
                icon: 'channel',
                settings: { frequency: 'daily', followersThreshold: 50 },
                subjectFamilies: [ 'channels', 'lists', 'perspectives' ]
            },
            {
                id: 'sentiment-shift',
                name: 'Sentiment Shift',
                description: 'Triggers when sentiment of published content changes in a Perspective, List or Channel.',
                icon: 'sentiment',
                settings: { frequency: 'daily', type: 'both' },
                subjectFamilies: [ 'channels', 'lists', 'perspectives' ]
            },
            {
                id: 'audience-change',
                name: 'Audience Change',
                description: 'Triggers when the amount of followers changes in a List or Channel.',
                icon: 'users',
                settings: { frequency: 'hourly' },
                subjectFamilies: [ 'channels', 'lists' ]
            },
            {
                id: 'follow-content',
                name: 'Follow Content',
                description: 'Triggers when the Content is replied to, shared or edited.',
                icon: 'file-text',
                settings: { frequency: 'hourly', triggerOnReplies: true, triggerOnShares: true, triggerOnEdit: true },
                subjectFamilies: [ 'content' ],
                hidden: true
            }
        ],

        hasAlerts: false
    }),

    getters: {
        items: store => store.resource.data || []
    },

    actions: {
        async initialize() {
            await this.load()

            this.hasAlerts = this.items.length > 0 
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.loadingPromise = this.resource.fetch(this).then(() => {
                this.isInitialized = true
                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        find(id) {
            return this.resource.data?.find(i => i.id == id)
        },

        async update(alert, data) {
            await api.route('me alerts update', alert.id)
                .json({ _method: 'put', ...data })
                .post()
                .json(res => Object.assign(alert, res.data))

            return this.reload()
        },

        async delete(alert) {
            return useDeleteConfirmationModal().open('Alert', alert).then(async () => {
                await api.route('me alerts delete', alert.id).delete().res()
                await this.reload()
            })
        },

        filterBy(filters) {
            this.filters = filters
            this.reload()
        },

        async mute(alert, until = null) {
            await api.route('me alerts mute', alert.id).json(until ? { until } : {}).post().res()
            alert.muted = until || true
        },

        async unmute(alert) {
            await api.route('me alerts unmute', alert.id).delete().res()
            alert.muted = false
        },

        async pause(alert) {
            await api.route('me alerts pause', alert.id).post().res()
            alert.paused = true
        },

        async unpause(alert) {
            await api.route('me alerts unpause', alert.id).delete().res()
            alert.paused = false
        },

        async markSeen(trigger) {
            await api.route('me alerts triggers mark-seen', [ trigger.alert.id, trigger.id ]).post()
                .json(res => Object.assign(trigger, res.data))
            trigger.unread = false
            this.reload()
        },

        async markNotSeen(trigger) {
            await api.route('me alerts triggers mark-not-seen', [ trigger.alert.id, trigger.id ]).delete()
                .json(res => Object.assign(trigger, res.data))
            trigger.unread = true
            this.reload()
        }
    }
})
