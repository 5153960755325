import { defineContentTopTopicsAnalysisStore } from '@/stores/analysis/analyses/content-top-topics'

import { defineStore } from 'pinia'

export default settings => defineStore(settings.id, {
    state: () => ({
        type: 'top-level',

        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{
                ...series,
                analysisTopLevelStore: defineContentTopTopicsAnalysisStore({ id: `${settings.id}TopLevelAnalysis` }),
                analysisBreakdownStore: defineContentTopTopicsAnalysisStore({ id: `${settings.id}BreakdownAnalysis` })
            }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = {
                ...series,
                analysisTopLevelStore: defineContentTopTopicsAnalysisStore({ id: `${settings.id}TopLevelAnalysis${this.series.length}` }),
                analysisBreakdownStore: defineContentTopTopicsAnalysisStore({ id: `${settings.id}BreakdownAnalysis${this.series.length}` })
            })

            this.loadAnalysis(series, series.analysisTopLevelStore(), 'top-level')
            this.loadAnalysis(series, series.analysisBreakdownStore(), 'breakdown')
        },

        stopComparing(series) {
            series = this.series.find(s => s.id == series.id)

            series?.analysisTopLevelStore().$destroy()
            series?.analysisBreakdownStore().$destroy()

            this.series = this.series.filter(s => s.id != series.id)
        },

        toggleType() {
            this.type = this.type == 'top-level' ? 'breakdown' : 'top-level'
        },

        async load() {
            return Promise.all(this.series.flatMap(series => [
                this.loadAnalysis(series, series.analysisTopLevelStore(), 'top-level'),
                this.loadAnalysis(series, series.analysisBreakdownStore(), 'breakdown')
            ]))
        },

        loadAnalysis(series, store, type = 'top-level') {
            let filters = series.filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                values: [],
                meta: { type: type, timeline: false }
            }]

            if (filters['date']) {
                store.date = filters['date']
            } else {
                store.date = { type: 'past', date: { past: 1, unit: 'years' } }
            }

            return series.data ? store.setData(series.data) : store.loadSeries()
        },

        analysisStore(series) {
            return this.type == 'breakdown' ? series.analysisBreakdownStore() : series.analysisTopLevelStore()
        }
    }
})
