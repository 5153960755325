<template>
    <div v-if="! content.stackedChild" class="content-card max-w-4xl w-full relative flex flex-col" :class="{ 'min-h-0 max-h-full overflow-y-scroll': mode == 'modal' }" :data-id="content.id">
        <div class="content-card-content flex flex-1 flex-col" :class="{ 'min-h-0': mode == 'modal' }">
            <a v-if="content.stackedParent" @click.prevent="streamStore.expand(content)" href="#" class="flex flex-col items-center">
                <div class="h-2 bg-white border-t border-x border-gray-200" style="width: 90%"></div>
                <div class="h-2 bg-white border-t border-x border-gray-200" style="width: 95%"></div>
            </a>
            <div class="bg-white border border-gray-200 shadow-xs rounded-lg flex flex-1 flex-col">
                <a v-if="content.stackedParent" @click.prevent="streamStore.expand(content)" href="#" class="pt-0.5 text-center text-gray-700">
                    <ui-icon name="chevron-down"></ui-icon>
                </a>

                <!-- Content Source & Header -->
                <div class="flex items-center justify-between" :class="mode == 'details' ? 'p-6' : 'px-5 py-4'">
                    <div class="flex items-center min-w-0 w-full">
                        <div class="shrink-0 relative">
                            <a v-if="content.publishedBy" :href="$router.resolve({ name: 'channels.channel.information', params: { id: content.publishedBy.id } }).href" target="_blank">
                                <ui-avatar :item="content.publishedBy" :class="{ 'h-7 w-7': compact, 'h-9 w-9': mode == 'details', 'h-8 w-8': ! compact && mode != 'details' }"></ui-avatar>
                                <ui-icon :name="content.publishedBy.monitored ? $nodeType(content.publishedBy.type).icon : `${$nodeType(content.publishedBy.type).icon}-inactive`" v-tooltip="$nodeType(content.publishedBy.type).name" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full"></ui-icon>
                            </a>
                            <template v-else>
                                <ui-avatar icon="user" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" :class="{ 'h-7 w-7': compact, 'h-9 w-9': mode == 'details', 'h-8 w-8': ! compact && mode != 'details' }"></ui-avatar>
                                <ui-icon :name="`${$nodeType(content.type).icon}-inactive`" v-tooltip="$nodeType(content.type).name" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full"></ui-icon>
                            </template>
                        </div>
                        <div class="ml-3 min-w-0">
                            <div class="inline-flex items-center w-full">
                                <p class="font-semibold truncate min-w-0 leading-tight">
                                    <a v-if="content.publishedBy" :href="$router.resolve({ name: 'channels.channel.information', params: { id: content.publishedBy.id } }).href" v-tooltip="content.publishedBy.name" target="_blank">
                                        {{ content.publishedBy.name }}
                                    </a>
                                    <span v-else>
                                        Anonymous
                                    </span>
                                </p>
                                <span class="text-gray-800 mx-1 whitespace-nowrap leading-tight" v-if="content.publishedInto.id != content.publishedBy?.id">
                                    in
                                </span>
                                <a :href="$router.resolve({ name: 'channels.channel.information', params: { id: content.publishedInto.id } }).href" target="_blank" class="inline-flex items-center min-w-0 font-semibold leading-tight" v-tooltip="content.publishedInto.name" v-if="content.publishedInto.id != content.publishedBy?.id">
                                    <ui-avatar :item="content.publishedInto" class="w-5 h-5 mr-1"></ui-avatar>
                                    <span class="truncate">
                                        {{ content.publishedInto.name }}
                                    </span>
                                </a>
                            </div>
                            <div class="flex items-center tracking-tight text-gray-800" :class="compact ? 'text-2xs leading-tight' : 'text-xs leading-tight'">
                                <div class="group flex items-center" v-tooltip="dateTooltip">
                                    <a @click.prevent="showDetails" :href="$router.resolve({ name: 'content.details', params: { id: content.id } }).href" target="_blank">
                                        {{ $dateTime(content.publishedAt) }}
                                    </a>
                                </div>
                                <a href="#" @click.prevent="showContentEdits(content.id)" class="block cursor-pointer ml-2 px-2 py-0.5 leading-none rounded-full bg-blue-50 text-blue-600 font-medium text-2xs tracking-tight cursor-default" v-if="content.editedAt" v-tooltip="editedTooltip">
                                    Edited
                                </a>
                                <div class="ml-2 px-2 py-0.5 leading-none rounded-full bg-red-100 text-red-500 font-medium text-2xs tracking-tight cursor-default" v-if="content.deletedAt" v-tooltip="deletedTooltip">
                                    Deleted
                                </div>
                                <div class="ml-2 px-2 py-0.5 leading-none rounded-full bg-gray-100 text-gray-500 font-medium text-2xs tracking-tight cursor-default" v-if="isDeglyphed">
                                    Deglyphed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex shrink-0 items-center text-gray-500 space-x-1">
                        <contextual-menu :content="content" :stream-store="streamStore" plain></contextual-menu>
                    </div>
                </div>

                <!-- Content -->
                <div class="space-y-2" :class="{ 'text-sm': compact, 'mx-6 mb-5': mode == 'details', 'mx-5 mb-4': mode != 'details' }" v-if="content.conversationParent || content.imagesMatch || content.videosMatch || content.title || text">
                    <!-- Conversation root -->
                    <card-root v-if="content.conversationParent" :parent="content" :root="content.conversationParent" :stream-store="streamStore"></card-root>

                    <div class="flex space-x-3" v-if="content.imagesMatch || content.videosMatch">
                        <div class="bg-yellow-100 text-yellow-900 px-1.5 text-sm rounded-full border border-yellow-200" v-if="content.imagesMatch">
                            Matching image
                        </div>
                        <div class="bg-yellow-100 text-yellow-900 px-1.5 text-sm rounded-full border border-yellow-200" v-if="content.videosMatch">
                            Matching video
                        </div>
                    </div>

                    <div :class="{ 'border border-gray-300 bg-gray-50/20 rounded-xl shadow-sm py-2 px-4': content.unmodified?.title || content.unmodified?.body, 'border border-blue-100 bg-gray-50/20 rounded-xl shadow-sm py-2 px-4': content.translation?.text }">
                        <div v-if="content.translation?.text" class="flex items-center justify-between text-gray-700 mb-2">
                            <div class="text-sm font-semibold">
                                <ui-icon name="translate"></ui-icon>
                                Translated text
                            </div>

                            <a @click.prevent="removeTranslation()" href="#">
                                <ui-icon name="x"></ui-icon>
                            </a>
                        </div>
                        <div v-else-if="content.unmodified?.title || content.unmodified?.body" class="flex items-center justify-between text-gray-700 mb-2">
                            <div class="text-sm font-semibold">
                                <ui-icon name="text-case"></ui-icon>
                                Unmodified text
                            </div>

                            <a @click.prevent="removeUnmodified()" href="#">
                                <ui-icon name="x"></ui-icon>
                            </a>
                        </div>

                        <h1 class="font-medium" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-if="content.unmodified?.title || content.title" v-html="content.unmodified?.title ?? content.title"></h1>

                        <div>
                            <div class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></div>
                            <div v-if="content.translation?.incomplete" class="text-xs text-gray-800 italic my-1">
                                Text is too long, translating first {{content.translation?.incomplete}} characters.
                            </div>
                            <span v-if="! isShowingFullText">
                                <a class="cursor-pointer text-blue-500" @click.prevent="readMore">Read more</a>
                            </span>
                        </div>
                    </div>

                    <slot name="no-content"></slot>
                </div>

                <!-- Media -->
                <div class="w-full flex flex-wrap" :class="{ 'mb-4': ! content.parent && mode == 'details' }" v-if="isShowingMedia">
                    <div class="w-full max-h-96 bg-white relative cursor-pointer" @click="showMedia(0)">
                        <img v-if="firstMedia.mediaType == 'video' && firstMedia.thumbnailUrl || firstMedia.mediaType == 'image' && firstMedia.url" class="w-full h-full object-contain" :src="firstMedia.thumbnailUrl || firstMedia.url">
                        <div v-else class="w-full h-64 bg-gray-200"></div>

                        <div class="absolute inset-0 flex items-center justify-center">
                            <div v-if="firstMedia.mediaType == 'video' && (firstMedia.url || firstMedia.thumbnailUrl)" class="w-16 h-16 flex items-center justify-center bg-gray-900/50 rounded-full">
                                <ui-icon name="play" class="text-3xl text-white"></ui-icon>
                            </div>

                            <div v-if="! firstMedia.url && ! firstMedia.thumbnailUrl" class="flex flex-col items-center text-gray-700 font-medium gap-y-1">
                                <ui-icon :name="firstMedia.mediaType == 'image' ? 'image' : 'video'" class="text-5xl text-gray-600"></ui-icon>
                                <div v-if="firstMedia.mediaType == 'image'">
                                    Image is processing
                                </div>
                                <div v-if="firstMedia.mediaType == 'video'">
                                    {{ firstMedia.originalUrl ? 'Third-party video' : 'Video is not available' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/4 p-px" :key="key" v-for="media, key in additionalMedia">
                        <div class="h-0 pb-full relative cursor-pointer" @click="showMedia(key + 1)">
                            <img v-if="media.mediaType == 'video' && media.thumbnailUrl || media.mediaType == 'image' && media.url" class="w-full h-full absolute object-cover" :src="media.thumbnailUrl || media.url">
                            <div v-else class="w-full h-full absolute bg-gray-200"></div>

                            <div v-if="hiddenMediaCount && key == additionalMedia.length - 1" class="absolute inset-0 flex items-center justify-center text-white bg-gray-800/75 font-medium">
                                <span class="text-[28px]">+{{ hiddenMediaCount }}</span>
                            </div>

                            <div v-else class="absolute inset-0 flex items-center justify-center">
                                <div v-if="media.mediaType == 'video' && (media.url || media.thumbnailUrl)" class="w-16 h-16 flex items-center justify-center bg-gray-900/50 rounded-full">
                                    <ui-icon name="play" class="text-3xl text-white"></ui-icon>
                                </div>

                                <ui-icon v-else-if="! media.url && ! media.thumbnailUrl" :name="media.mediaType == 'image' ? 'image' : 'video'" class="text-5xl text-gray-600"></ui-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Shared post -->
                <div class="mb-4 mx-3 last:rounded-b-lg" v-if="content.sharedContent">
                    <content-card :content="content.sharedContent" :stream-store="streamStore" :compact="true"></content-card>
                </div>

                <div class="mt-auto divide-y divide-gray-100 bg-gray-50 border-t border-gray-100 rounded-b-lg" :class="compact ? 'text-sm' : ''" v-if="content.features?.length || content.metrics?.interactions || content.metrics?.impact || content.metrics?.views || sentiment || content.tags?.length || content.notes?.length || isSponsored || (content.conversationChildren && content.conversationChildren.length && mode != 'modal')">
                    <!-- Links -->
                    <card-links :features="content.features" :stream-store="streamStore"></card-links>

                    <!-- Mentions -->
                    <card-mentions :features="content.features" :stream-store="streamStore"></card-mentions>

                    <!-- Metrics -->
                    <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                        <div class="flex items-center" v-if="content.metrics.interactions">
                            <ui-dropdown width="w-52">
                                <template v-slot:trigger>
                                    <div class="flex justify-center items-center cursor-pointer">
                                        <ui-icon name="interactions-solid" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                        <div class="leading-none ml-2">
                                            <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions

                                            <!--<span class="text-xs bg-green-50 border border-green-200 text-green-600 pl-1 pr-2 py-0.5 font-medium tracking-tight rounded-full ml-1" v-tooltip="{ content: `This post has <b>${content.metrics.benchmark} times <br>reactions</b> of an average post`, html: true }" v-if="content.labels.includes('overperforming')">-->
                                            <!--    <ui-icon name="arrow-up"></ui-icon>-->
                                            <!--    {{content.metrics.benchmark}}x-->
                                            <!--</span>-->
                                            <!--<span class="text-xs bg-red-50 border border-red-200 text-red-600 px-1 py-0.5 font-medium tracking-tight rounded-full ml-1" v-tooltip="{ content: `This post has <b>${content.metrics.benchmark} times <br>reactions</b> of an average post`, html: true }" v-else-if="content.labels.includes('underperforming')">-->
                                            <!--    <ui-icon name="arrow-down"></ui-icon>-->
                                            <!--    {{content.metrics.benchmark}}x-->
                                            <!--</span>-->
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <div class="py-2">
                                        <div class="flex items-center px-4">
                                            <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                                <ui-icon name="interactions-solid" class="text-lg"></ui-icon>
                                                <h2>Interactions</h2>
                                            </div>
                                            <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
                                        </div>
                                        <div class="text-gray-700 text-sm leading-tight px-4 mt-2">
                                            Sum of reactions, comments and shares as reported by the native platform.
                                        </div>
                                    </div>

                                  <div class="py-2 border-t botder-gray-200">
                                        <div class="flex items-center px-4 py-1">
                                            <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                                <ui-icon name="thumbs-up" class="text-lg"></ui-icon>
                                                <h2>Reactions</h2>
                                            </div>
                                            <div class="flex items-center gap-x-2">
                                                <h1 class="font-semibold">
                                                    {{$number(content.metrics.reactions)}}
                                                </h1>
                                            </div>
                                        </div>
                                        <div class="flex items-center px-4 py-1">
                                            <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                                <ui-icon name="comment" class="text-lg"></ui-icon>
                                                <h2>Comments</h2>
                                            </div>
                                            <div class="flex items-center gap-x-2">
                                                <h1 class="font-semibold">
                                                    {{$number(content.metrics.comments)}}
                                                </h1>
                                            </div>
                                        </div>
                                        <div class="flex items-center px-4 py-1">
                                            <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                                <ui-icon name="share" class="text-lg"></ui-icon>
                                                <h2>Shares</h2>
                                            </div>
                                            <div class="flex items-center gap-x-2">
                                                <h1 class="font-semibold">
                                                    {{$number(content.metrics.shares)}}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="platform in platformMetrics" :key="platform.id" class="px-4 py-2 border-t botder-gray-200">
                                        <div class="flex items-center justify-center gap-x-1 font-medium text-gray-700 text-xs">
                                            <ui-icon :name="platform.icon"></ui-icon>
                                            {{platform.name}}
                                        </div>
                                        <div class="columns-2">
                                            <div v-for="metric in platform.metrics" :key="metric.id" class="flex items-center py-1">
                                                <div class="flex-1 flex items-center gap-x-1.5 text-gray-700 text-xs">
                                                    <ui-icon :name="metric.icon" :as-image="platform.id == 'facebook'" class="text-base"></ui-icon>
                                                    <h2>{{metric.name}}</h2>
                                                </div>
                                                <div class="flex items-center gap-x-2">
                                                    <h1 class="text-xs font-semibold">
                                                        {{$number(metric.value)}}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </ui-dropdown>
                        </div>

                        <ui-dropdown v-if="content.metrics.views" width="w-52">
                            <template v-slot:trigger>
                                <div class="flex justify-center items-center cursor-pointer">
                                    <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                                    </div>
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="py-2">
                                    <div class="flex items-center px-4">
                                        <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                            <ui-icon name="views" class="text-lg"></ui-icon>
                                            <h2>Views</h2>
                                        </div>
                                        <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
                                    </div>
                                    <div class="text-gray-700 text-sm leading-tight px-4 mt-2">
                                        How many times this content has been seen as reported by the native platform.
                                    </div>
                                </div>
                            </template>
                        </ui-dropdown>
                        <ui-dropdown v-else-if="content.metrics.impact" width="w-52">
                            <template v-slot:trigger>
                                <div class="flex justify-center items-center cursor-pointer">
                                    <ui-icon name="zap" filled class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.impact) }}</span> impact
                                    </div>

                                    <ui-icon name="flask" class="text-lg text-gerulata-blue-500 ml-1" v-tooltip="'Experimental Feature'"></ui-icon>
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="py-2">
                                    <div class="flex items-center px-4">
                                        <div class="flex-1 flex items-center gap-x-1.5 text-gray-700">
                                            <ui-icon name="zap" filled class="text-lg"></ui-icon>
                                            <h2>Impact</h2>
                                        </div>
                                        <ui-data-quality-icon quality="processed"></ui-data-quality-icon>
                                    </div>
                                    <div class="text-gray-700 text-sm leading-tight px-4 mt-2">
                                        Estimated number of how many times this content has been seen.
                                    </div>
                                    <div class="border-t border-gray-200 px-4 pt-2 mt-2">
                                        <div class="flex-1 flex items-center gap-x-1.5 text-gerulata-blue-500">
                                            <ui-icon name="flask" class="text-lg"></ui-icon>
                                            <h2>Experimental Feature</h2>
                                        </div>
                                    </div>
                                    <div class="text-gray-700 text-sm leading-tight px-4 mt-2">
                                        We are still tweaking the values to be more accurate.
                                    </div>
                                </div>
                            </template>
                        </ui-dropdown>
                    </div>

                    <!-- Tags & Labels -->
                    <div v-if="sentiment || (content.tags.length > 0) || isSponsored" class="py-1 flex flex-wrap gap-x-1 gap-y-1" :class="mode == 'details' ? 'px-4' : 'px-3'">

                        <!-- Sponsored -->
                        <div class="flex items-center text-lg font-medium cursor-default py-1 px-2 rounded-sm" v-tooltip="'This content is a paid advertisement.'" v-if="isSponsored">
                            <ui-icon name="megaphone"></ui-icon>
                            <div class="ml-1 text-xs">Sponsored</div>
                        </div>

                        <!-- Sentiment Label -->
                        <ui-dropdown position="top">
                            <template v-slot:trigger>
                                <div class="flex items-center text-lg font-medium cursor-default hover:bg-gray-100 py-1 px-2 rounded-sm" v-tooltip="'Content sentiment'" v-if="sentiment">
                                    <ui-icon :name="sentiment.icon" :class="sentiment.color"></ui-icon>
                                    <div class="ml-1 text-xs" :class="sentiment.color">{{ sentiment.title }}</div>
                                </div>
                            </template>
                            <template v-slot:content>
                                <ui-dropdown-link icon="flag" @click="editSentiment(content)">
                                    Report wrong value
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown>

                        <!-- IPTC Topics -->
                        <ui-dropdown v-for="topic in iptcTopics" :key="topic.id" position="top">
                            <template v-slot:trigger>
                                <div class="flex items-center text-lg font-medium cursor-default hover:bg-gray-100 text-gray-700 py-1 px-2 rounded-sm group" v-tooltip="$arrayJoin(topic.children.map(t => t.name))">
                                    <ui-icon :name="topic.icon" class="text-gray-500 group-hover:text-gray-800"></ui-icon>
                                    <div class="ml-1 text-xs">{{ topic.name }}</div>
                                </div>
                            </template>
                            <template v-slot:content>
                                <ui-dropdown-link :icon="topic.icon" submenu>
                                    {{topic.name}}...
                                    <template v-slot:submenu>
                                        <ui-dropdown-link @click="showOnlyTopic(topic)">Show only this topic</ui-dropdown-link>
                                        <ui-dropdown-link @click="dontShowTopic(topic)">Don't show this topic</ui-dropdown-link>
                                    </template>
                                </ui-dropdown-link>
                                <ui-dropdown-separator></ui-dropdown-separator>
                                <ui-dropdown-link v-for="topic in topic.children" :key="topic.id" :icon="topic.icon" submenu>
                                    {{topic.name}}...
                                    <template v-slot:submenu>
                                        <ui-dropdown-link @click="showOnlyTopic(topic)">Show only this topic</ui-dropdown-link>
                                        <ui-dropdown-link @click="dontShowTopic(topic)">Don't show this topic</ui-dropdown-link>
                                    </template>
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown>

                        <!-- User Tags -->
                        <ui-dropdown position="top" v-for="tag in content.tags" :key="tag.id">
                            <template v-slot:trigger>
                                <a class="flex items-center text-lg font-medium text-gray-800 cursor-default hover:bg-gray-100 py-1 px-2 rounded-sm" href="#">
                                    <ui-icon name="user-tag" :class="`text-${tag.color}`"></ui-icon>
                                    <div class="ml-1 text-xs">{{ tag.name }}</div>
                                </a>
                            </template>

                            <template v-slot:content>
                                <ui-dropdown-link icon="clear" @click="toggleTagOn(content, tag)">
                                    Remove tag
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown>

                        <content-add-tag :content="content"></content-add-tag>
                    </div>

                    <!-- Notes -->
                    <card-notes :item="content" v-if="mode != 'details'"></card-notes>

                    <!-- Replies -->
                    <card-replies v-if="content.conversationChildren && content.conversationChildren.length && mode != 'modal' && mode != 'details'" :parent="content" :replies="content.conversationChildren" :stream-store="streamStore"></card-replies>

                    <slot name="details"></slot>

                    <card-details v-if="mode == 'details'"></card-details>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardDetails from './partials/details'
import CardLinks from './partials/links'
import CardMentions from './partials/mentions'
import CardNotes from '@/components/me/notes/card-notes'
import CardReplies from './partials/replies'
import CardRoot from './partials/root'
import ContentAddTag from './partials/add-tag'
import ContextualMenu from '@/components/content/contextual-menus/content'

import useContentModalsSentimentEditStore from '@/stores/content/modals/sentiment-edit'
import useContentEditsStore from '@/stores/content/modals/content-edits'
import useDetailsStore from '@/stores/content/details'
import useTaggingStore from '@/stores/tagging/tagging'

import { removeUnmodified } from '@/helpers/deglyphing'
import { iptcTopic, topics as iptcTopics } from '@/helpers/iptc'
import language from '@/helpers/language'
import { removeTranslation } from '@/helpers/translations'

import { mapActions } from 'pinia'

// Make sure keywords highlighting classes are generated: bg-yellow-100 bg-orange-100 bg-red-100
export default {
    components: {
        CardDetails, CardLinks, CardMentions, CardNotes, CardReplies, CardRoot, ContentAddTag, ContextualMenu
    },

    name: 'content-card',

    props: [
        'content', 'streamStore',
        'parentComponent',
        'compact', 'mode', 'isExpanded',
        'hideMediaSection'
    ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isShowingMedia() {
            return this.media?.length && ! this.hideMediaSection
        },

        isShowingFullText() {
            return this.showFullText || (this.content.body || '').length <= (this.mode == 'details' ? 8000 : 500)
        },

        isShowingTags() {
            return this.content.tags && this.content.tags.length
        },

        isRTL() {
            return this.content.translation && ! this.content.translation.isLoading
                ? language(this.content.translation.language).rtl : language(this.content.language[0]).rtl
        },

        sentiment() {
            if (this.content.labels.includes('sentiment_polarity:positive')) {
                return {title: 'Positive', color: 'text-emerald-500', icon: 'face-smile'}
            } else if (this.content.labels.includes('sentiment_polarity:negative')) {
                return {title: 'Negative', color: 'text-rose-500', icon: 'face-frown'}
            } else if (this.content.labels.includes('sentiment_polarity:neutral')) {
                return {title: 'Neutral', color: 'text-indigo-500', icon: 'face-neutral'}
            } else {
                return null
            }
        },

        dateTooltip() {
            let content = `Published at <b>${this.$dateTime(this.content.publishedAt)}</b>`
            if (this.content.firstSeenAt) { content += `<br>Detected at <b>${this.$dateTime(this.content.firstSeenAt)}</b><br>(${this.content.firstSeenDifference})` }

            return { content, html: true }
        },

        editedTooltip() {
            return { content: `Edited at <b>${this.$dateTime(this.content.editedAt)}</b>. Show more details...`, html: true }
        },

        deletedTooltip() {
            return { content: `Deleted at <b>${this.$dateTime(this.content.deletedAt)}</b>`, html: true }
        },

        text() {
            let body = this.content.translation?.text ?? this.content.unmodified?.body ?? this.content.body

            if (this.isShowingFullText) return body

            return this.mode == 'details'
                ? body.substring(0, 8000) + '...'
                : body.replace(/\n\s*\n/g, "\n").substring(0, 500) + '...'
        },

        firstMedia() {
            return this.media?.[0] || {}
        },

        additionalMedia() {
            return this.media?.slice(1, 5) || {}
        },

        hiddenMediaCount() {
            return this.media?.length > 5 ? this.media.length - 5 : 0
        },

        media() {
            let thumbnails = this.content.images.filter(i => i.parent)
            let images = this.content.images.filter(i => ! i.parent)

            let videos = this.content.videos.map(v => ({ ...v, thumbnailUrl: thumbnails.find(t => t.parent == v.id)?.url }))

            return [ ...images, ...videos ].sort((a, b) => a.order - b.order)
        },

        links() {
            return this.content.features.filter(f => f.type == 'url')
        },

        isShowingMetrics() {
            return this.content.metrics.interactions || this.content.metrics.impact || this.content.metrics.views
        },

        platformMetrics() {
            return [
                {
                    id: 'facebook', name: 'Facebook', icon: 'badges.channel-facebook-page',
                    metrics: [
                        { id: 'like', name: 'Like', icon: 'reactions.like' },
                        { id: 'love', name: 'Love', icon: 'reactions.love' },
                        { id: 'care', name: 'Care', icon: 'reactions.care' },
                        { id: 'haha', name: 'Haha', icon: 'reactions.haha' },
                        { id: 'wow', name: 'Wow', icon: 'reactions.wow' },
                        { id: 'sad', name: 'Sad', icon: 'reactions.sad' },
                        { id: 'angry', name: 'Angry', icon: 'reactions.angry' }
                    ],
                    types: [ 'facebook-post' ]
                },
                {
                    id: 'x', name: 'X', icon: 'badges.channel-x-user',
                    metrics: [
                        { id: 'reposts', name: 'Reposts', icon: 'repeat' },
                        { id: 'quotes', name: 'Quotes', icon: 'corner-down-right' }
                    ],
                    types: [ 'x-post' ]
                },
                {
                    id: 'youtube', name: 'YouTube', icon: 'badges.channel-youtube-channel',
                    metrics: [
                        { id: 'likes', name: 'Likes', icon: 'thumbs-up' },
                        { id: 'dislikes', name: 'Dislikes', icon: 'thumbs-down' }
                    ],
                    types: [ 'youtube-video' ]
                }
            ]
            .filter(platform => platform.types.includes(this.content.type))
            .map(platform => ({
                ...platform, metrics: platform.metrics.map(metric => ({ ...metric, value: this.content.metrics[platform.id][metric.id] }))
            }))
        },

        isSponsored() {
            return [ 'facebook-ad' ].includes(this.content.type)
        },

        iptcTopics() {
            return this.content.labels
                .filter(label => typeof label == 'string') // FIXME Temporary workaround for broken data.
                .filter(label => label.startsWith('iptc_mediatopic:') && ! label.includes('.'))
                .map(label => iptcTopic(label.replace(/^iptc_mediatopic:/, '')))
                .map(topic => ({
                    ...topic,
                    children: topic.children.filter(t => this.content.labels.includes(`iptc_mediatopic:${t.id}`))
                }))
        },

        isDeglyphed() {
            return this.content.labels.includes('deglyphed')
        }
    },

    methods: {
        ...mapActions(useContentModalsSentimentEditStore, { editSentiment: 'open' }),
        ...mapActions(useContentEditsStore, { showContentEdits: 'show' }),
        ...mapActions(useTaggingStore, [ 'toggleTagOn' ]),

        readMore() {
            if (this.mode == 'details') {
                this.showFullText = true
            } else {
                useDetailsStore().open(this.content, this.streamStore)
            }
        },

        showDetails() {
            if (this.mode == 'details') {
                this.$router.push({ name: 'content.details', params: { id: this.content.id } })
            } else {
                useDetailsStore().open(this.content, this.streamStore)
            }
        },

        showMedia(currentIndex) {
            this.streamStore?.mediaLightbox.open(this.media, currentIndex, this.content)
        },

        showOnlyTopic(topic) {
            this.streamStore.filters.set('iptcTopics', [ topic.id ])
        },

        dontShowTopic(topic) {
            topic = iptcTopic(topic.id)
            let topics = [ ...(this.streamStore.filters.value('iptcTopics') || []) ]

            if (! topics.length) {
                topics = iptcTopics.map(topic => topic.id)
            }

            if (topics.includes(topic.id)) {
                topics = topics.filter(topicId => topicId != topic.id)
            } else if (topics.includes(topic.parent?.id)) {
                topics = topics.filter(topicId => topicId != topic.parent.id)
                topics.push(...topic.parent.children.map(topic => topic.id))
                topics = topics.filter(topicId => topicId != topic.id)
            }

            this.streamStore.filters.set('iptcTopics', topics)
        },

        async removeTranslation() {
            removeTranslation(this.content)
            this.$nextTick(() => this.streamStore.triggerLayoutUpdate())
        },

        async removeUnmodified() {
            removeUnmodified(this.content)
            this.$nextTick(() => this.streamStore.triggerLayoutUpdate())
        }
    },

    watch: {
        'content.notes': {
            handler() { this.streamStore?.triggerLayoutUpdate([ this.content ]) },
            deep: true
        },
        'content.tags'() { this.streamStore?.triggerLayoutUpdate([ this.content ]) },
        'content.isTranslating'() { this.streamStore?.triggerLayoutUpdate([ this.content ]) }
    }
}
</script>
