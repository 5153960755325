import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export default defineStore('analysisMyAnalysesPage', {
    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'My Analyses | Analysis'
    },

    actions: {
        afterEnter() {
            this.$reset()

            useMyAnalysesStore().initialize()
        }
    }
})
