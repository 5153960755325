<template>
    <div class="h-full" v-if="editor">
        <div class="h-9 flex items-center divide-x divide-gray-700 bg-gray-800 text-gray-400 rounded-md shadow-xs mb-6">
            <div v-if="! features.length || ['h1', 'h2', 'h3'].some(feature => features.includes(feature))" class="flex items-center px-1">
                <div v-if="! features.length || features.includes('h1')" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 1 }) }" v-tooltip="'Heading 1'">
                    H1
                </div>

                <div v-if="! features.length || features.includes('h2')" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 2 }) }" v-tooltip="'Heading 2'">
                    H2
                </div>

                <div v-if="! features.length || features.includes('h3')" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 3 }) }" v-tooltip="'Heading 3'">
                    H3
                </div>
            </div>

            <div v-if="! features.length || ['bold', 'italic', 'underline'].some(feature => features.includes(feature))" class="flex items-center px-1">
                <div v-if="! features.length || features.includes('bold')" @click="editor.chain().focus().toggleBold().run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('bold') }" v-tooltip="'Bold'">
                    <ui-icon name="bold"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('italic')" @click="editor.chain().focus().toggleItalic().run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('italic') }" v-tooltip="'Italic'">
                    <ui-icon name="italic"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('underline')" @click="editor.chain().focus().toggleUnderline().run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('underline') }" v-tooltip="'Underline'">
                    <ui-icon name="underline"></ui-icon>
                </div>
            </div>

            <div v-if="! features.length || ['align-left', 'align-center', 'align-right', 'align-justify'].some(feature => features.includes(feature))" class="flex items-center px-1">
                <div v-if="! features.length || features.includes('align-left')" @click="editor.chain().focus().setTextAlign('left').run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'left' }) }" v-tooltip="'Align Left'">
                    <ui-icon name="align-left"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('align-center')" @click="editor.chain().focus().setTextAlign('center').run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'center' }) }" v-tooltip="'Align Center'">
                    <ui-icon name="align-center"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('align-right')" @click="editor.chain().focus().setTextAlign('right').run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'right' }) }" v-tooltip="'Align Right'">
                    <ui-icon name="align-right"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('align-justify')" @click="editor.chain().focus().setTextAlign('justify').run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'justify' }) }" v-tooltip="'Align Justify'">
                    <ui-icon name="align-justify"></ui-icon>
                </div>
            </div>

            <div v-if="! features.length || ['list-unordered', 'list-ordered'].some(feature => features.includes(feature))" class="flex items-center px-1">
                <div v-if="! features.length || features.includes('list-unordered')" @click="editor.chain().focus().toggleBulletList().run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('bulletList') }" v-tooltip="'Bullet List'">
                    <ui-icon name="list-unordered"></ui-icon>
                </div>

                <div v-if="! features.length || features.includes('list-ordered')" @click="editor.chain().focus().toggleOrderedList().run()" class="inline-flex items-center justify-center w-8 h-7 text-sm font-medium hover:bg-gray-900 rounded-md max-w-full cursor-default" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('orderedList') }" v-tooltip="'Ordered List'">
                    <ui-icon name="list-ordered"></ui-icon>
                </div>
            </div>
        </div>

        <editor-content class="h-full overflow-y-scroll" :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import safeColors from "@/helpers/safe-colors"
import { Markdown } from 'tiptap-markdown'

export default {
    mixins: [safeColors],

    components: {
        EditorContent
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
        features: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            editor: null,
        }
    },

    watch: {
        modelValue(modelValue) {
            const isSame = this.editor.storage.markdown.getMarkdown() === modelValue

            if (isSame) {
                return
            }

            this.editor.commands.setContent(modelValue, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                Markdown.configure({
                    html: false,
                    linkify: true
                }),
                Underline,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                ListItem,
                BulletList,
                OrderedList
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.storage.markdown.getMarkdown())
            },
        })

        this.editor.commands.setContent(this.modelValue, false)
    },

    beforeUnmount() {
        this.editor.destroy()
    }
}
</script>

<style>
.ProseMirror {
    outline: none;
    min-height: 100vh;
    overflow-y: scroll;
    white-space: break-spaces;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p { padding-bottom: 0.33rem }

.ProseMirror h1 { font-size: 24px; line-height: 30px; padding-bottom: 0.4rem }
.ProseMirror h2 { font-size: 20px; line-height: 26px; padding-bottom: 0.4rem }
.ProseMirror h3 { font-size: 16px; line-height: 22px; padding-bottom: 0.4rem }

.ProseMirror ul { list-style-type: disc; padding: 0 1rem }
.ProseMirror ol { list-style-type: decimal; padding: 0 1rem }

.ProseMirror li {
    padding-bottom: 2px;
}
</style>
