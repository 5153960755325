<template>
    <div class="relative flex-1" @keydown.esc="$page.isSearchFocused = false">
        <div class="absolute top-0 left-4 h-10 flex items-center pointer-events-none z-40">
            <ui-icon name="search" class="text-xl" :class="{ 'text-gray-700': ! $page.isSearchFocused, 'text-blue-600': $page.isSearchFocused }"></ui-icon>

            <div class="text-gray-700 ml-2" v-if="! $page.isSearchFocused && ! store.search.query">
                Enter a search query...
            </div>
        </div>

        <search-input
            ref="searchInput"
            v-model="store.search.query"
            :language="store.search.language"
            @focus="() => $page.isSearchFocused = true"
            @transaction="transaction"
            @submit="submit"
            :allow-multi-line="true"
            :allow-highlighting="true"
            :allow-suggesting-topics="true"
            :allow-creating-topics="true"
            :allow-editing-topics="true"
            :allow-unwrapping-topics="true"
            :is-focused="$page.isSearchFocused"
        ></search-input>

        <audio v-if="audio" ref="audio" :src="audio"></audio>

        <div class="absolute top-0 right-4 h-10 flex items-center space-x-2 z-40">
            <a href="#" class="flex text-2xl text-blue-600 hover:text-blue-900" @click.prevent="store.clearSearch()" v-tooltip="'Clear'" v-if="$page.isSearchFocused && store.search.query">
                <ui-icon name="x"></ui-icon>
            </a>

            <search-language :store="store" v-if="$page.isSearchFocused"></search-language>
            <search-settings :store="store" v-if="$page.isSearchFocused"></search-settings>

            <search-help></search-help>
        </div>
    </div>
</template>

<script>
import SearchHelp from './search-help'
import SearchInput from './search-input-input'
import SearchLanguage from './search-language'
import SearchSettings from './search-settings'

import useSuggestionsStore from '@/stores/reusable/suggestions'
import useSupportHelpStore from '@/stores/support/user-guide.js'

import { mapActions } from 'pinia'

export default {
    data: () => ({
        audio: null
    }),

    components: {
        SearchHelp, SearchInput, SearchLanguage, SearchSettings
    },

    methods: {
        ...mapActions(useSuggestionsStore, [ 'suggest' ]),
        ...mapActions(useSupportHelpStore, { showHelp: 'show' }),

        transaction({ editor, transaction }) {
            if (transaction.docChanged) {
                this.$nextTick(() => {
                    let text = editor.getText('\n').replaceAll(/\/topic\/([a-z0-9-]+)/ig, ' ')

                    let left = text.substring(0, transaction.selection.$anchor.pos).match(/[\p{L}\d]+$/u)?.[0] || ''
                    let right = text.substring(transaction.selection.$anchor.pos).match(/^[\p{L}\d]+/u)?.[0] || ''

                    if ((left.length + right.length) < 3) return

                    this.suggestForWord(left + right)
                })
            }
        },

        suggestForWord(word) {
            return this.suggest(word, this.store.search.language)
        },

        submit() {
            this.store.applySearchQuery()

            this.$page.isSearchFocused = false
            this.$refs.searchInput.editor.commands.blur()
        }
    },

    watch: {
        'store.search.query'(newVal) {
            if (newVal === 'amoge') { this.audio = '/audio/amoge.mp3'; this.$nextTick(() => this.$refs.audio.play()) }
        }
    }
}
</script>
