<template>
    <div class="flex items-start z-20">
        <div class="bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md w-64 inline-block relative mr-2 shadow-xs" v-if="showPanel">
            <div class="px-3 pt-2">
                <ui-icon :name="'chevron-right'" class="ml-auto float-right text-3xl cursor-pointer" @click="hidePanel"></ui-icon>
                <h1 class="text-xl">{{ sections[activeSection] }}</h1>
            </div>

            <graph-stats :store="store" v-if="isActive('info')" />
            <graph-layout v-else-if="isActive('layout')" />
            <graph-metrics v-else-if="isActive('metrics')" />
            <graph-style v-else-if="isActive('style')" />
            <graph-filter :store="store" v-else-if="isActive('filter')" />
        </div>

        <div class="inline-block">
            <div class="flex border border-gray-200 rounded-md cursor-pointer mb-1 content-center bg-white/80 backdrop-blur-sm h-12 p-1 shadow-xs" v-tooltip.left="'Add Channels / Features'" @click="$page.addChannel" v-if="store.nodes.length < store.maxNodesLimit">
                <ui-icon name="circle-plus" class="text-3xl mx-auto my-2 text-gray-700 hover:text-gray-900"></ui-icon>
            </div>

            <div class="bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md mb-1 shadow-xs">
                <div class="m-1 p-1 cursor-pointer flex justify-center items-center w-10 h-10" :class="isActive('info') ? 'bg-blue-100 rounded-full': ''" @click="mainMenuClick('info')" v-tooltip.left="sections['info']">
                    <ui-icon name="stats-info" class="text-3xl" :class="isActive('info') ? 'text-gray-900' : 'text-gray-700 hover:text-gray-900'"></ui-icon>
                </div>

                <div  class="m-1 p-1 cursor-pointer flex justify-center items-center w-10 h-10" :class="isActive('layout') ? 'bg-blue-100 rounded-full': ''" @click="mainMenuClick('layout')" v-tooltip.left="sections['layout']">
                    <ui-icon name="layout" class="text-3xl" :class="isActive('layout') ? 'text-gray-900' : 'text-gray-700 hover:text-gray-900'"></ui-icon>
                </div>

                <div class="m-1 p-1 cursor-pointer flex justify-center items-center w-10 h-10" :class="isActive('style') ? 'bg-blue-100 rounded-full': ''" @click="mainMenuClick('style')" v-tooltip.left="sections['style']">
                    <ui-icon name="styling" class="text-3xl" :class="isActive('style') ? 'text-gray-900' : 'text-gray-700 hover:text-gray-900'"></ui-icon>
                </div>

                <div class="m-1 p-1 cursor-pointer flex justify-center items-center w-10 h-10" :class="isActive('metrics') ? 'bg-blue-100 rounded-full': ''" @click="mainMenuClick('metrics')" v-tooltip.left="sections['metrics']">
                    <ui-icon name="beaker" class="text-3xl" :class="isActive('metrics') ? 'text-gray-900' : 'text-gray-700 hover:text-gray-900'"></ui-icon>
                </div>

                <div class="relative m-1 p-1 cursor-pointer flex justify-center items-center w-10 h-10" :class="isActive('filter') ? 'bg-blue-100 rounded-full': ''" @click="mainMenuClick('filter')" v-tooltip.left="sections['filter']">
                    <ui-icon name="filter" class="text-3xl" :class="isActive('filter') ? 'text-gray-900' : 'text-gray-700 hover:text-gray-900'"></ui-icon>
                    <div v-if="filtersActive()" id="active-badge" class="absolute px-0.5 bg-red-400 top-4 text-white">Active</div>
                </div>
            </div>

            <div class="bg-white border border-gray-200 rounded-md shadow-xs">
                <div class="p-1 cursor-pointer flex justify-center items-center w-12 h-12 rounded-md" :class="dragModeEnabled ? 'bg-blue-100' : 'bg-white'"  @click="toggleDragMode" v-tooltip.left="'Move Nodes'">
                    <ui-icon name="graph-move" class="text-3xl" :class="dragModeEnabled ? 'text-black' : 'text-gray-600'"></ui-icon>
                </div>

                <div class="p-1 cursor-pointer flex justify-center items-center w-12 h-12 rounded-md" :class="selectionModeEnabled ? 'bg-blue-100' : 'bg-white'" @click="toggleSelectionMode" v-tooltip.left="'Select Nodes'">
                    <ui-icon name="selection" class="text-3xl" :class="selectionModeEnabled ? 'text-black' : 'text-gray-600'"></ui-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "pinia"
import GraphLayout from "@/components/connections/partials/graph-layout.vue"
import GraphMetrics from "@/components/connections/partials/graph-metrics.vue"
import GraphStyle from "@/components/connections/partials/graph-style.vue"
import GraphStats from "@/components/connections/partials/graph-stats.vue"
import GraphFilter from "@/components/connections/partials/graph-filter.vue"

import useGraphEventsStore from "@/stores/connections/graph-events.js"
import useGraphFiltersStore from "@/stores/connections/graph-filters.js"

export default {
    components: { GraphFilter, GraphMetrics, GraphStats, GraphStyle, GraphLayout },

    props: {
        store: { type: Object, required: true }
    },

    data: () => ({
        activeSection: '',
        showPanel: false,

        sections: {
            info: 'Stats',
            layout: 'Layout',
            metrics: 'Metrics',
            style: 'Styling',
            data: 'Data',
            filter: 'Filters'
        }
    }),

    computed: {
        ...mapState(useGraphEventsStore, [ 'selectionModeEnabled', 'dragModeEnabled' ]),
    },

    methods: {
        ...mapActions(useGraphEventsStore, [ 'toggleSelectionMode', 'toggleDragMode' ]),
        ...mapActions(useGraphFiltersStore, [ 'filtersActive' ]),

        isActive(section) {
            return this.activeSection === section
        },

        mainMenuClick(section) {
            if (this.activeSection === section) {
                this.hidePanel()
                return
            }

            this.activeSection = section
            this.showPanel = true
        },

        hidePanel() {
            this.showPanel = false
            this.activeSection = ""
        }
    }
}
</script>

<style>
#active-badge {
    font-size: 8px;
    line-height: 10px;
    border-radius: 10px;
    opacity: 0.7;
}
</style>
