<template>
    <ui-header :title="board.name || 'Dashboard'" :avatar="board.avatar">
        <template v-slot:actions-start>
            <ui-contextual-menu plain color="black" class="ml-2 mt-1" z-index="60">
                <ui-dropdown-link icon="edit" @click="edit">Edit</ui-dropdown-link>
                <ui-dropdown-separator></ui-dropdown-separator>
                <ui-dropdown-link icon="settings" @click="editBoard(board)">Settings</ui-dropdown-link>
                <ui-dropdown-separator></ui-dropdown-separator>
                <ui-dropdown-link icon="copy" @click="duplicateBoard(board)">Duplicate...</ui-dropdown-link>
            </ui-contextual-menu>
        </template>
        <template v-slot:actions-end>
            <ui-button color="blue" size="sm" icon="check-circle" v-if="isEditing || hasUnsavedChanges" :action="save">
                Save
            </ui-button>

            <ui-button color="blue" size="sm" @click.prevent v-if="! isEditing && isBeingSaved">
                <ui-spinner color="white" type="clip" class="text-lg mr-1"></ui-spinner>
                Saving...
            </ui-button>

            <ui-help-link page="dashboards" class="text-3xl"></ui-help-link>
        </template>
    </ui-header>
</template>

<script>
import useBoardStore from '@/stores/dashboards/board'
import useModalsBoardDuplicateStore from '@/stores/dashboards/modals/board-duplicate'
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useBoardStore, [ 'board', 'hasUnsavedChanges', 'isBeingSaved', 'isEditing' ])
    },

    methods: {
        ...mapActions(useBoardStore, [ 'edit', 'save' ]),

        ...mapActions(useModalsBoardDuplicateStore, {
            duplicateBoard: 'open'
        }),

        ...mapActions(useModalsBoardEditStore, {
            editBoard: 'open'
        })
    }
}
</script>
