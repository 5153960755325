<template>
    <div class="text-center">
        <h1 class="font-semibold text-lg mb-1">Are you sure you want to delete this {{entity}}?</h1>
    </div>

    <div v-if="hasDependents">
        <p class="text-center mb-1">
            These items are currently using this {{entity}}.
        </p>
        <p class="text-center">
            Deleting this item will update them, which might change their results or how they are displayed.
        </p>

        <div class="mt-6">
            <div class="space-y-1.5 bg-gray-50/25 hover:bg-gray-50/75 text-gray-800 w-full">
                <a :href="$router.resolve(resolveRoute(dependent)).href" target="_blank" class="flex items-center w-full px-5 py-2 cursor-pointer text-gray-700 hover:text-gray-900 border border-gray-300 rounded-lg text-lg group" :key="i" v-for="dependent, i in dependents">
                    <div>
                        <ui-icon :name="resolveIcon(dependent)" class="text-3xl"></ui-icon>
                    </div>
                    <div class="px-4">
                        <h1 class="text-lg font-medium">{{dependent.name}}</h1>
                        <p class="text-sm text-gray-700 group-hover:text-gray-900">{{resolveType(dependent)}}</p>
                    </div>
                    <div class="ml-auto">
                        <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalActions, mapLocalState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'dependents', 'entity', 'hasDependents', 'submitting' ])
    },

    methods: {
        ...mapLocalActions([ 'cancel', 'confirm' ]),

        resolveIcon(dependent) {
            return {
                'alerts': 'at-sign',
                'analyses': 'analysis',
                'dashboards': 'dashboard',
                'perspectives': 'perspective',
                'topics': 'topic'
            }[dependent.family]
        },

        resolveType(dependent) {
            return {
                'alerts': 'Alert',
                'analyses': 'Analysis Series',
                'dashboards': 'Dashboard Widget',
                'perspectives': 'Filter in Perspective',
                'topics': 'Parent Topic'
            }[dependent.family]
        },

        resolveRoute(dependent) {
            return {
                'alerts': { name: 'alerts', query: { show: `alert|id:${dependent.resource.id}` } },
                'analyses': { name: 'analysis.analysis.details', params: { type: dependent.resource.type, id: dependent.resource.id } },
                'dashboards': { name: 'dashboard.boards.board', params: { id: dependent.resource.id } },
                'perspectives': { name: 'content.perspectives.perspective', params: { perspectiveId: dependent.resource.id } },
                'topics': { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${dependent.resource.query}|${dependent.resource.language}|`, 'filter[date]': 'past:1|months', topic: dependent.resource.id } }
            }[dependent.family]
        }
    }
}
</script>
