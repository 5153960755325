<template>
    <div class="bg-white border border-gray-200 shadow-xs rounded-lg p-4 flex flex-col min-w-0" :class="isComparing ? 'h-80' : 'h-72'">
        <div class="flex justify-between pb-2">
            <h1 class="text-lg font-medium text-gray-700">Platforms</h1>

            <div class="flex gap-x-2">
                <a @click.prevent="store.toggleType()" href="#" class="flex items-center font-medium text-gray-700 mb-2">
                    {{ type == 'content' ? 'Content' : 'Interactions' }} <ui-icon name="select" class="text-xs"></ui-icon>
                </a>
                <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3">
                    <span class="rounded-sm px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <ui-async class="flex-1 flex min-h-0 items-center" :guards="! series.analysisStore().isLoading">
                    <chart :options="analysisOptions(series)" ref="analysis" class="h-full w-full"></chart>
                </ui-async>

                <div class="absolute inset-0 backdrop-blur-xs rounded-lg flex items-center justify-center" :class="{'bg-white': ! isInitialized}" v-if="series.analysisStore().isLoading">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

import { arrayWrap } from '@/helpers/array'
import { mapLocalActions, mapLocalState } from '@/helpers/pinia'

export default {
    components: {
        Chart
    },

    computed: {
        ...mapLocalState([ 'isComparing', 'isInitialized', 'series', 'type' ])
    },

    methods: {
        ...mapLocalActions([ 'analysisOptions' ])
    },

    watch: {
        'series.length'() {
            setTimeout(() => arrayWrap(this.$refs.analysis).forEach(a => a.chart.reflow()), 500)
        }
    }
}
</script>
