import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('myCuratedLists', {
    state: () => ({
        categories: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    getters: {
        flatCategories(store) {
            return store.flatten(store.categories)
        },

        flatLists(store) {
            return store.flatCategories.flatMap(c => c.lists.map(l => ({ ...l, category: c })))
        }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('curated lists').get().json(res => {
                this.categories = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.categories
        },

        find(id) {
            return this.flatCategories.find(c => c.id == id)
        },

        findList(id) {
            return this.flatLists.find(l => l.id == id)
        },

        flatten(categories) {
            return categories.flatMap(c => {
                return [ c, ...this.flatten(c.children) ]
            })
        }
    }
})
