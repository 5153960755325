<template>
    <h1 class="text-[24px] font-semibold mb-4">{{title}}</h1>
    <div ref="content" class="leading-loose help-text" @click.stop="click" v-html="content"></div>
</template>

<script>
export default {
    props: [ 'title', 'content' ]
}
</script>

<style>
@reference '../../../assets/styles/tailwind.css';

.help-text h1 {
    @apply text-3xl font-semibold leading-loose pt-3 pb-1;
}

.help-text h2 {
    @apply text-xl font-semibold leading-loose pt-2 pb-1;
}

.help-text h3 {
    @apply text-lg font-semibold leading-loose pt-1 pb-1;
}

.help-text p {
    @apply leading-normal pb-3;
}

.help-text p a {
    @apply text-blue-700 font-semibold;
}

.help-text img {
    @apply mb-3 rounded;
}

.help-text ul, ol {
    white-space: normal;
    @apply pl-5;
}

.help-text ul {
    list-style-type: disc;
}

.help-text ol {
    list-style-type: decimal;
}

.help-text li {
    @apply pb-0.5;
}

.help-text pre {
    @apply py-1 px-2 font-mono text-sm bg-gray-50 rounded-sm mb-3 text-amber-700;
}

.help-text .hint h2 {
    font-size: 15px;
    line-height: 17px;
    padding-top: 6px;
    margin-left: 0;
}

.help-text .hint.note {
    @apply bg-blue-50 border-l-4 border-blue-800 px-4 py-2 my-3 rounded-r;
}

.help-text .hint.note h2 {
    @apply text-blue-700;
}

.help-text .hint.important {
    @apply bg-orange-50 border-l-4 border-orange-600 px-4 py-2 my-3 rounded-r;
}

.help-text .hint.important h2 {
    @apply text-orange-700;
}

.help-text .hint.danger {
    @apply bg-red-50 border-l-4 border-red-600 px-4 py-2 my-3 rounded-r;
}

.help-text .hint.danger h2 {
    @apply text-red-700;
}

.help-text table tr {
    @apply border-b border-gray-300;
}

.help-text table th {
    @apply px-3 py-1.5 font-medium leading-tight;
}

.help-text table td {
    @apply border-x border-gray-300 px-3 py-1.5 leading-tight;
}
</style>
