<template>
    <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
        appear
    >
        <div class="fixed inset-y-0 right-0 max-w-xl w-full h-full p-4 pb-20 z-40 flex bg-linear-to-r from-transparent to-[#2c404c55]" v-show="isShowingOverlay">
            <div class="bg-white border border-gray-200 shadow-xl flex flex-col h-full w-full rounded-xl">
                <div class="flex items-center justify-between gap-x-1.5 px-8 py-6">
                    <a @click.prevent="index" href="#" class="text-gray-700 hover:text-gray-900">
                        <h1 class="flex items-center gap-x-1.5 text-lg font-semibold">
                            <ui-icon name="book" class="text-xl"></ui-icon>
                            User Guide
                        </h1>
                    </a>

                    <a href="#" class="text-xl text-gray-700 hover:text-gray-900 leading-none " @click.prevent="closeOverlay">
                        <ui-icon name="remove"></ui-icon>
                    </a>
                </div>

                <ui-async v-if="content" class="flex-1 min-h-0 px-8 pb-6 overflow-y-scroll" :guards="content">
                    <help-content :title="title" :content="content"></help-content>
                    <p class="text-xs text-gray-600 mt-6" v-if="updatedAt">Last updated {{ $dateTime(updatedAt) }}</p>
                </ui-async>

                <div v-else class="flex-1 min-h-0 px-8 pb-6 overflow-y-scroll leading-relaxed">
                    <div v-for="page in pages" :key="page.id">
                        <a @click.prevent="show(page)" href="#" class="flex gap-x-1.5 text-gray-800 hover:text-gray-900 hover:ml-1.5 transition-all">
                            <h1 class="text-xl font-semibold py-0.5">{{page.title}}</h1>
                            <div class="border-b border-dashed border-gray-300 flex-1 mb-2.5"></div>
                        </a>
                        <ul v-if="page.sections.length" class="pl-3">
                            <li v-for="section in page.sections" :key="section.id">
                                <a @click.prevent="show(page.id, section.id)" href="#" class="block text-gray-700 hover:text-gray-900 hover:ml-1.5 transition-all">
                                    <h2 class="font-medium">{{section.title}}</h2>
                                </a>
                                <ul v-if="section.children.length" class="pl-3">
                                    <li v-for="section in section.children" :key="section.id">
                                        <a @click.prevent="show(page.id, section.id)" href="#" class="block text-gray-700 hover:text-gray-900 hover:ml-1.5 transition-all">
                                            <h3>{{section.title}}</h3>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import useSupportUserGuideStore from '@/stores/support/user-guide'
import useSupportWhatsNewStore from '@/stores/support/whats-new'

import HelpContent from '@/components/support/help/content'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        HelpContent
    },

    computed: {
        ...mapState(useSupportUserGuideStore, [ 'updatedAt', 'pages' ]),

        isShowingOverlay() {
            return useSupportUserGuideStore().isShowingOverlay || useSupportWhatsNewStore().isShowingOverlay
        },

        title() {
            return useSupportUserGuideStore().title || useSupportWhatsNewStore().title
        },

        content() {
            return useSupportUserGuideStore().content || useSupportWhatsNewStore().content
        }
    },

    methods: {
        ...mapActions(useSupportUserGuideStore, [ 'click', 'index', 'show' ]),

        closeOverlay() {
            useSupportUserGuideStore().closeOverlay()
            useSupportWhatsNewStore().closeOverlay()
        }
    }
}
</script>
