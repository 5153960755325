<template>
    <search-filter name="Alert" :ids="['trigger']" :filters="filters" v-if="value">
        <template v-slot:applied>
            <ui-icon name="bell" class="mr-1"></ui-icon>
            {{value.alert}}
        </template>

        <template v-slot:options>
            <div class="w-64 p-2 pt-0 text-center text-sm">
                <div class="mb-2 font-medium flex items-center justify-center gap-x-1.5">
                    <ui-avatar :name="value.alert" class="w-5 h-5"></ui-avatar>
                    {{value.alert}}
                </div>

                <div class="mb-2 text-xs text-gray-600">
                    Triggered at {{$dateTime(value.createdAt)}}
                </div>

                <div class="text-xs text-gray-500">
                    Showing only content associated with this alert trigger.
                </div>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    props: [ 'filters' ],

    components: {
        SearchFilter
    },

    data: () => ({
        alert: null
    }),

    computed: {
        value() {
            return this.filters.value('trigger')
        }
    }
}
</script>
