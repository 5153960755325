<template>
    <ui-dropdown :align="align || 'left'" z-index="50" width="w-auto" :disabled="readOnly" :keep-open="keepOpen">
        <template v-slot:trigger>
            <a href="#" class="block" :class="{ 'cursor-default': readOnly }">
                <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-none bg-blue-50 text-blue-600 rounded-sm max-w-full" v-if="applied">
                    <slot name="applied"></slot>
                </div>

                <div class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-50 rounded-sm max-w-full" v-else>
                    {{name}}
                </div>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div @click.stop>
                <div v-if="! noHeader" class="flex items-center gap-x-1.5 px-4 py-3 pb-2 font-medium text-gray-900 leading-none">
                    <p class="flex-1">{{name}}</p>
                    <ui-help-link v-if="helpSection" page="content" :section="helpSection"></ui-help-link>
                    <a href="#" @click.prevent="close" class="ml-auto">
                        <ui-icon name="x"></ui-icon>
                    </a>
                </div>

                <slot name="options" :close="close"></slot>

                <template v-if="$slots.controls">
                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <div class="pt-1">
                        <slot name="controls" :close="close"></slot>
                    </div>
                </template>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    props: {
        'align': {},
        'filters': {},
        'helpSection': {},
        'ids': { default: [] },
        'keepOpen': Boolean,
        'name': {},
        'noHeader': Boolean
    },

    computed: {
        applied() {
            return this.ids.find(f => this.filters.value(f))
        },

        readOnly() {
            return this.ids.find(f => this.filters.isReadOnly(f))
        }
    }
}
</script>
