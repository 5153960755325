import { defineStore } from 'pinia'
import useGraphsStore from '@/stores/connections/graphs'

export default defineStore('connectionsGraphsPage', {
    actions: {
        async afterEnter(to) {
            useGraphsStore().init()
        }
    }
})
