<template>
    <div>
        <slot name="placeholder" v-if="! store.tableRows || ! store.tableRows.length">
            <div class="h-32 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="list" class="mb-2 text-3xl"></ui-icon>
                <p class="text-sm">There's no data for this table yet</p>
            </div>
        </slot>

        <table class="table-fixed w-full overflow-y-scroll" v-else>
            <caption>{{ store.tableOptions.caption }}</caption>

            <thead>
            <tr :class="headerRowStyle()">
                <th v-for="header in store.tableOptions.headers" :key="header.id" :id="header.id" :class="headerColumnStyle(header)">
                    <div :class="headerColumnContentStyle(header)">
                        <p>{{ header.title }}</p>
                    </div>
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="row in store.tableRows" :key="row.id" @click="row.click ? row.click() : null" :class="[rowStyle(row), { 'cursor-pointer': row.click }]">
                <td v-for="column in row.columns" :key="column.id" @click="column.click ? column.click() : null" @mouseover="column.mouseover ? column.mouseover() : null" @mouseout="column.mouseout ? column.mouseout() : null" :class="[columnStyle(column), { 'cursor-pointer': column.click }]">
                    <div
                        v-if="column.progress"
                        class="absolute top-1 right-0 z-0 mx-2 bg-gray-100 h-6 rounded-xs"
                        :style="{
                            'width': column.value / store.tableOptions.rows.reduce((a, b) => a + b.columns.find(c => c.id === column.id).value, 0) * 100 + '%',
                            'background-color': store.tableOptions.progressColor || '#c5dafb50'
                        }"
                    ></div>

                    <img v-if="column.type === 'image'" class="h-6 w-6 mx-auto rounded-full relative z-10" :class="[columnContentStyle(column)]" :src="column.value" alt="Row image">

                    <ui-avatar v-else-if="column.type === 'avatar'" class="h-6 w-6 mx-auto relative z-10" :class="[columnContentStyle(column)]" :item="column.value"></ui-avatar>

                    <p v-else-if="column.type === 'color'" class="rounded-full h-3 w-3 relative z-10" :class="[columnContentStyle(column)]" :style="row.enabled ? `background: ${column.value}` : `background: none`"></p>

                    <span v-else-if="column.type === 'icon'" class="relative z-10">
                        <ui-icon :name="column.value" class="text-sm"></ui-icon>
                    </span>

                    <component v-else-if="column.type == 'component'" :is="column.component" v-bind="column.props" text-class="line-clamp-1 relative z-10"></component>

                    <p v-else :class="[columnContentStyle(column)]" class="relative z-10">{{ column.formatter ? column.formatter(column.value) : column.value }}</p>
                </td>
            </tr>

            <tr v-for="footer in store.tableOptions.footers" :key="footer.id" :id="footer.id" :class="footerRowStyle(footer)">
                <td v-for="column in footer.columns" :key="column.id" @click="column.click ? column.click() : null" :class="[footerColumnStyle(column), { 'cursor-pointer': column.click }]">
                    <p>{{ column.formatter ? column.formatter(column.value) : column.value }}</p>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    methods: {
        headerRowStyle() {
            if (this.store.tableOptions.headerStyle) return this.store.tableOptions.headerStyle

            let style = ['text-left', 'text-sm', 'text-gray-600']

            return this.store.tableOptions.additionalHeaderStyle ? style.concat(this.store.tableOptions.additionalHeaderStyle) : style
        },

        headerColumnStyle(column) {
            if (this.store.tableOptions.headerColumnStyle) return this.store.tableOptions.headerColumnStyle
            if (column.style) {return column.style}

            let style = ['font-medium', 'pb-2', 'px-4']

            return column.additionalStyle ? style.concat(column.additionalStyle) : style
        },

        headerColumnContentStyle(column) {
            if (column.contentStyle) {return column.contentStyle}

            let style = ['cursor-default', 'whitespace-nowrap']

            return column.additionalContentStyle ? style.concat(column.additionalContentStyle) : style
        },

        rowStyle(row) {
            if (row.style) {return row.style}

            let style = row.enabled ? ['text-gray-900', 'font-medium', 'text-sm', 'even:bg-gray-50'] : ['text-gray-900', 'text-sm', 'even:bg-gray-50']

            return row.additionalStyle ? style.concat(row.additionalStyle) : style
        },

        columnStyle(column) {
            if (column.style) {return column.style}

            let style = column.progress ? ['relative', 'py-1', 'px-4'] : ['py-1', 'px-4']

            return column.additionalStyle ? style.concat(column.additionalStyle) : style
        },

        columnContentStyle(column) {
            if (column.contentStyle) {return column.contentStyle}
            if (column.type == 'image' || column.type == 'color') return

            let style = ['line-clamp-1']

            return column.additionalContentStyle ? style.concat(column.additionalContentStyle) : style
        },

        footerRowStyle(row) {
            if (row.style) {return row.style}

            let style = ['text-left', 'text-sm', 'text-gray-700']

            return row.additionalStyle ? style.concat(row.additionalStyle) : style
        },

        footerColumnStyle(column) {
            if (column.style) {return column.style}

            let style = ['font-medium', 'pt-2']

            return column.additionalStyle ? style.concat(column.additionalStyle) : style
        }
    }
}
</script>
