<template>
    <ui-dropdown width="w-56">
        <template v-slot:trigger>
            <a href="#" class="flex items-center justify-center h-full font-medium gap-x-1.5 px-3" :class="{ 'text-gray-700 hover:text-gray-900': ! store.translate.enabled, 'text-blue-500': store.translate.enabled }">
                <ui-icon name="translate" class="text-xl"></ui-icon> Translate
            </a>
        </template>
        <template v-slot:content>
            <ui-dropdown-link @click="store.toggleTranslation()" :selected="store.translate.enabled" keep-open>Enable auto-translation</ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="text-xs font-medium text-gray-700 px-4 mt-1.5 cursor-pointer">Translate into</div>
            <ui-dropdown-link :icon="translateLanguage.icon" submenu>
                {{translateLanguage.name}}...

                <template v-slot:submenu>
                    <div class="max-h-80 overflow-y-auto">
                        <template v-for="language, index in translatableLanguages" :key="index">
                            <ui-dropdown-link v-if="language" @click="() => setTranslateLanguage(language)" :icon="language.icon">{{language.name}}</ui-dropdown-link>
                            <ui-dropdown-separator v-else></ui-dropdown-separator>
                        </template>
                    </div>
                </template>
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="text-xs font-medium text-gray-700 px-4 mt-1.5 cursor-pointer">Except languages</div>
            <ui-dropdown-link v-for="language in exceptLanguages" @click="() => removeException(language)" :icon="language.icon" :key="language.code" keep-open>{{language.name}}</ui-dropdown-link>
            <ui-dropdown-link icon="plus-circle" submenu>
                Add exception...

                <template v-slot:submenu>
                    <div class="max-h-80 overflow-y-auto">
                        <template v-for="language, index in translatableLanguages" :key="index">
                            <ui-dropdown-link v-if="language" @click="() => addException(language)" :icon="language.icon" keep-open>{{language.name}}</ui-dropdown-link>
                            <ui-dropdown-separator v-else></ui-dropdown-separator>
                        </template>
                    </div>
                </template>
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import useMyStore from '@/stores/me/my'

import { default as language, languages } from '@/helpers/language'
import { sortAlphabetically } from '@/helpers/sorting'

export default {
    computed: {
        translateLanguage() {
            return language(useMyStore().preferences.translateLanguage)
        },

        translatableLanguages() {
            return [
                language('en'),
                null,
                ...languages.filter(language => language.translatable && language.code != 'en' && language.icon),
                null,
                ...languages.filter(language => language.translatable && language.code != 'en' && ! language.icon)
            ]
        },

        exceptLanguages() {
            return sortAlphabetically(useMyStore().preferences.contentStreamAutoTranslateExceptions.map(code => language(code)))
        }
    },

    methods: {
        setTranslateLanguage(language) {
            useMyStore().updatePreferences({ translateLanguage: language.code })
            this.store.translateContent(true)
        },

        addException(language) {
            let exceptions = useMyStore().preferences.contentStreamAutoTranslateExceptions

            if (! exceptions.includes(language.code)) {
                useMyStore().updatePreferences({ contentStreamAutoTranslateExceptions: [ ...exceptions, language.code ] })
            }
        },

        removeException(language) {
            let exceptions = useMyStore().preferences.contentStreamAutoTranslateExceptions

            if (exceptions.includes(language.code)) {
                useMyStore().updatePreferences({ contentStreamAutoTranslateExceptions: exceptions.filter(code => code != language.code) })
            }
        }
    }
}
</script>
