import useMyBroadcasterStore from '@/stores/me/broadcaster'
import useMyStore from '@/stores/me/my'
import useSupportChatStore from '@/stores/support/chat'
import useTriggerStore from '@/stores/alerts/trigger'

import api from '@/api'
import { useRouter } from '@/helpers'
import { downloadUrl } from '@/helpers/download'
import { sortByDate } from '@/helpers/sorting'
import { debug } from '@/helpers/logger'

import { isToday, parseISO } from 'date-fns'
import { defineStore } from 'pinia'

export default defineStore('myNotifications', {
    state: () => ({
        broadcaster: null,

        isInitialized: false,
        isSubscribed: false,
        isOverlayShown: false,
        loadingPromise: null,
        isLoading: false,
        isShowingAllNotifications: false,

        notifications: [],
        toasts: [],
        tasks: [],

        lastToastId: 0,

        onToast: {},
        onTaskComplete: {}
    }),

    getters: {
        shownNotifications: store => {
            let notifications = sortByDate(store.notifications).reverse()
            let todayNotifications = notifications.filter(n => isToday(parseISO(n.createdAt)))

            if (store.isShowingAllNotifications) return notifications

            return todayNotifications.length ? todayNotifications : notifications.slice(-1)
        },

        unreadNotificationsCount(state) {
            return state.notifications.filter(n => n.readAt === null).length
        }
    },

    actions: {
        async initialize() {
            this.load()

            await this.handleBroadcast()
        },

        toggle() {
            this.isOverlayShown ? this.hide() : this.show()
        },

        show() {
            useSupportChatStore().hide()

            this.isOverlayShown = true

            this.dismissAllToasts()
            this.loadNew()
        },

        hide() {
            if (! this.isOverlayShown) return

            this.isOverlayShown = false

            api.route('me notifications mark-as-read')
                .json({
                    ids: this.notifications.filter(n => n.readAt === null).map(n => n.id)
                }).post()

            this.notifications.forEach(n => n.readAt = new Date())
        },

        onTaskCompletion(taskId, callback) {
            this.onTaskComplete[taskId] = this.onTaskComplete[taskId] || []
            this.onTaskComplete[taskId].push(callback)
        },

        onToast(id, callback) {
            this.onToast[id] = callback
        },

        removeOnToast(id) {
            delete this.onToast[id]
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me notifications').get().json(res => {
                this.notifications = []

                res.data.forEach(n => {
                    n.actions?.forEach(action => action.onAction = this.resolveOnAction(action))
                    this.notifications.push(n)
                })

                this.isLoading = false
                this.isInitialized = true
            })
        },

        loadNew() {
            this.isLoading = true

            api.route('me notifications')
                .query({ after: this.notifications[0]?.createdAt })
                .get()
                .json(res => {
                    res.data.forEach(n => {
                        n.actions?.forEach(action => action.onAction = this.resolveOnAction(action))
                        this.notifications.unshift(n)
                    })

                    this.isLoading = false
                })
        },

        loadMore($state) {
            let lastNotification = this.notifications[this.notifications.length - 1]

            if (! lastNotification) return $state.complete()

            api.route('me notifications')
                .query({ before: lastNotification.createdAt })
                .get()
                .json(res => {
                    if (! res.data.length) {
                        return $state.complete()
                    }

                    res.data.forEach(n => {
                        n.actions?.forEach(action => action.onAction = this.resolveOnAction(action))
                        this.notifications.push(n)
                    })

                    $state.loaded()
                })
        },

        async handleBroadcast() {
            await useMyBroadcasterStore().initialize()

            useMyBroadcasterStore().broadcaster.private(`workspaces.${useMyStore().currentWorkspace.id}`)
                .notification((notification) => this.processIncoming(notification))

            useMyBroadcasterStore().broadcaster.private(`users.${useMyStore().user.id}`)
                .notification((notification) => this.processIncoming(notification))
        },

        processIncoming(notification) {
            debug('Notifications - incoming notification', notification)

            if (notification.type.startsWith('task.')) {
                this.processIncomingTask(notification)
            } else if (! notification.silent) {
                this.pushToast(notification)
            }
        },

        processIncomingTask(incomingTask) {
            let task = this.resolveTask(incomingTask.taskId)

            Object.assign(task, {
                type: incomingTask.type,
                state: incomingTask.state,
                progress: incomingTask.progress,
                payload: incomingTask.payload,
                updatedAt: incomingTask.updatedAt
            })
            
            if (incomingTask.type == 'task.completed' && this.onTaskComplete[task.id]) {
                this.onTaskComplete[task.id].forEach(callback => callback(task))
                delete this.onTaskComplete[task.id]
            }

            if (incomingTask.notification && ! incomingTask.notification.silent) {
                let toast = {
                    ...incomingTask.notification,
                    taskId: incomingTask.taskId,
                    progress: incomingTask.progress,
                    level: { completed: 'success', failed: 'error' }[incomingTask.state] || 'info'
                }
                let existingToast = this.toasts.find(toast => toast.taskId == incomingTask.taskId)

                if (existingToast) {
                    this.updateToast(existingToast, toast)
                } else {
                    this.pushToast(toast)
                }
            }
        },

        pushToast(toast) {
            if (toast.uniqueId && this.toasts.find(t => t.uniqueId === toast.uniqueId)) return
            if (Object.values(this.onToast).some(callback => callback(toast) === false)) return

            toast.id = ++this.lastToastId
            toast.createdAt = new Date
            toast.actions?.forEach(action => action.onAction = this.resolveOnAction(action))
            toast.dismiss = () => this.dismissToast(toast)

            this.toasts.push(toast)

            if (toast.expires) {
                setTimeout(() => this.dismissToast(toast), (toast.expires === true ? 5 : toast.expires) * 1000)
            }

            return toast
        },

        updateToast(toast, newToast) {
            newToast.actions?.forEach(action => action.onAction = this.resolveOnAction(action))

            Object.assign(toast, newToast)

            if (toast.expires) {
                setTimeout(() => this.dismissToast(toast), (toast.expires === true ? 3 :toast.expires) * 1000)
            }

            return toast
        },

        dismissToast(toast) {
            if (toast.onDismiss) toast.onDismiss()

            this.toasts = this.toasts.filter(t => t.id != toast.id)
        },

        dismissAllToasts() {
            this.toasts = []
        },

        resolveOnAction(action) {
            if (action.onAction) {
                return action.onAction
            } else if (action.navigateTo) {
                return () => useRouter().replace(action.navigateTo instanceof Object ? action.navigateTo : { path: action.navigateTo })
            } else if (action.openUrl) {
                return () => window.open(action.openUrl, action.target || '_self')
            } else if (action.download) {
                return () => downloadUrl(action.download)
            } else if (action.showAlert) {
                return () => useTriggerStore().open(action.showAlert)
            }
        },

        reportApiError() {
            this.pushToast({
                title: 'Unexpected error',
                body: 'You might need to reload the page to recover normal functionality. Please contact us if the problem persists.',
                level: 'error',
                actions: [{
                    label: 'Reload',
                    onAction: () => window.location.reload()
                }]
            })
        },

        reportForbiddenError() {
            this.pushToast({
                title: 'Permission error',
                body: 'You don\'t have permission to perform this action. Please contact us if the problem persists.',
                level: 'error',
                actions: [{
                    label: 'Reload',
                    onAction: () => window.location.reload()
                }]
            })
        },

        resolveTask(id) {
            let task = this.tasks.find(t => t.id == id)

            if (! task) this.tasks.push(task = { id, state: 'waiting', payload: {} })

            return task
        },

        showAllNotifications() {
            this.isShowingAllNotifications = true
        }
    }
})
