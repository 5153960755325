<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <div class="flex items-center justify-between px-1 mb-4">
            <h1 class="text-[24px] font-semibold">
                Content
            </h1>
            <ui-help-link page="content" class="text-3xl"></ui-help-link>
        </div>

        <div class="w-full bg-white shadow-xs rounded-lg border border-gray-200 p-2 mb-8">
            <perspective-search :store="$page.streamStore"></perspective-search>
        </div>

        <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="navigation.content" class="text-6xl text-gray-400"></ui-icon>
                <h1 class="text-3xl font-semibold mt-6">Welcome to the Content section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Search</span> through the content we collect from social media platforms and the internet, using a combination of text queries, powerful filters and AI powered semantic search.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Save your combination of search query and filters as a <span class="font-medium">perspective</span>, a building block that can be further used in analyses, dashboards and more.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Make your well-crafted text queries into named <span class="font-medium">topics</span>, saved queries that can be used in multiple perspectives and combined with other topics.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Switch between the <span class="font-medium">results</span> view, showing all matching content and the <span class="font-medium">inspect</span> view, showing you a quick overview including results and interactions timeline, top channels and platforms, sentiment, top links and domains, and more.</p>
                    </div>
                </div>
                <div>
                    <ui-button color="blue" size="lg" :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' } }">
                        Show the latest Content
                    </ui-button>
                </div>
            </div>
        </div>

        <template v-else>
            <ui-cards-section title="Pinned Perspectives" :store="contentIndexPinnedCardsStore" key="indexPinnedCards">
            </ui-cards-section>

            <ui-cards-section :store="contentIndexCardsStore" key="indexCards">
                <template v-slot:title>
                    <div class="flex items-center grow h-8">
                        <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                            <a @click.prevent="contentIndexCardsStore.show('perspectives')" href="#" class="flex items-center justify-center px-3" :class="contentIndexCardsStore.isShowing == 'perspectives' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                                Perspectives
                            </a>
                            <a @click.prevent="contentIndexCardsStore.show('topics')" href="#" class="flex items-center justify-center px-3" :class="contentIndexCardsStore.isShowing == 'topics' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                                Topics
                            </a>
                        </div>
                    </div>
                </template>
            </ui-cards-section>
        </template>
    </div>
</template>

<script>
import PerspectiveSearch from './perspective/stream/search'

import useContentIndexCardsStore from '@/stores/content/index-cards'
import useContentIndexPinnedCardsStore from '@/stores/content/index-pinned-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        PerspectiveSearch
    },

    computed: {
        ...mapStores(useContentIndexCardsStore, useContentIndexPinnedCardsStore),

        isShowingWelcomeMessage() {
            return this.contentIndexCardsStore.isInitialized && this.contentIndexCardsStore.isEmpty
        }
    }
}
</script>
