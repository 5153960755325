<template>
    <div class="flex items-center space-x-2 bg-white rounded-lg shadow-xs p-1.5 px-3 border border-gray-200 h-10">
        <div class="flex items-center w-48 h-8 relative text-sm text-gray-800 -mt-2">
            <div>Monitored channels</div>
            <div class="flex-1 text-right font-semibold text-base mr-1">{{stats?.current?.monitoredChannels}}</div>
            <ui-dropdown width="w-64">
                <template v-slot:trigger>
                    <ui-help-link size="small"></ui-help-link>
                </template>
                <template v-slot:content>
                    <div class="px-4 py-3 text-gray-700">
                        <div class="text-lg">
                            Monitored Channels
                        </div>
                        <div class="leading-tight text-sm mt-2">
                            You are monitoring <span class="font-bold">{{stats?.current?.monitoredChannels}}</span> out of <span class="font-bold">{{stats?.limits?.monitoredChannels}}</span> custom channels available for your organization.
                        </div>
                        <div class="leading-tight text-sm mt-2">
                            If you need to monitor more channels, please <a href="mailto:contact@gerulata.com" class="text-blue-600 hover:text-blue-900">contact us</a> for options.
                        </div>
                    </div>

                    <div class="px-4 py-2 border-t border-gray-200 text-gray-700">
                        <div v-for="option in monitoringOptions" :key="option.name" class="flex items-center">
                            <div class="flex-1 text-sm">
                                <h2>{{option.name}}</h2>
                            </div>
                            <div class="flex items-center">
                                <h1 class="text-sm font-semibold">
                                    {{$number(option.usage)}} / {{$number(option.budget)}}
                                </h1>
                            </div>
                        </div>
                    </div>
               </template>
            </ui-dropdown>
            <div class="absolute inset-x-0 bottom-0 h-1 rounded-full overflow-hidden bg-gray-300">
                <div class="rounded-full bg-green-500 h-full" :class="{ 'bg-green-500': color == 'green', 'bg-orange-500': color == 'orange', 'bg-red-500': color == 'red' }" :style="`width:${Math.min(stats?.current?.monitoredChannels / stats?.limits?.monitoredChannels * 100, 100)}%;`"></div>
            </div>
        </div>
    </div>
</template>

<script>
import useMonitoredStatsStore from '@/stores/channels/monitored-stats'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useMonitoredStatsStore, [ 'color', 'monitoringOptions', 'stats' ])
    }
}
</script>
