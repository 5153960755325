import useContentDetailsStore from '@/stores/content/details'
import useContentPerspectiveStream from '@/stores/content/perspective/stream'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { content } from '@/api'

import { defineStore } from 'pinia'

export default defineStore('contentDetailsPage', {
    actions: {
        async afterEnter(to) {
            useMyPerspectivesStore().initialize()
            
            useContentDetailsStore().load(await content().find(to.params.id), useContentPerspectiveStream())
        }
    }
})
