import defineConnectionsGraph from '@/stores/connections/connections-graph'
import useQuickSearchStore from '@/stores/me/quick-search'
import { defineStore } from 'pinia'
import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox.js'

export default defineStore('connectionsPage', {
    state: () => ({
        graph: defineConnectionsGraph({ id: 'connectionsGraph' })(),
        lightbox: defineStreamMediaLightbox({ id: 'connectionsMediaLightbox', name: 'connectionsMediaLightbox' })
    }),

    getters: {
        title: () => 'Connections Explorer'
    },

    actions: {
        afterEnter(to) {
            this.graph.reset()

            if (to.params?.id && to.params.id !== 'new') {
                setTimeout(() => this.loadGraph(to.params.id), 200)
            } else if (to.params.id === 'new' && !to.params?.ids) {
                setTimeout(() => this.initializeNewGraph(), 200)
            } else if (to.params?.ids) {
                setTimeout(() => this.initializeGraph(to.params.ids.split(',')), 200)
            } else {
                setTimeout(() => this.graph.initialize(), 200)
            }
        },

        chooseChannel() {
            useQuickSearchStore().show({
                families: [ 'channels' ],
                limit: 50,
                onSelect: result => {
                    this.graph.inspectNode([result.id])
                }
            })
        },

        addChannel() {
            useQuickSearchStore().show({
                families: [ 'channels' ],
                limit: 50,
                onSelect: result => {
                    this.graph.inspectNode([result.id])
                }
            })
        },

        initializeNewGraph() {
            this.graph.initialize()
        },

        initializeGraph(nodeIds) {
            this.graph.initialize()
            this.graph.inspectNode(nodeIds)
        },

        loadGraph(id) {
            this.graph.initialize()
            this.graph.loadGraph(id)
        }
    }
})
