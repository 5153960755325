<template>
    <div class="h-8 flex items-center mb-4 relative gap-x-4">
        <toolbar-range :store="store" v-if="store.date !== false"></toolbar-range>
        <toolbar-grouping :store="store" v-if="store.granularity !== false"></toolbar-grouping>

        <div class="flex items-center h-full">
            <ui-dropdown v-if="store.exportable && store.hasValues">
                <template v-slot:trigger>
                    <a href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2">
                        <ui-icon name="download" class="text-xl"></ui-icon> Export
                    </a>
                </template>
                <template v-slot:content>
                    <ui-dropdown-link @click="store.exportChart({ type: 'image/png' })">Chart - PNG image</ui-dropdown-link>
                    <ui-dropdown-link @click="store.exportChart({ type: 'image/svg+xml' })">Chart - SVG image</ui-dropdown-link>
                    <!-- <ui-dropdown-link @click="store.export({ type: 'application/pdf' })">Chart - PDF document</ui-dropdown-link> -->
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <template v-if="store.exportable.data">
                        <ui-dropdown-link @click="store.export('xlsx')">Table - XLS spreadsheet</ui-dropdown-link>
                        <ui-dropdown-link @click="store.export('csv')">Table - CSV file</ui-dropdown-link>
                        <ui-dropdown-separator></ui-dropdown-separator>
                    </template>
                    <!--<ui-dropdown-link @click="store.exportChart('xls')">Dataset - XLS spreadsheet</ui-dropdown-link>-->
                    <ui-dropdown-link @click="store.exportChart('csv')">Dataset - CSV file</ui-dropdown-link>
                </template>
            </ui-dropdown>

            <a href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2" @click.prevent="store.print" v-tooltip="'Print'" v-if="store.printable && store.hasValues">
                <ui-icon name="printer" class="text-xl"></ui-icon> Print
            </a>
        </div>

        <div class="ml-auto"></div>

        <span v-if="store.seriesGeneratedAt">
            Generated at <span class="font-medium">{{$dateTime(store.seriesGeneratedAt)}}</span>
        </span>
    </div>

    <div class="bg-white border border-gray-200 w-full shadow-xs rounded-lg flex mb-4 p-2 space-x-2">
        <slot name="settings"></slot>
    </div>
</template>

<script>
import ToolbarGrouping from './toolbar-grouping'
import ToolbarRange from './toolbar-range'

export default {
    components: {
        ToolbarGrouping, ToolbarRange
    }
}
</script>
