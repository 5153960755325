<template>
    <div class="h-full min-h-[9rem] relative">
        <div v-if="store.isLoading" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-100/10 backdrop-blur-xs rounded-md">
            <div class="bg-gray-200/80 text-gray-900 font-medium px-6 py-4 rounded-lg shadow-xs flex items-center">
                <ui-spinner type="clip" class="mr-2"></ui-spinner>
                Updating heatmap content...
            </div>
        </div>

        <div class="relative min-h-[9rem]" ref="contentContainer" v-if="store.series?.length">
            <div class="w-full h-full" ref="content"></div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
                <div class="absolute mb-3 h-12 w-32" :style="`left:${tooltip.position.x}px;top:${tooltip.position.y}px;`"
                         v-show="tooltip.show">
                    <div
                            class="bg-gray-900/80 border border-gray-700 text-gray-50 shadow-sm rounded-xs text-xs px-3 py-1 text-center -translate-x-1/2">
                        <div class="text-2xs font-medium mb-1">{{ (tooltip.value.date) }}</div>
                        <div class="font-bold">{{ tooltip.value.value }}</div>
                    </div>
                </div>
            </transition>
        </div>

        <div v-else-if="! store.isLoading" class="h-full flex flex-col items-center justify-center text-gray-700">
            <ui-icon name="frown" class="mb-2 text-3xl text-gray-600"></ui-icon>
            <p class="font-medium">Nothing to show yet.</p>
            <p>Please check back later or try a different time range.</p>
        </div>
    </div>
</template>

<script>
import {select} from 'd3-selection'
import {group, min, max, range, reverse} from 'd3-array'
import {timeDays, timeMonday, timeYear} from 'd3-time'
import {scaleQuantize} from 'd3-scale'

export default {
    props: ['cellWidth', 'cellHeight', 'compact'],

    watch: {
        'store.series'(val) {
            if (val?.length) this.$nextTick(() => this.render())
        },

        'store.styles': {
            handler() { this.render() },
            deep: true
        }
    },

    mounted() {
        this.store.series && this.store.series.length && this.render()
    },

    data: () => ({
        tooltip: {
            show: false,
            value: {},
            position: {}
        }
    }),

    methods: {
        dayNumber(date) {
            return (date.getDay() + 6) % 7
        },

        dayFormatter(day) {
            if (this.compact) {
                return ['M', 'T', 'W', 'T', 'F', 'S', 'S'][(day + 6) % 7]
            }

            return ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'][(day + 6) % 7]
        },

        monthFormatter(month) {
            if (this.compact) {
                return ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'][month]
            }

            return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month]
        },

        render() {
            if (! this.$refs.content) return

            this.$refs.content.innerHTML = ''

            let data = [ ...this.store.series ].sort((a, b) => new Date(a.date) - new Date(b.date))

            if (!data.length) return

            let dateValues = data.map(dv => ({
                date: new Date(dv.date),
                value: Number(dv.value),
                url: dv.url || null
            }))

            let dayRange = this.store.styles['heatmap.range'] === 'single'
                ? timeDays(new Date().setFullYear(new Date().getFullYear() - 1), new Date(), 1)
                : timeDays(min(dateValues, d => new Date(new Date(d.date).getFullYear(), 0, 1)), new Date(), 1)

            let m = group(dateValues, d => new Date(d.date).setHours(0))

            dateValues = dayRange.map(date => m.get(date)?.[0] || {date: date, value: 0, url: null})

            const width = this.$refs.content.offsetWidth

            const svg = select(this.$refs.content).append('svg')

            let years = this.store.styles['heatmap.range'] === 'single' ? group(dateValues, () => 'past year') : group(dateValues, d => d.date.getFullYear())
            years = reverse(years)

            const cellWidth = this.cellWidth ?? width / 53 - (25 / 53)
            const cellHeight = this.cellHeight ?? cellWidth
            const yearHeight = cellHeight * 7

            svg.attr('viewBox', `0 0 ${width} ${years.length * (yearHeight + 30)}`)

            const wrapper = svg.append('g')

            // Year groups
            const year = wrapper
                .selectAll('g')
                .data(years)
                .join('g')
                .attr(
                    'transform',
                    (_, i) => `translate(0, ${yearHeight * i + 30 * i})`
                )

            const timeWeek = timeMonday
            const colorFn = scaleQuantize()
                .domain([1, Math.ceil(max(dateValues.map(c => c.value)))])
                .range([ '#c5dafb', '#9fc2f9', '#79a9f6', '#5d96f4', '#3f84f2', '#2172f0', '#0e62e4', '#0f55c8', '#0d49ab' ])

            // Days text on the left
            year
                .append('g')
                .selectAll('text')
                .data(range(7))
                .join('text')
                .attr('x', 0)
                .attr('y', d => (d + 0.84) * cellHeight)
                .attr('class', (this.compact ? 'text-2xs' : 'text-xs') + ' fill-current text-medium text-gray-700')
                .text(d => this.dayFormatter(d + 1))

            // Points
            year
                .append('g')
                .selectAll('rect')
                .data(d => d[1])
                .join('rect')
                .attr('width', cellWidth - 2.5)
                .attr('height', cellHeight - 2.5)
                .attr('x', d => (this.store.styles['heatmap.range'] === 'single' ? timeWeek.count(dayRange[0], d.date) : timeWeek.count(timeYear(d.date), d.date)) * cellWidth + 25)
                .attr('y', d => this.dayNumber(d.date) * cellHeight + 0.5)
                .attr('fill', d => d.value === 0 ? '#f0f3f5' : colorFn(d.value))
                .on('mouseover', (ev, d) => this.showTooltip({date: this.$date(d.date), value: d.value}, { x: ev.clientX, y: ev.clientY }))
                .on('mouseout', () => this.hideTooltip())
                .on('click', (ev, d) => d.url ? window.open(d.url, '_blank') : ev.preventDefault())

            // Month separators
            year
                .append('g')
                .selectAll('rect')
                .data(d => d[1].filter(d => d.date.getMonth() !== new Date(new Date(d.date).setDate(d.date.getDate() + 1)).getMonth()))
                .join('rect')
                .attr('width', cellWidth - 2.5)
                .attr('height', 3.5)
                .attr('x', d => (this.store.styles['heatmap.range'] === 'single' ? timeWeek.count(dayRange[0], d.date) : timeWeek.count(timeYear(d.date), d.date)) * cellWidth + 25)
                .attr('y', d => this.dayNumber(d.date) * cellHeight + cellHeight - 2.5)
                .attr('fill', '#4032f9')

            // Months text on the bottom
            year
                .append('g')
                .selectAll('text')
                .data(d => this.store.styles['heatmap.range'] === 'single' ? d[1].filter(d => d.date.getDate() === 1) : range(12).map(month => new Date(d[0], month, 1)))
                .join('text')
                .attr('x', d => this.store.styles['heatmap.range'] === 'single' ? timeWeek.count(dayRange[0], d.date) * cellWidth : timeWeek.count(timeYear(d), d) * cellWidth + 30)
                .attr('y', 7 * cellHeight + 15)
                .attr('class', (this.compact ? 'text-2xs' : 'text-xs') + ' fill-current text-gray-700')
                .text((d, i) => `${this.monthFormatter(this.store.styles['heatmap.range'] === 'single' ? d.date.getMonth() : i)} '${(this.store.styles['heatmap.range'] === 'single' ? d.date : d).getFullYear().toString().substr(-2)}`)
        },

        showTooltip(value, position) {
            if (! this.$refs.contentContainer) return

            this.tooltip.value = value

            this.tooltip.position = {
                x: position.x - this.$refs.contentContainer.getBoundingClientRect().left,
                y: position.y + 20 - this.$refs.contentContainer.getBoundingClientRect().top
            }

            this.tooltip.show = true
        },

        hideTooltip() {
            this.tooltip.show = false
        },

        handleEvent(callback) {
            return function (event, d) {
                callback(this, d, event)
            }
        },

        reflow() {
            this.render()
        }
    }
}
</script>
