<template>
    <div class="mx-auto pt-2 px-2" v-show="$page.showFilters" v-click-away="() => $page.isSearchFocused = false">
        <div class="flex flex-1 items-center space-x-4">
            <search-input :store="store"></search-input>

            <ui-button color="blue" size="lg" icon="check-square" :action="savePerspective" v-if="store.hasUnsavedChanges && ! $page.perspective">
                Save
            </ui-button>
        </div>

        <div id="top-filters"></div>

        <search-suggestions :store="store" v-show="$page.isSearchFocused && store.search.query && goodSuggestions.length"></search-suggestions>
        <search-filters :store="store" v-show="! ($page.isSearchFocused && store.search.query && goodSuggestions.length)"></search-filters>
    </div>
</template>

<script>
import SearchFilters from './search-filters'
import SearchInput from './search-input'
import SearchSuggestions from './search-suggestions'

import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'
import useSuggestionsStore from '@/stores/reusable/suggestions'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        SearchFilters, SearchInput, SearchSuggestions
    },

    computed: {
        ...mapState(useSuggestionsStore, [ 'goodSuggestions' ])
    },

    methods: {
        ...mapActions(useContentModalsPerspectiveEditStore, { _openEditModal: 'open' }),

        async savePerspective() {
            if (this.store.perspective && this.store.perspective.id) {
                await this.store.savePerspective()
            } else {
                await this._openEditModal(this.store.perspective, this.store)
            }
        }
    }
}
</script>
