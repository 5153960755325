<template>
    <div class="py-2 text-sm">
        <div class="flex items-center">
            <a v-if="reply.publishedBy" :href="$router.resolve({ name: 'channels.channel.information', params: { id: reply.publishedBy.id } }).href" target="_blank">
                <ui-avatar :item="reply.publishedBy" class="w-7 h-7 mr-2"></ui-avatar>
            </a>
            <ui-avatar v-else icon="user" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-7 h-7 mr-2"></ui-avatar>

            <div>
                <a v-if="reply.publishedBy" class="font-medium" :href="$router.resolve({ name: 'channels.channel.information', params: { id: reply.publishedBy.id } }).href" target="_blank">
                    {{ reply.publishedBy.name }}
                </a>
                <span v-else class="font-medium">
                    Anonymous
                </span>

                <a class="block text-2xs leading-none mt-0.5 text-gray-700" :href="$router.resolve({ name: 'content.details', params: { id: reply.id } }).href" target="_blank">
                    <span>{{ $dateTime(reply.publishedAt) }}</span>
                    <span class="ml-1 bg-red-100 text-red-500 py-0.5 rounded-xs px-2 cursor-default" v-if="deleted">Deleted</span>
                </a>
            </div>
        </div>
        <div class="mt-2">
            <span class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></span>
            <a href="#" v-if="! isShowingFullText" class="ml-1 text-blue-600" @click.prevent="readMore">Read more</a>
        </div>
        <div v-if="reply.images.length" class="flex flex-wrap mt-1">
            <img class="w-12 h-12 mr-2 object-contain rounded-md border border-gray-400 hover:border-gray-700 cursor-pointer" @click="showMedia(i)" :src="image.url" v-for="image, i in reply.images" :key="i" alt="">
        </div>
    </div>
</template>

<script>
import language from '@/helpers/language'

export default {
    props: [ 'parent', 'reply', 'streamStore' ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isRTL() {
            return this.reply.translation && ! this.reply.translation.isLoading
                ? language(this.reply.translation.language).rtl : language(this.reply.language[0]).rtl
        },

        isShowingFullText() {
            return this.showFullText || (this.reply.body || '').length <= 100
        },

        text() {
            return this.isShowingFullText ? this.reply.body : this.reply.body.replace(/\n\s*\n/g, "\n").substr(0, 100) + '...'
        },

        deleted() {
            return this.reply.labels.filter(l => l === 'deleted').length
        }
    },

    methods: {
        readMore() {
            this.showFullText = true
            this.streamStore.triggerLayoutUpdate([ this.parent ])
        },

        showMedia(currentIndex) {
            this.streamStore.mediaLightbox.open(this.reply.images, currentIndex)
        }
    }
}
</script>
