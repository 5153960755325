import useMyAnalysesStore from '../stores/me/analyses'
import useMyChannelListsStore from '../stores/me/channel-lists'
import useBoardStore from '../stores/dashboards/board'
import useMyPerspectivesStore from '../stores/me/perspectives'
import useMyTopicsStore from '../stores/me/topics'

import { defineStore } from 'pinia'

export default defineStore('dashboardsBoardPage', {
    getters: {
        title: () => useBoardStore()?.board.name ? `${useBoardStore().board.name} | Dashboards` : ''
    },

    actions: {
        async beforeEnter(to) {
            await Promise.all([
                useMyAnalysesStore().initialize(),
                useMyChannelListsStore().initialize(),
                useMyPerspectivesStore().initialize(),
                useMyTopicsStore().initialize()
            ])

            useBoardStore().initialize(to.params.id).then(() => {
                if (useBoardStore().board?.lastUsedAt) { useBoardStore().board.lastUsedAt = new Date }
            })
        },

        async beforeLeave() {
            useBoardStore().stopUpdating()
        }
    }
})
