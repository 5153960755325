<template>
    <div class="max-w-3xl mx-auto space-y-6 relative">
        <template v-if="store.items.data && store.items.data.length">
            <template v-for="item in store.items.data">
                <slot name="item" :item="item" :layout="store.layout"></slot>
            </template>
        </template>

        <div v-else-if="store.items.isFetching">
            <div :class="containerClass" :style="containerStyle">
                <template v-for="v, i in Array.from({ length: 10 })">
                    <slot name="placeholder" :index="i" :layout="store.layout">
                        <stream-pending-placeholder data-id="-1" :large="!! (i % 2)"></stream-pending-placeholder>
                    </slot>
                </template>
            </div>
        </div>

        <div class="absolute w-[calc(100%+3rem)] h-[calc(100%+3rem)] -top-6 -left-6 z-10 bg-gray-75/50 backdrop-blur-xs rounded-md" v-if="store.items.isFetchingFirst">
            <div class="h-screen w-full absolute -top-28 left-0 flex items-center justify-center">
                <div class="bg-gray-900/75 text-white font-medium px-6 py-4 rounded-lg shadow-xs flex items-center">
                    <ui-spinner type="clip" color="white" class="mr-2"></ui-spinner>
                    Updating results...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StreamPendingPlaceholder from '../cards/pending-placeholder'

export default {
    components: { StreamPendingPlaceholder }
}
</script>
