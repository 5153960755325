<template>
    <div class="bg-white border border-gray-200 shadow-xs rounded-lg w-full p-4 pt-0 flex flex-col min-w-0 relative" :class="isComparing ? 'h-96' : 'h-80'">
        <div class="flex justify-between">
            <div class="flex items-start gap-x-3">
                <div class="flex flex-col justify-center min-h-0 mx-3 cursor-pointer relative pt-4 overflow-hidden" :class="{ 'pt-5 pb-5': isComparing }" v-for="metric in metrics(series[0])" :key="metric.id" @click="selectedMetric = metric.id">
                    <div v-if="selectedMetric == metric.id" class="absolute -top-0.5 left-0 h-1.5 w-full rounded-full bg-blue-700"></div>
                    <div class="flex items-center gap-x-1 font-medium" :class="{ 'text-blue-600': selectedMetric == metric.id, 'text-gray-700': selectedMetric != metric.id, 'text-sm': ! isComparing }">
                        <ui-icon :name="metric.icon" class="text-lg"></ui-icon>
                        <h2>{{metric.name}}</h2>
                    </div>
                    <h1 class="text-3xl font-semibold" v-if="! isComparing">
                        {{$number(metric.current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous && ! isComparing">
                        <div class="text-sm border rounded-sm leading-tight whitespace-nowrap px-1" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{metric.change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(metric.previous)}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-top gap-x-2 pt-4">
                <ui-data-quality-icon :quality="activeMetric(series[0]).dataQuality"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex-1 min-h-0">
            <ui-analysis :store="store"></ui-analysis>
        </div>

        <div v-if="isComparing" class="flex items-start gap-x-3">
            <div class="flex px-3 pt-6 pb-2 gap-x-2" v-for="series, index in series" :key="index">
                <div class="w-5 h-1 rounded-sm mt-2" :style="{ 'background-color': series.color }"></div>
                <div class="flex flex-col justify-center min-h-0">
                    <div class="flex items-center gap-x-1 font-medium text-gray-700">
                        <h2 class="text-sm">
                            {{series.name}}
                        </h2>
                    </div>
                    <h1 class="text-3xl font-semibold">
                        {{$number(activeMetric(series).current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="activeMetric(series).previous">
                        <div class="text-sm border rounded-sm leading-tight whitespace-nowrap px-1" :class="activeMetric(series).previous < activeMetric(series).current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="activeMetric(series).previous < activeMetric(series).current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="activeMetric(series).previous < activeMetric(series).current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{activeMetric(series).change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(activeMetric(series).previous)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalActions, mapLocalState, mapLocalWritableState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'chartOptions', 'isComparing', 'isInitialized', 'isLoading', 'series' ]),
        ...mapLocalWritableState([ 'selectedMetric' ])
    },

    methods: {
        ...mapLocalActions([ 'activeMetric', 'detailedMetrics', 'metrics' ])
    }
}
</script>
