import { content } from '@/api'

import { defineStore } from 'pinia'

export default settings => defineStore(settings.id, {
    state: () => ({
        sorting: 'date-desc',

        items: null,

        filters: null
    }),

    actions: {
        initialize(filters, sorting = null) {
            this.filters = filters

            if (sorting) {
                this.sorting = sorting
            }

            this.loadItems()
        },

        setSorting(sorting) {
            this.sorting = sorting
            this.items = null
            this.loadItems()
        },

        loadItems() {
            return content()
                .filters(this.filters.toPerspective())
                .sorting(this.sorting)
                .limit(25)
                .get(content => {
                    this.items = content
                })
        }
    }
})
