import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyPerspectivesStore from '@/stores/me/perspectives'

export const useContentModalsPerspectiveEditStore = defineForm({
    id: 'contentModalsPerspectiveEdit',

    inputs: () => ({
        perspective: {},
        name: null,
        description: null,
        avatar: {},
        filters: null,
        sortingOption: null,
        sortingDirection: null,
        layout: null
    }),

    state: () => ({
        stream: null
    }),

    submitRequest() {
        if (! this.inputs.perspective?.id) {
            trackEvent('content', 'new-perspective-saved')
        }

        let route = this.inputs.perspective && this.inputs.perspective.id
            ? [ 'me perspectives update', { id: this.inputs.perspective.id } ]
            : [ 'me perspectives store' ]

        return api.route(...route)
            .json({
                _method: this.inputs.perspective && this.inputs.perspective.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                },
                filters: this.inputs.filters,
                sorting: this.inputs.sortingOption,
                layout: this.inputs.layout
            })
            .post()
    },

    async onResponse(res) {
        if (this.inputs.perspective?.id) Object.assign(this.inputs.perspective, res.data)

        await useMyPerspectivesStore().reload()

        if (this.stream) {
            this.stream.applyPerspective(res.data)
        }

        useModal().hide('content-perspective-edit')

        this.fulfillPromise()
    },

    actions: {
        open(perspective = {}, stream = null) {
            this.reset()

            this.inputs.perspective = perspective
            this.inputs.name = perspective?.name
            this.inputs.description = perspective?.description
            this.inputs.avatar = perspective?.avatar || {}

            this.inputs.filters = stream?.filters.toJson() || JSON.stringify(perspective?.filters)
            this.inputs.sortingOption = stream?.sorting.id || perspective?.meta?.sorting?.option
            this.inputs.layout = stream?.layout.id || perspective?.meta?.layout

            this.stream = stream

            useModal().show('content-perspective-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('content-perspective-edit')

            this.fulfillPromise()
        },

        async delete() {
            if (this.stream) {
                this.stream.deletePerspective()
            } else {
                await useMyPerspectivesStore().delete({ id: this.inputs.perspective.id })
            }

            useModal().hide('content-perspective-edit')

            this.fulfillPromise()
        }
    }
})

export default useContentModalsPerspectiveEditStore
