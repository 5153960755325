import useAccountSettingsStore from '@/stores/settings/account'
import useMyAppsStore from '@/stores/me/apps'

import { defineStore } from 'pinia'

export default defineStore('settingsAccountPage', {
    getters: {
        title: () => 'Settings'
    },

    actions: {
        beforeEnter() {
            useAccountSettingsStore().load()
            useMyAppsStore().initialize()
        }
    }
})
