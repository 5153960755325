import { asyncResource } from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export default defineStore('channelsChannelAudience', {
    state: () => ({
        type: 'members',
        channel: null,

        members: asyncResource({
            method: 'post',
            request: (api, store, payload) => api.route('monitor channels members', { id: store.channel.id }).json({ ...payload }),
            paginated: true,
            transform: (data) => data.map(item => ({ ...item, channel: item.publishedBy }))
        }),

        topSources: asyncResource({
            method: 'get',
            request: (api, store, payload) => api.route('monitor channels top-sources', { id: store.channel.id })
        }),

        topTargets: asyncResource({
            method: 'get',
            request: (api, store, payload) => api.route('monitor channels top-targets', { id: store.channel.id })
        }),
    }),

    getters: {
        items(state) {
            return {
                members: state.members,
                topSources: state.topSources,
                topTargets: state.topTargets
            }[state.type]
        }
    },

    actions: {
        load(channel) {
            if (this.channel?.id != channel.id) this.$reset()

            this.channel = channel
            
            this.members.fetchFirst(this)
            this.topSources.fetchFirst(this)
            this.topTargets.fetchFirst(this)
        },
        
        open(type) {
            useModal().show('channels-audience')
            this.setType(type)
        },
        
        reload(type) {
            if (type == 'members') this.members.fetchFirst(this)
            if (type == 'topSources') this.topSources.fetchFirst(this)
            if (type == 'topTargets') this.topTargets.fetchFirst(this)
        },
        
        async loadMore(infiniteScroll) {
            if (this.type === 'members') {
                if (! this.members.data?.length || ! this.members.res.sort) return infiniteScroll?.complete()

                let newItems = await this.members.fetchNext(this)

                newItems.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
            }
        },

        setType(type) {
            this.type = type
        }
    }
})
