<template>
    <div class="bg-white border border-gray-200 shadow-xs rounded-lg w-full p-4 pt-0 flex flex-col min-w-0 relative" :class="isComparing ? 'h-96' : 'h-80'">
        <div class="flex justify-between">
            <div class="flex items-start gap-x-3">
                <div class="flex flex-col justify-center min-h-0 mx-3 cursor-pointer relative pt-4 overflow-hidden" :class="{ 'pt-5 pb-5': isComparing }" v-for="metric in metrics(series[0])" :key="metric.id" @click="selectedMetric = metric.id">
                    <div v-if="selectedMetric == metric.id" class="absolute -top-0.5 left-0 h-1.5 w-full rounded-full bg-blue-700"></div>
                    <div class="flex items-center gap-x-1 font-medium" :class="{ 'text-blue-600': selectedMetric == metric.id, 'text-gray-700': selectedMetric != metric.id && ! metric.secondary, 'text-gray-500': selectedMetric != metric.id && metric.secondary, 'text-sm': ! isComparing && ! metric.secondary, 'text-xs': ! isComparing && metric.secondary }">
                        <ui-icon :name="metric.icon" :class="metric.secondary ? 'text-sm' : 'text-lg'"></ui-icon>
                        <h2>{{metric.name}}</h2>
                        <ui-icon v-if="metric.id == 'impact'" name="flask" class="text-lg text-gerulata-blue-500 group-hover:text-gerulata-blue-100 transition ml-1" v-tooltip="'Experimental Feature'"></ui-icon>
                    </div>
                    <h1 class="font-semibold" :class="metric.secondary ? 'text-xl text-gray-700' : 'text-3xl'" v-if="! isComparing">
                        {{$number(metric.current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous && metric.previous != metric.current && ! isComparing">
                        <div class="text-sm border rounded-sm leading-tight whitespace-nowrap px-1" :class="{ 'bg-green-100 border-green-300': metric.previous <= metric.current, 'bg-red-100 border-red-300': metric.previous > metric.current, 'text-sm': ! metric.secondary, 'text-xs': metric.secondary }">
                            <span :class="metric.previous <= metric.current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{metric.change}}%
                            </span>
                        </div>
                        <div :class="metric.secondary ? 'text-xs' : 'text-sm'">
                            {{$number(metric.previous)}}
                        </div>
                   </div>
                </div>
            </div>

            <div class="flex items-top gap-x-2 pt-4">
                <ui-dropdown width="w-auto">
                    <template v-slot:trigger>
                        <a href="#" class="font-medium text-gray-700">
                            More...
                        </a>
                    </template>
                    <template v-slot:content>
                        <div class="flex">
                            <div v-for="series, index in series" :key="index" class="w-64 divide-y divide-gray-200">
                                <div v-if="moreMetrics(series).length" class="px-4 py-2">
                                    <div v-for="metric in moreMetrics(series)" :key="metric.id" class="flex items-center py-1">
                                        <div class="flex-1 flex items-center gap-x-1.5 font-medium text-gray-700 text-lg">
                                            <ui-icon :name="metric.icon" class="text-xl"></ui-icon>
                                            <h2>{{metric.name}}</h2>
                                        </div>
                                        <div class="flex items-center gap-x-2">
                                            <h1 class="text-xl font-semibold">
                                                {{$number(metric.current)}}
                                            </h1>
                                            <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous && ! isComparing">
                                                <div class="text-xs border rounded-sm leading-tight whitespace-nowrap px-1 cursor-default" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                                                    <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'" v-tooltip="`Previously ${$number(metric.previous)}`">
                                                        <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                                        {{metric.change}}%
                                                    </span>
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="platform in platformMetrics(series)" :key="platform.id" class="px-4 py-2">
                                    <div class="flex items-center justify-center gap-x-1 font-medium text-gray-700 text-xs">
                                        <ui-icon :name="platform.icon"></ui-icon>
                                        {{platform.name}}
                                    </div>
                                    <div v-for="metric in platform.metrics" :key="metric.id" class="flex items-center py-1">
                                        <div class="flex-1 flex items-center gap-x-1.5 font-medium text-gray-700 text-sm">
                                            <ui-icon :name="metric.icon" :as-image="platform.id == 'facebook'" class="text-xl"></ui-icon>
                                            <h2>{{metric.name}}</h2>
                                        </div>
                                        <div class="flex items-center gap-x-2">
                                            <h1 class="font-semibold">
                                                {{$number(metric.current)}}
                                            </h1>
                                            <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous">
                                                <div class="text-xs border rounded-sm leading-tight whitespace-nowrap px-1 py-0.5 cursor-default" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'" v-tooltip="`${metric.previous < metric.current ? '↑' : '↓'} ${metric.change}%, previously ${$number(metric.previous)}`">
                                                    <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'">
                                                        <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                                    </span>
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>

                <ui-data-quality-icon :quality="activeMetric(series[0]).dataQuality"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex-1 min-h-0">
            <ui-analysis :store="store"></ui-analysis>
        </div>

        <div v-if="isComparing" class="flex items-start gap-x-3">
            <div class="flex px-3 pt-6 pb-2 gap-x-2" v-for="series, index in series" :key="index">
                <div class="w-5 h-1 rounded-sm mt-2" :style="{ 'background-color': series.color }"></div>
                <div class="flex flex-col justify-center min-h-0">
                    <div class="flex items-center gap-x-1 font-medium text-gray-700">
                        <h2 class="text-sm">
                            {{series.name}}
                        </h2>
                    </div>
                    <h1 class="text-3xl font-semibold">
                        {{$number(activeMetric(series).current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="activeMetric(series).previous && activeMetric(series).current != activeMetric(series).previous">
                        <div class="text-sm border rounded-sm leading-tight whitespace-nowrap px-1" :class="activeMetric(series).previous < activeMetric(series).current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="activeMetric(series).previous < activeMetric(series).current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="activeMetric(series).previous < activeMetric(series).current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{activeMetric(series).change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(activeMetric(series).previous)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalActions, mapLocalState, mapLocalWritableState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'chartOptions', 'isComparing', 'isInitialized', 'isLoading', 'series' ]),
        ...mapLocalWritableState([ 'selectedMetric' ])
    },

    methods: {
        ...mapLocalActions([ 'activeMetric', 'metrics', 'moreMetrics', 'platformMetrics' ])
    }
}
</script>
