<template>
    <div>
        <ui-dropdown-link icon="folder" :submenu="true" :submenu-align="align" submenu-width="w-56">
            Lists...

            <template v-slot:submenu>
                <div class="px-2 py-1">
                    <div class="relative w-full h-7">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-xs bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-hidden focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="listsSearch">
                    </div>
                </div>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-async :guards="areListsInitialized">
                    <div class="max-h-80 my-2 overflow-y-auto">
                        <ui-dropdown-link @click="isIncludedIn(list, channel) ? removeFromList(list, channel) : addToList(list, channel)" :icon="true" :selected="isIncludedIn(list, channel)" :checkbox="true" keep-open :key="list.id" v-for="list in lists">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar :item="list" class="w-4 h-4 shrink-0"></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                {{list.name}}
                            </template>
                        </ui-dropdown-link>

                        <div class="text-xs text-gray-700 text-center py-1" v-if="! lists.length">
                            No lists have been found.
                        </div>
                    </div>
                </ui-async>
            </template>
        </ui-dropdown-link>
    </div>
</template>

<script>
import textSearch from '@/helpers/text-search'

import useMyChannelListsStore from '@/stores/me/channel-lists'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'channel', 'list', 'align' ],

    data: () => ({
        listsSearch: ''
    }),

    computed: {
        ...mapState(useMyChannelListsStore, {
            areListsInitialized: 'isInitialized',

            lists(store) {
                let lists = this.listsSearch ? textSearch(this.listsSearch, store.items, t => t.name) : store.items

                lists = lists.sort((a, b) => a.name.localeCompare(b.name))

                let applied = lists.filter(list => store.isIncludedIn(list, this.channel))
                let notApplied = lists.filter(list => ! store.isIncludedIn(list, this.channel))

                return [ ...applied, ...notApplied ]
            }
        })
    },

    methods: {
        ...mapActions(useMyChannelListsStore, {
            _addToList: 'addToList',
            _removeFromList: 'removeFromList',
            isIncludedIn: 'isIncludedIn'
        }),

        async addToList(list, channel) {
            await this._addToList(list, channel)
        },

        async removeFromList(list, channel) {
            await this._removeFromList(list, channel)
        }
    }
}
</script>
