<template>
    <search-filter name="Performance" :ids="['metrics', 'labels', 'sentiments']" :filters="filters" help-section="performance-filter" @opened="refresh">
        <template v-slot:applied>
            <div class="flex items-center space-x-2">
                <!-- Metrics -->
                <div v-if="filters.value('metrics')?.length">
                    <div v-if="filters.value('metrics').length > 1" class="inline-flex items-center leading-none space-x-1">
                        <ui-icon name="info"></ui-icon>
                        <span class="font-medium">{{ filters.value('metrics').length }} metrics</span>
                    </div>

                    <div v-else class="inline-flex items-center leading-none space-x-1">
                        <ui-icon :name="metricValues[0].icon"></ui-icon>

                        <span class="font-medium" v-if="! filters.value('metrics')[0].gte">&lt; {{filters.value('metrics')[0].lte}}</span>
                        <span class="font-medium" v-else-if="! filters.value('metrics')[0].lte">&gt; {{filters.value('metrics')[0].gte}}</span>
                        <span class="font-medium" v-else>{{filters.value('metrics')[0].gte}} - {{filters.value('metrics')[0].lte}}</span>
                    </div>
                </div>

                <!-- Labels -->
                <div v-if="filters.value('labels')?.length" class="inline-flex space-x-1">
                    <div class="inline-flex items-center space-x-1 text-xs font-medium leading-4 bg-blue-50 py-0.5" :key="index" v-for="value, index in labelValues">
                        <span class="inline-block w-3 h-3 text-xs leading-4 rounded-full" :class="`bg-${value.color.dark}`"></span>
                        <span>{{value.title}}</span>
                    </div>
                </div>

                <!-- Sentiment -->
                <div v-if="filters.value('sentiments')?.length" class="inline-flex space-x-1">
                    <div class="inline-flex items-center space-x-1 text-xs font-medium leading-4 bg-blue-50 py-0.5" :key="index" v-for="value, index in sentimentValues">
                        <div class="flex justify-center items-center text-center" :class="value.color">
                            <ui-icon :name="value.icon"></ui-icon>
                        </div>
                        <span>{{value.name}}</span>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <!-- Metrics -->
                <template v-if="selectedMetric">
                    <label class="block mb-2 px-4 text-gray-600 text-xs font-semibold uppercase">
                        {{selectedMetricName}}
                    </label>

                    <div class="flex items-stretch px-4">
                        <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-xs placeholder-gray-400 border-gray-300 border-r-0 rounded-r-none text-center" placeholder="Min" v-model="selectedMetricMin">
                        <div class="flex items-center border-t border-b border-gray-300 bg-white text-gray-400 px-1">&ndash;</div>
                        <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-xs placeholder-gray-400 border-gray-300 border-l-0 rounded-l-none text-center" placeholder="Max" v-model="selectedMetricMax">
                    </div>

                    <div class="px-4 mt-0.5 text-xs text-red-600" v-if="! isMetricValueValid">Minimum value must be equal to or smaller than the maximum value</div>
                </template>

                <template v-else>
                    <!-- Metrics -->
                    <div v-for="metric, index in metrics" :key="index">
                        <ui-dropdown-link v-if="metric" :selected="localMetrics.some(v => v.metric.id === metric.id)" :icon="metric.icon" @click="selectedMetric = metric.id" keep-open :experimental="metric.id == 'impact'">
                            {{metric.name}}
                        </ui-dropdown-link>

                        <ui-dropdown-separator v-else class="my-2"></ui-dropdown-separator>
                    </div>

                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <!-- Labels -->
                    <!--<ui-dropdown-link checkbox :selected="localLabels.includes(label.id)" @click="toggleLocalLabel(label)" :icon="true" :key="label.id" v-for="label in labels">-->
                    <!--    <template v-slot:icon>-->
                    <!--        <div class="w-4 flex justify-center items-center text-center">-->
                    <!--            <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${label.color.dark}`"></span>-->
                    <!--        </div>-->
                    <!--    </template>-->
                    <!--    <template v-slot:default>-->
                    <!--        {{label.title}}-->
                    <!--    </template>-->
                    <!--</ui-dropdown-link>-->

                    <!--<ui-dropdown-separator class="my-2"></ui-dropdown-separator>-->

                    <!-- Sentiments -->
                    <ui-dropdown-link checkbox :selected="localSentiments.includes(sentiment.id)" @click="toggleLocalSentiment(sentiment)" :icon="true" :key="sentiment.id" v-for="sentiment in sentiments">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center" :class="sentiment.color">
                                <ui-icon :name="sentiment.icon"></ui-icon>
                            </div>
                        </template>
                        <template v-slot:default>
                            {{sentiment.name}}
                        </template>
                    </ui-dropdown-link>
                </template>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="reset(); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm" :disabled="! isApplicable">
                    Apply
                </ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyLabels from '@/stores/me/labels'

import { mapState } from 'pinia'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        metrics: [
            { name: 'Interactions', icon: 'interactions', id: 'interactions' },
            { name: 'Impact', icon: 'zap', id: 'impact' },
            null,
            { name: 'Reactions', icon: 'thumbs-up', id: 'reactions' },
            { name: 'Comments', icon: 'comment', id: 'comments' },
            { name: 'Shares', icon: 'share', id: 'shares' },
            { name: 'Views', icon: 'eye', id: 'views' }
        ],

        sentiments: [
            { name: 'Positive', id: 'positive', icon: 'face-smile', color: 'text-emerald-500' },
            { name: 'Negative', id: 'negative', icon: 'face-frown', color: 'text-rose-500' },
            { name: 'Neutral', id: 'neutral', icon: 'face-neutral', color: 'text-indigo-500' }
        ],

        selectedMetric: null,
        selectedMetricMin: null,
        selectedMetricMax: null,

        localMetrics: [],
        localLabels: [],
        localSentiments: []
    }),

    computed: {
        ...mapState(useMyLabels, [ 'labels' ]),

        isMetricValueValid() {
            return this.selectedMetricMin && this.selectedMetricMax
                ? this.selectedMetricMin <= this.selectedMetricMax
                : this.selectedMetricMin !== '' || this.selectedMetricMax !== ''
        },

        isApplicable() {
            return ! this.selectedMetric || this.isMetricValueValid
        },

        selectedMetricName() {
            return this.metrics.find(m => m?.id == this.selectedMetric).name
        },

        metricValues() {
            return (this.filters.value('metrics') || []).map(v => this.metrics.find(m => m?.id == v.metric))
        },

        labelValues() {
            return (this.filters.value('labels') || []).map(v => this.labels.find(m => m.id == v))
        },

        sentimentValues() {
            return (this.filters.value('sentiments') || []).map(v => this.sentiments.find(m => m.id == v))
        }
    },

    methods: {
        toggleLocalLabel(item) {
            this.localLabels.includes(item.id)
                ? this.localLabels.splice(this.localLabels.findIndex(v => v === item.id), 1)
                : this.localLabels.push(item.id)
        },

        toggleLocalSentiment(item) {
            this.localSentiments.includes(item.id)
                ? this.localSentiments.splice(this.localSentiments.findIndex(v => v === item.id), 1)
                : this.localSentiments.push(item.id)
        },

        reset() {
            this.filters.remove([ 'metrics', 'labels', 'sentiments' ])
        },

        refresh() {
            this.selectedMetric = this.selectedMetricMin = this.selectedMetricMax = null

            this.localMetrics = (this.filters.value('metrics') || []).map(metric => ({ ...metric }))
            this.localLabels = [ ...(this.filters.value('labels') || []) ]
            this.localSentiments = [ ...(this.filters.value('sentiments') || []) ]
        },

        apply() {
            if (this.selectedMetric) {
                this.filters.set('metrics', [{
                    metric: this.selectedMetric,
                    gte: this.selectedMetricMin !== '' ? this.selectedMetricMin : '',
                    lte: this.selectedMetricMax !== '' ? this.selectedMetricMax : ''
                }])
            }

            if (this.localLabels.length) {
                this.filters.set('labels', this.localLabels)
            } else {
                this.filters.remove('labels')
            }

            if (this.localSentiments.length) {
                this.filters.set('sentiments', this.localSentiments)
            } else {
                this.filters.remove('sentiments')
            }
        }
    }
}
</script>
