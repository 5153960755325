import useAuthSocialLogin from '@/stores/auth/social-login'

import { defineStore } from 'pinia'

export default defineStore('authSocialPage', {
    actions: {
        beforeEnter(to) {
            useAuthSocialLogin().finish(to)
        }
    }
})
