import api from '../../api'

import { defineStore } from 'pinia'
import debounce from "just-debounce-it";

export default defineStore('suggestions', {
    state: () => ({
        isLoading: false,
        suggestions: [],

        abortSuggest: null
    }),

    getters: {
        goodSuggestions: store => store.suggestions?.filter(s => s.distance >= 0.6) || []
    },

    actions: {
        suggest: debounce(function (word, language) {
            if (this.abortSuggest) { this.abortSuggest.abort() }

            this.isLoading = true

            api.route('services suggestions', { query: word, language })
                .signal(this.abortSuggest = new AbortController())
                .get()
                .error(500, err => {
                    // Make sure we keep going even if suggestions API is down
                })
                .json(res => {
                    this.suggestions = res
                    this.isLoading = false
                })
        }, 200)
    }
})
