<template>
    <div>
        <board-header></board-header>

        <div class="px-6 xl:px-8 pb-8 w-full mx-auto" :class="{ 'max-w-7xl': centered, 'max-w-12xl': ! centered }">
            <div class="h-8 flex items-center" v-if="! isShowingWelcomeMessage">
                <overview-date-filter :filters="filters"></overview-date-filter>

                <!--<a href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-3 ml-4">-->
                <!--    <ui-icon name="download" class="text-xl"></ui-icon> Export-->
                <!--</a>-->

                <a @click.prevent="toggleFullscreenMode()" href="#" class="flex items-center justify-center h-full font-medium gap-x-1.5 px-3 ml-4" :class="fullscreenMode ? 'text-blue-600' : 'text-gray-700 hover:text-gray-900'">
                    <ui-icon name="maximize-2" class="text-xl"></ui-icon> Full-screen
                </a>

                <div class="ml-auto"></div>

                <div class="flex items-center text-sm">
                    <ui-spinner v-if="isRefreshing" type="clip" class="mr-2"></ui-spinner>
                    <span>Last updated at <span class="font-medium">{{ $dateTime(lastRefresh || new Date(), "H:mm:ss") }}</span></span>
                </div>
            </div>

            <ui-async :guards="contents">
                <grid-layout
                    class="-ml-[24px] w-[calc(100%+48px)]"
                    v-model:layout="contents"
                    :col-num="4"
                    :row-height="160"
                    :is-draggable="isEditing"
                    :is-resizable="isEditing"
                    :is-mirrored="false"
                    :vertical-compact="false"
                    :margin="[24, 24]"
                    :use-css-transforms="true"
                    @layout-ready="layoutUpdated"
                    @layout-updated="layoutUpdated"
                    ref="gridLayout"
                    v-if="contents && contents.length">
                    <grid-item
                        v-for="widget in contents" :x="widget.x" :y="widget.y" :w="widget.w" :h="widget.h" :min-w="widget.type.minW" :min-h="widget.type.minH" :i="widget.i" :key="widget.i">

                        <analysis-widget :store="widget" :dashboard-store="dashboardsBoardStore" v-if="widget.type.id == 'analysis'"></analysis-widget>
                        <stream-widget :store="widget" :dashboard-store="dashboardsBoardStore" v-else-if="widget.type.id == 'stream'"></stream-widget>
                        <metrics-widget :store="widget" :dashboard-store="dashboardsBoardStore" :editable="true" v-else-if="widget.type.id == 'metrics'"></metrics-widget>

                    </grid-item>
                </grid-layout>
            </ui-async>

            <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
                <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                    <ui-icon name="navigation.dashboards" class="text-6xl text-gray-400"></ui-icon>
                    <h1 class="text-3xl font-semibold mt-6">Welcome to your new Board!</h1>
                    <div class="space-y-4 my-6">
                        <div class="flex items-center space-x-3">
                            <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                            <p class="text-justify my-2"><span class="font-medium">Add widgets</span> like Analyses, Streams and Metrics from the bottom toolbar.</p>
                        </div>
                        <div class="flex items-center space-x-3">
                            <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                            <p class="text-justify my-2"><span class="font-medium">Drag the widgets</span> to change their position or drag the bottom-right corner to resize the widget.</p>
                        </div>
                        <div class="flex items-center space-x-3">
                            <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                            <p class="text-justify my-2"><span class="font-medium">Interact with the widgets</span> after saving your dashboard. All widgets show data in the selected date range.</p>
                        </div>
                    </div>
                </div>
            </div>

            <board-add-widget v-if="isEditing"></board-add-widget>
        </div>

        <Teleport to="#root">
            <board-edit-modal v-if="isEditing"></board-edit-modal>
        </Teleport>
    </div>
</template>

<script>
import BoardHeader from './board-header'
import BoardAddWidget from './board-add-widget'
import BoardEditModal from './modals/board-edit'
import AnalysisWidget from './widgets/analysis'
import StreamWidget from './widgets/stream'
import MetricsWidget from './widgets/metrics'

import OverviewDateFilter from '@/components/content/perspective/toolbar/date'

import installVueGrid from 'vue-grid-layout'
let vueGrid = { component(name, component) { this[name] = component } }
installVueGrid(vueGrid)

import useBoardStore from '@/stores/dashboards/board'

import {mapActions, mapState, mapStores, mapWritableState} from 'pinia'

export default {
    components: {
        BoardHeader,
        BoardAddWidget,
        BoardEditModal,
        GridLayout: vueGrid['grid-layout'],
        GridItem: vueGrid['grid-item'],
        AnalysisWidget,
        StreamWidget,
        OverviewDateFilter,
        MetricsWidget
    },

    data: () => ({
        centered: false
    }),

    computed: {
        ...mapWritableState(useBoardStore, [
            'contents'
        ]),

        ...mapState(useBoardStore, [
            'board',
            'contents',
            'filters',
            'fullscreenMode',
            'isEditing',
            'lastRefresh',
            'isRefreshing'
        ]),

        ...mapStores(useBoardStore),

        isShowingWelcomeMessage() {
            return this.contents && ! this.contents.length
        }
    },

    methods: {
        ...mapActions(useBoardStore, [ 'edit', 'toggleFullscreenMode' ]),

        layoutUpdated() { useBoardStore().layoutUpdated() }
    }
}
</script>
