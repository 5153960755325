<template>
    <div class="bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md p-2 mb-2 max-w-80 shadow-xs" v-if="nodeStyle == 'color' && nodeColorDomain">
        <div class="flex justify-between">
            <div class="mb-2">Color by:</div>
            <div class="capitalize ml-2 font-bold">{{ attribute }}</div>
        </div>

        <div v-if="nodeColorByNumeric">
            <img src="@/assets/cool.png" alt="" class="w-64 h-auto">
            <div class="flex justify-between mt-2">
                <div v-if="nodeColorDomain[0] !== Number.MAX_VALUE">{{ nodeColorDomain[0] % 1 === 0 ? $number(nodeColorDomain[0]) : $number(nodeColorDomain[0], ".4g") }}</div>
                <div v-else>0</div>

                <div v-if="nodeColorDomain[1] !== Number.MIN_VALUE">{{ nodeColorDomain[1] % 1 === 0 ? $number(nodeColorDomain[1]) : $number(nodeColorDomain[1], ".4g") }}</div>
                <div v-else>0</div>
            </div>
        </div>

        <div v-else>
            <div v-for="value in nodeColorDomain" :key="value" class="mr-2 inline-block flex mb-1">
                <div class="w-5 h-5 rounded-full" :style="{ 'background-color': nodeColorFunction(value) }"></div>
                <div class="ml-2">{{ value }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "pinia"
import useGraphStyleStore from "@/stores/connections/graph-style"
import useConnectionsDataStore from "@/stores/connections/connections-data"

export default {

    computed: {
        ...mapState(useGraphStyleStore, [
            'nodeStyle', 'nodeColorAttr', 'nodeColorByNumeric', 'nodeColorDomain', 'nodeColorFunction'
        ]),

        ...mapState(useConnectionsDataStore, [ 'statsSuffixes' ]),

        attribute() {
            const suffix = this.statsSuffixes.find(e => this.nodeColorAttr.endsWith(e))

            if (suffix) {
                return suffix.replaceAll('_', ' ')
            }

            return this.nodeSizeByAttr
        }
    }
}
</script>
