import { asyncResource, content } from '@/api'
import { date } from '@/helpers/datetime'
import number from '@/helpers/number'

import { defineStore } from 'pinia'

export default defineStore('alertsTriggerSpikeDetection', {
    state: () => ({
        trigger: null,

        content: asyncResource({
            method: 'post',
            request: (api, store, payload) => content()
                .ids(store.trigger.meta.content)
                .query(payload)
                .sorting('date-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date)
    }),

    getters: {
        analysisOptions: store => ({
            chart: {
                backgroundColor: false,
                type: 'line',
                zoomType: 'xy',
                resetZoomButton: { position: { x: -40, y: -5 } },
                spacing: [0, 0, 0, 0]
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: '',
            },
            plotOptions: {
                series: {
                    lineWidth: 3,
                    marker: { enabled: false }
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [
                {
                    id: 'values',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent'
                }
            ],
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () {
                    return`${date(this.x)}<br/><strong>${number(this.y)}</strong><br/>`
                },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' }
            },
            legend: {
                enabled: false
            },
            series: [{
                color: '#2172f0',
                data: store.trigger?.meta.snapshots.map(snapshot => ({
                    x: Date.parse(snapshot.date),
                    y: parseInt(snapshot[store.trigger?.meta.metric])
                })),
                name: 'Followers',
                type: 'spline',
                yAxis: 0
            }],
            credits: {
                enabled: false
            }
        })
    },

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.content.reset()
            this.content.fetchFirst(this)
        },

        async loadMore(infiniteScroll) {
            if (this.content.isFetching) return infiniteScroll?.loaded()

            let items = await this.content.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})
