<template>
    <div class="relative flex-1" @keydown.esc="$page.isSearchFocused = false">
        <div class="absolute top-0 left-4 h-10 flex items-center pointer-events-none z-40">
            <ui-icon name="search" class="text-xl" :class="{ 'text-gray-700': ! $page.isSearchFocused, 'text-blue-600': $page.isSearchFocused }"></ui-icon>

            <div class="text-gray-700 ml-2" v-if="! $page.isSearchFocused && ! store.search">
                Enter a search query...
            </div>
        </div>

        <search-input
            ref="searchInput"
            v-model="store.search"
            @focus="() => $page.isSearchFocused = true"
            :allow-multi-line="false"
            :allow-highlighting="false"
            :allow-suggesting-topics="false"
            :allow-creating-topics="false"
            :allow-editing-topics="false"
            :allow-unwrapping-topics="false"
            :is-focused="$page.isSearchFocused"
        ></search-input>

        <audio v-if="audio" ref="audio" :src="audio"></audio>

        <div class="absolute top-0 right-4 h-10 flex items-center space-x-2 z-40">
            <a href="#" class="flex text-2xl text-blue-600 hover:text-blue-900" @click.prevent="store.clearSearch()" v-tooltip="'Clear'" v-if="$page.isSearchFocused && store.search.query">
                <ui-icon name="x"></ui-icon>
            </a>
        </div>
    </div>
</template>

<script>
import SearchInput from './search-input-input'

import useSupportHelpStore from '@/stores/support/user-guide.js'
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import { mapActions } from 'pinia'

export default {
    data: () => ({
        audio: null
    }),

    components: {
        SearchInput
    },

    methods: {
        ...mapActions(useModalsSearchHistoryStore, { openSearchHistoryModal: 'open' }),
        ...mapActions(useSupportHelpStore, { showHelp: 'show' })
    },

    watch: {
        'store.search'(newVal) {
            this.store.load()
        }
    }
}
</script>
