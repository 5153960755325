import useSupportUserGuideStore from '@/stores/support/user-guide'
import useSupportWhatsNewStore from '@/stores/support/whats-new'

import { defineStore } from 'pinia'

export default defineStore('helpWhatsNewPage', {
    getters: {
        title: store => useSupportWhatsNewStore().title ? `${useSupportWhatsNewStore().title} | Help` : 'Help'
    },

    actions: {
        async afterEnter(to) {
            useSupportUserGuideStore().initialize()
            useSupportWhatsNewStore().initialize()

            useSupportWhatsNewStore().loadRelease(to.params.release)
        }
    }
})
