import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('featuresFeatureConnections', {
    state: () => ({
        feature: null,

        coexistingFeatures: { data: null, error: null },
        connectedChannels: { data: null, error: null },

        selectedType: 'connected-channels'
    }),

    actions: {
        load(feature, force = false) {
            if (force) this.$reset()
            if (this.feature?.id != feature.id) this.$reset()

            this.feature = feature

            this.fetchData('coexisting-features', 'coexistingFeatures')
            this.fetchData('connected-channels', 'connectedChannels')
        },

        reload() {
            this.load(this.feature, true)
        },

        fetchData(connectionType, resultKey) {
            return api.route(`monitor features connections`, { id: this.feature.id, type: connectionType }).get()
                .error(500, err => this[resultKey] = { data: null, error: err?.json?.message || 'unknown' })
                .json(res => this[resultKey] = { data: res.data, error: null })
        }
    }
})
