import { defineStore } from 'pinia'
import dagre from 'dagre'
import api from '@/api'
import { useVueFlow, Position } from '@vue-flow/core'
import { useModal } from "@/helpers.js"

const { findNode, setCenter, fitView } = useVueFlow({ id: 'modal-explain-edge' })

export default defineStore('connectionsModalsEdgeExplain', {
    state: () => ({
        isLoading: false,

        inspectedNodeIds: [],
        node_ids: new Set(),
        edge_ids: new Set(),

        nodes: [],
        edges: [],

        selectedNode: null,

        dagreGraph: new dagre.graphlib.Graph(),
        transform: [ 0, 0, 1 ],

        fromNodeLabel: '',
        toNodeLabel: '',

        edgeLabel: '',
        edgeWeight: 0,

        fromNodeId: null,
        toNodeId: null,
    }),

    actions: {
        open(ids, edge, node1, node2) {
            this.$reset()

            useModal().show('edge-explain')

            this.fromNodeLabel = node1.label
            this.toNodeLabel = node2.label

            this.edgeLabel = edge.label
            this.edgeWeight = edge.weight

            this.fromNodeId = ids[0]
            this.toNodeId = ids[1]

            this.getEdgeExplain(this.fromNodeId, this.toNodeId, edge.edge_type)
        },

        async getEdgeExplain(source, target, type) {
            this.isLoading = true

            await api.route('connections edge', true)
                .json({ source, target, type })
                .post()
                .json(data => {
                    const graph = this.processGraphData(data.subgraph)

                    this.nodes = [ ...graph.nodes ]
                    this.edges = [ ...graph.edges ]

                    setTimeout(() => { fitView() }, 100)

                    this.isLoading = false
                });
        },

        processGraphData(graph) {
            const nodes = []
            const edges = []

            graph.nodes.forEach(node => {
                if (this.node_ids.has(node.key)) {
                    return
                }

                this.node_ids.add(node.key)

                const attrs = node.attributes

                nodes.push({
                    id: node.key,
                    type: attrs.class.toLowerCase(),
                    data: { ...attrs, is_origin: this.inspectedNodeIds.includes(node.key) },
                    position: { x: 0, y: 0 },
                    class: 'dark',
                })
            })

            graph.edges.forEach(edge => {
                let sourceHandle = 'right-source'
                let targetHandle = "left-target"

                let target = edge.target
                let source = edge.source

                edges.push({
                    type: "custom",
                    color: 'black',
                    id: edge.key,
                    target: target,
                    source: source,
                    label: edge.attributes.label,
                    sourceHandle: sourceHandle,
                    targetHandle: targetHandle
                })
            })

            return { nodes, edges }
        },

        handleLayout() {
            const dagreGraph = new dagre.graphlib.Graph()
            dagreGraph.setDefaultEdgeLabel(() => ({}))

            dagreGraph.setGraph({
                rankdir: 'LR',
                ranksep: 300,
                ranker: 'tight-tree',
                acyclicer: 'greedy',
                marginx: 100,
                marginy: 100,
                // align: 'UL',
            })

            for (const node of this.nodes) {
                const graphNode = findNode(node.id)
                let nodeObj = {}

                if (node.position.x === 0 && node.position.y === 0) {
                    nodeObj = {
                        width: graphNode.dimensions.width + 300,
                        height: graphNode.dimensions.height + 500,
                    }
                } else {
                    nodeObj = {
                        width: graphNode.dimensions.width + 300,
                        height: graphNode.dimensions.height + 500,
                        x: node.position.x,
                        y: node.position.y,
                        // rank: this.rank,
                    }
                }

                dagreGraph.setNode(node.id, nodeObj)
            }

            for (const edge of this.edges) {
                dagreGraph.setEdge(edge.source, edge.target)
            }

            dagre.layout(dagreGraph)

            this.nodes = [ ...this.nodes.map((node) => {
                const nodeWithPosition = dagreGraph.node(node.id)
                let paddingx = 0
                let paddingy = 0

                return {
                    ...node,
                    targetPosition: Position.Left,
                    sourcePosition: Position.Right,
                    position: { x: nodeWithPosition.x + paddingx, y: nodeWithPosition.y + paddingy },
                }
            }) ]
        },

        handleNodeClick({ event, node }) {
            this.edges = this.edges.map(edge => {
                if (edge.source === node.id || edge.target === node.id) {
                    return { ...edge, selected: true }
                }
                return { ...edge, selected: false }
            })
        },

        isSelected(id) {
            return this.fromNodeId === id || this.toNodeId === id
        },

        focusNode(nodeId, duration = 500, zoom = 0.8) {
            const node = findNode(nodeId)

            if (node) {
                const x = node.position.x
                const y = node.position.y

                setCenter(x, y, { duration, zoom })
                this.selectedNode = nodeId
            }
        },

        highlightNode(nodeId) {
            this.selectedNode = nodeId
        },

        cancelHighlightedNodes() {
            this.selectedNode = null
        }
    }
})
