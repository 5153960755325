import useSettingsAccountSocialLogin from '@/stores/settings/account-social-login'

import { defineStore } from 'pinia'

export default defineStore('settingsAccountLoginSocialPage', {
    actions: {
        beforeEnter(to) {
            useSettingsAccountSocialLogin().finishConnecting(to)
        }
    }
})
