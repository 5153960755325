<template>
    <template v-if="range">
        <date-time-range v-if="type === 'datetime'" v-bind="$attrs" v-model:value="internalValue"></date-time-range>
        <calendar-range v-else v-bind="$attrs" v-model:value="internalValue"></calendar-range>
    </template>

    <template v-else>
        <date-time v-if="type === 'datetime'" v-bind="$attrs" v-model:value="internalValue"></date-time>
        <calendar v-else v-bind="$attrs" v-model:value="internalValue"></calendar>
    </template>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'

const Calendar = DatePicker.Calendar
const CalendarRange = DatePicker.CalendarRange
const DateTime = DatePicker.DateTime
const DateTimeRange = DatePicker.DateTimeRange

import { UTCToZoned, zonedToUTC } from '@/helpers/datetime'

export default {
    components: { Calendar, CalendarRange, DateTime, DateTimeRange },

    props: { 'type': {}, range: { type: Boolean, default: false }, 'modelValue': {} },

    computed: {
        internalValue: {
            get() {
                return Array.isArray(this.modelValue)
                    ? this.modelValue.map((v) => UTCToZoned(v))
                    : (this.modelValue ? UTCToZoned(this.modelValue) : this.modelValue)
            },
            set(val) {
                this.$emit('update:modelValue', Array.isArray(val)
                    ? val.map((v) => zonedToUTC(v))
                    : zonedToUTC(val))
            }
        }
    }
}
</script>