import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('myPerspectives', {
    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me perspectives').query({ type: 'juno' }).get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(perspective, data) {
            await api.route('me perspectives update', perspective.id)
                .formData({ _method: 'put', ...data })
                .post()
                .json(res => Object.assign(perspective, res.data))

            return this.reload()
        },

        async delete(perspective) {
            return useDeleteConfirmationModal().open('Perspective', perspective, 'me perspectives used-by').then(async () => {
                await api.route('me perspectives delete', perspective.id).delete().res()
                await this.reload()
            })
        },

        async duplicate(perspective) {
            await api.route('me perspectives store')
                .formData({
                    name: perspective.name,
                    filters: JSON.stringify(perspective.filters),
                    topicId: perspective.topicId
                })
                .post()
                .json()

            return this.reload()
        }
    }
})
