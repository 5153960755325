import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export default defineStore('channelsModalsChannelDetailsText', {
    state: () => ({
        label: null,
        value: null
    }),

    actions: {
        open(label, value) {
            this.$reset()
            
            this.label = label
            this.value = value

            useModal().show('channel-details-text')
        },

        cancel() {
            useModal().hide('channel-details-text')
        }
    }
})
