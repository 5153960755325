<template>
    <div>
        <div class="bg-white w-full shadow-sm rounded-md mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="! store.series.length">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Content Publish Times" analysis!</h1>
            <p class="text-lg my-1">This analysis allows you to <span class="font-medium">visualize the posting activity by day or hour</span> using a heatmap.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data-set</h2>
                    <p class="text-justify my-2">Select a Perspective, Topic or Channel List as a data-set.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Analysis Styles</h2>
                    <p class="text-justify my-2">Customize how the analysis looks by choosing form multiple analysis types like daily or hourly analysis and other options.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range</h2>
                    <p class="text-justify my-2">Use the range controls to specify the shown date range. Hover over the heatmap boxes to see the exact date.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Select Data-set button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => store.changeDataset()">
                    Select Data-set
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-dataset :store="store"></settings-dataset>
                    <settings-heatmap-type :store="store"></settings-heatmap-type>
                </template>
            </analysis-toolbar>

            <div class="px-4 py-14 bg-white border border-gray-200 rounded-lg shadow-xs relative">
                <daily-heatmap :store="store.heatmapOptions" v-if="store.selectedHeatmapType.id == 'daily'"></daily-heatmap>
                <hourly-heatmap :store="store.heatmapOptions" v-if="store.selectedHeatmapType.id == 'hourly'"></hourly-heatmap>
            </div>
        </div>
    </div>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import DailyHeatmap from './heatmaps/daily'
import HourlyHeatmap from './heatmaps/hourly'
import SettingsDataset from './settings/dataset'
import SettingsHeatmapType from './settings/heatmap-type'

export default {
    components: {
        AnalysisToolbar, DailyHeatmap, HourlyHeatmap, SettingsDataset, SettingsHeatmapType
    }
}
</script>
