<template>
    <ui-dropdown width="w-56" @opened="loadStreamTags(store)" @closed="resetStreamTags(store)">
        <template v-slot:trigger>
            <a href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-3">
                <ui-icon name="tags" class="text-xl"></ui-icon> Tagging
            </a>
        </template>
        <template v-slot:content>
            <ui-dropdown-link icon="ai" @click="addSmartTag(store.filters, store.sorting)">Add Smart Tag...</ui-dropdown-link>
            <ui-dropdown-link icon="check-circle" @click="openReviewOverlay(store)">Review Mode...</ui-dropdown-link>

            <ui-dropdown-separator class="mb-3"></ui-dropdown-separator>

            <tags-dropdown-content :stream="store" v-if="isBulkTaggingAvailable"></tags-dropdown-content>

            <template v-else>
                <div class="text-xs text-center text-gray-600 p-4 pt-1 pb-3">
                    Bulk Tagging is only available with less than 10 000 results.
                </div>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link icon="settings" @click="manageTags">
                    Manage Tags
                </ui-dropdown-link>
            </template>
        </template>
    </ui-dropdown>
</template>

<script>
import TagsDropdownContent from '@/components/me/tags/contextual-menu-content'

import useContentModalsReviewOverlayStore from '@/stores/content/modals/review-overlay'
import useContentModalsSmartTagStore from '@/stores/content/modals/smart-tag'
import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'
import useTaggingStore from '@/stores/tagging/tagging'

import { mapActions } from 'pinia'

export default {
    components: {
        TagsDropdownContent
    },

    computed: {
        isBulkTaggingAvailable() {
            return this.store.itemsCount > 0 && this.store.itemsCount < 10000
        }
    },
    
    methods: {
        ...mapActions(useContentModalsReviewOverlayStore, { openReviewOverlay: 'open' }),
        ...mapActions(useContentModalsSmartTagStore, { addSmartTag: 'open' }),
        ...mapActions(useMyModalsTagsManageStore, { manageTags: 'open' }),
        ...mapActions(useTaggingStore, [ 'loadStreamTags', 'resetStreamTags' ])
    }
}
</script>
