<template>
    <div class="flex items-center justify-center gap-x-1.5 text-3xl font-semibold text-center mb-1">
        {{trigger.meta.channels.length}} new publishers in
        <span class="flex items-center gap-x-1">
            <ui-avatar :item="trigger.meta.subject" class="w-7 h-7"></ui-avatar> {{trigger.meta.subject.name}}
        </span>
    </div>

    <div class="flex items-center justify-center gap-x-1.5 mb-10 text-gray-700">
        {{$dateTime(trigger.createdAt)}}
    </div>

    <p class="mb-2"><span class="font-semibold">{{trigger.meta.channels.length}} new publishers</span> above <span class="font-semibold">{{trigger.alert.settings.followersThreshold}} followers</span> have published content.</p>

    <ui-async :guards="channels.data" class="space-y-6 max-w-2xl mx-auto --channels">
        <channel-card v-for="channel in channels.data" :channel="channel" :key="channel.id"></channel-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event, 'channels')"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-60">
                <ui-spinner></ui-spinner>
            </div>
        </template>
    </ui-async>

    <h2 class="text-center font-semibold mt-12 mb-4">Sample of published content</h2>

    <ui-async :guards="content.data" class="space-y-6 max-w-2xl mx-auto">
        <content-card v-for="content in content.data" :content="content" :stream-store="streamStore" :key="content.id"></content-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event, 'content')"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-60">
                <ui-spinner></ui-spinner>
            </div>
        </template>
    </ui-async>
</template>

<script>
import ChannelCard from '@/components/channels/stream-cards/channel'
import ContentCard from '@/components/content/stream-cards/content'

import useTriggerStore from '@/stores/alerts/trigger'
import useTriggerNewPublishersStore from '@/stores/alerts/trigger-new-publishers'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ChannelCard, ContentCard
    },

    computed: {
        ...mapState(useTriggerStore, [ 'streamStore' ]),
        ...mapState(useTriggerNewPublishersStore, [ 'analysisOptions', 'channels', 'content', 'lastReloadTime', 'trigger' ])
    },

    methods: {
        ...mapActions(useTriggerNewPublishersStore, [ 'loadMore' ])
    }
}
</script>
