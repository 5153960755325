<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-lg w-full space-y-8">
            <div v-if="$page.submitted">
                <img src="@/assets/gerulata.png" alt="Gerulata" class="w-32 h-auto mx-auto">
                <h2 class="mt-4 text-center text-4xl font-bold text-gray-900">
                    Please check your e-mail.
                </h2>
                <p class="mt-2 text-center text-gray-700">
                    If the e-mail address you've entered is connected to a Gerulata Platform account, you will receive an e-mail with password reset link shortly.
                </p>
            </div>

            <template v-else>
                <div>
                    <img src="@/assets/gerulata.png" alt="Gerulata" class="w-32 h-auto mx-auto">
                    <h2 class="mt-4 text-center text-4xl font-bold text-gray-900">
                        Forgot your password?
                    </h2>
                    <p class="mt-2 text-center text-gray-700">
                        Please enter your e-mail address to request a password reset link.
                    </p>
                </div>
                <ui-form :store="authPasswordRecoveryRequestFormStore" v-slot="{ inputs, submitting, errors }" class="max-w-md mx-auto mt-6 space-y-6">
                    <div class="border border-red-300 bg-red-50 text-red-900 w-full px-4 py-2 text-sm mt-3 md:mt-5 rounded-sm" v-if="errors && errors.other">
                        <p :key="error" v-for="error in errors.other">{{ error }}</p>
                    </div>

                    <div class="rounded-md shadow-xs -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">Email address</label>
                            <ui-input type="email" autocomplete="email" required placeholder="Email address" v-model="inputs.email" :errors="errors.email"></ui-input>
                        </div>
                    </div>

                    <div class="text-center">
                        <button type="submit" class="inline-flex items-center justify-center py-3 px-20 rounded-md shadow-xs bg-gerulata-blue-600 font-medium text-gerulata-blue-50 hover:bg-gerulata-blue-700 shadow-md disabled:opacity-50" :disabled="submitting">
                            Request Password Reset
                        </button>
                    </div>
                </ui-form>
            </template>
        </div>
    </div>
</template>

<script>
import useAuthPasswordRecoveryRequestFormStore from '@/stores/auth/password-recovery-request-form'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAuthPasswordRecoveryRequestFormStore)
    }
}
</script>
