<template>
    <div class="absolute bottom-4 inset-x-0 flex justify-center z-50">
        <div class="bg-gray-900/90 border border-gray-800 backdrop-blur-xs text-white shadow-xl rounded-lg divide-x divide-gray-700 flex">
            <div class="p-1">
                <a href="#" class="px-2 py-1 rounded-md cursor-pointer flex items-center hover:bg-gray-700" @click.prevent="addWidget('analysis')">
                    <ui-icon class="mr-2" name="plus-circle"></ui-icon>
                    <span>Analysis</span>
                </a>
            </div>

            <div class="p-1">
                <a href="#" class="px-2 py-1 rounded-md cursor-pointer flex items-center hover:bg-gray-700" @click.prevent="addWidget('stream')">
                    <ui-icon class="mr-2" name="stream"></ui-icon>
                    <span>Stream</span>
                </a>
            </div>

            <div class="p-1">
                <a href="#" class="px-2 py-1 rounded-md cursor-pointer flex items-center hover:bg-gray-700" @click.prevent="addWidget('metrics')">
                    <ui-icon class="mr-2" name="text-word"></ui-icon>
                    <span>Metrics</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import useBoardStore from '@/stores/dashboards/board'
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'size' ],

    computed: {
        ...mapState(useBoardStore, [ 'board', 'widgetTypes' ])
    },

    methods: {
        ...mapActions(useBoardStore, [ 'addWidget' ]),
        ...mapActions(useModalsBoardEditStore, { editBoard: 'open' })
    }
}
</script>
