import useSupportUserGuideStore from '@/stores/support/user-guide'
import useSupportWhatsNewStore from '@/stores/support/whats-new'

import { defineStore } from 'pinia'

export default defineStore('helpUserGuidePage', {
    getters: {
        title: store => useSupportUserGuideStore().title ? `${useSupportUserGuideStore().title} | Help` : 'Help'
    },

    actions: {
        async afterEnter(to) {
            useSupportUserGuideStore().initialize()
            useSupportWhatsNewStore().initialize()

            useSupportUserGuideStore().show(to.params.page || 'introduction', to.params.section)
        }
    }
})
