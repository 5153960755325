<template>
    <component :is="store.inline ? 'div' : 'ui-modal'" :class="store.inline ? 'bg-white flex rounded-lg overflow-hidden h-screen-4/6' : ''" unstyled :name="store.name" :adaptive="true" height="90%" :max-width="'90%'" @opened="opened" @closed="closed" @keydown="keyPressed">
        <div v-if="isOpen || store.inline" class="bg-white flex rounded-lg overflow-hidden h-full w-full">
            <div class="flex-1 bg-gray-900 outline-hidden rounded-l-md group relative" :class="{ 'w-screen': isUnknownOrientation }" tabindex="-1" ref="focusable">
                <div class="h-full pl-5 w-40 absolute left-0 top-0 bg-linear-to-r from-[#2c404cc0] z-50 transition-opacity duration-300 easy-in-out opacity-0 group-hover:opacity-100 flex items-center justify-start" v-if="! isFirst">
                    <a @click.prevent="store.previous()" href="#" class="bg-gray-200/80 shadow-sm w-12 h-12 flex items-center justify-center rounded-full text-[26px] text-black">
                        <ui-icon name="arrow-left"></ui-icon>
                    </a>
                </div>

                <template v-if="currentMedia.mediaType == 'image'">
                    <img v-if="currentMedia.url" class="w-full h-full object-contain" :src="currentMedia.url">

                    <div v-else class="w-full h-full flex flex-col items-center justify-center bg-gray-200 text-gray-700 font-medium gap-y-1">
                        <ui-icon name="image" class="text-5xl text-gray-600"></ui-icon>
                        <div>
                            Image is processing.
                        </div>
                        <a href="#" @click.prevent="store.loadCurrentMediaDetails()" class="text-gray-600 hover:text-gray-800">
                            <ui-icon name="refresh-cw"></ui-icon> Reload
                        </a>
                    </div>

                    <div v-if="currentMedia.url" class="absolute inset-0 flex items-center justify-center">
                        <div class="relative" :style="imageOverlayStyle(imageOverlay)">
                            <div class="border-2 border-blue-500 absolute" :style="regionStyle(region)" :key="index" v-for="region, index in store.regions"></div>
                        </div>
                    </div>
                </template>

                <content-video v-if="currentMedia.mediaType == 'video'" :video="currentMedia" :content="store.content"></content-video>

                <div class="h-full pr-5 w-40 absolute right-0 top-0 bg-linear-to-l from-[#2c404cc0] z-50 transition-opacity duration-300 easy-in-out opacity-0 group-hover:opacity-100 flex items-center justify-end" v-if="! isLast">
                    <a @click.prevent="store.next()" href="#" class="bg-gray-200/80 shadow-sm w-12 h-12 flex items-center justify-center rounded-full text-[26px] text-black">
                        <ui-icon name="arrow-right"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="w-80 bg-white flex flex-col rounded-r-md" v-if="store.sidebar">
                <div class="flex items-center p-4 pr-6 pb-0" v-if="! store.inline">
                    <div class="text-sm pl-2">
                        {{ store.currentIndex + 1 }} out of {{ store.media?.length }}
                    </div>

                    <a href="#" class="ml-auto text-2xl text-gray-700 hover:text-gray-900" @click.prevent="$modal.hide(store.name)">
                        <ui-icon name="remove"></ui-icon>
                    </a>
                </div>

                <div class="flex-1 overflow-y-auto border-l border-gray-200">
                    <slot name="sidebar"></slot>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
import ContentVideo from '@/components/content/stream-cards/partials/video'

import debounce from 'just-debounce-it'

export default {
    components: { ContentVideo },

    data: () => ({
        isOpen: false,
        imageOverlay: {}
    }),

    computed: {
        currentMedia() { return this.store.currentMedia },

        isFirst() { return this.store.isFirst },

        isLast() { return this.store.isLast },

        isUnknownOrientation() {
            return ! this.currentMedia.thumbnail?.height
        }
    },

    methods: {
        opened() {
            this.isOpen = true

            if (this.currentMedia.family == 'images') {
                this.$nextTick(() => {
                    this.$refs.focusable.focus()
                })

                this.recalculateImageOverlay()
            }
        },

        closed() {
            this.open = false
            this.store.close()
        },

        keyPressed(ev) {
            if (ev.key === 'ArrowLeft') return this.store.previous()
            if (ev.key === 'ArrowRight') return this.store.next()
        },

        recalculateImageOverlay() {
            if (! this.currentMedia) return
        
            let mediaSize = {
                height: this.currentMedia.height,
                width: this.currentMedia.width
            }

            if (! this.$refs.focusable) return

            let containerSize = {
                height: this.$refs.focusable.offsetHeight,
                width: this.$refs.focusable.offsetWidth
            }

            let ratio = Math.min(containerSize.width / mediaSize.width, containerSize.height / mediaSize.height)

            this.imageOverlay = {
                height: mediaSize.height * ratio,
                width: mediaSize.width * ratio
            }
        },

        imageOverlayStyle(overlay) {
            return `width:${overlay.width}px;height:${overlay.height}px;`
        },

        regionStyle(region) {
            return `top:${region.top * 100}%;left:${region.left * 100}%;width:${region.width * 100}%;height:${region.height * 100}%`
        }
    },

    watch: {
        async currentMedia() {
            await this.$nextTick()
            this.recalculateImageOverlay()
        }
    },

    mounted() {
        window.addEventListener('resize', debounce(() => this.recalculateImageOverlay()), 50)
    }
}
</script>
