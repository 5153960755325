<template>
    <div class="bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md mb-2 p-4 px-2" v-if="nodes.length">
        <div class="flex items-center justify-between mb-2 px-2">
            <div class="font-medium">Selected nodes ({{ nodes.length }})</div>

            <ui-dropdown width="w-auto">
                <template v-slot:trigger>
                    <button aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                        <ui-icon name="more-horizontal"></ui-icon>
                    </button>
                </template>

                <template v-slot:content>
                    <div class="flex items-center text-lg font-medium cursor-default py-1 px-2 rounded-sm">
                        <div class="ml-1 text-xs">Selected actions</div>
                    </div>

                    <ui-dropdown-link icon="square" @click="store.selectNodes([], true)">Deselect</ui-dropdown-link>
                    <ui-dropdown-link icon="share" @click="store.inspectNode(store.selectedNodes)" v-if="store.selectedNodes.length < 6 && store.nodes.length < store.maxNodesLimit">Load connections for selected</ui-dropdown-link>
                    <ui-dropdown-link icon="eye" @click="store.showNode(store.selectedNodes)">Show selected</ui-dropdown-link>
                    <ui-dropdown-link icon="eye-off" @click="store.hideNode(store.selectedNodes)">Hide selected</ui-dropdown-link>
                    <ui-dropdown-link icon="hash" @click="store.freezeNode(store.selectedNodes)">Freeze selected</ui-dropdown-link>
                    <ui-dropdown-link icon="trash" @click="deleteSelected" v-if="store.selectedNodes.length <= 200">Delete selected</ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <div class="overflow-x-auto max-h-80">
            <nodes-list :nodes="nodes" :store="store"></nodes-list>
        </div>
    </div>
</template>

<script>

import { sidebar_channels, sidebar_features } from "@/components/connections/node_styles.js"
import NodesList from "@/components/connections/partials/nodes-list.vue"

export default {
    components: { NodesList },

    props: {
        store: Object,
    },

    computed: {
        nodes() {
            return this.store.nodes.map(n => {
                if (n.attributes.label) {
                    const sidebar_styles = n.attributes.class === 'Channel' ? sidebar_channels : sidebar_features
                    return {
                        id: n.key,
                        name: n.attributes.label,
                        type: n.attributes.object_type,
                        icon: sidebar_styles.find(t => t.type === n.attributes.object_type)?.icon || 'badges.feature-image-cluster'
                    }
                }
            })
            .filter(n => n && this.store.selectedNodes.includes(n.id))
        }
    },

    methods: {
        deleteSelected() {
            this.store.deleteNode(this.store.selectedNodes)
            this.store.selectNodes([], true)
        }
    }
}
</script>
