import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export default defineStore('analysisIndexPage', {
    getters: {
        title: () => 'Analysis'
    },

    actions: {
        afterEnter() {
            useMyAnalysesStore().initialize()
        }
    }
})
