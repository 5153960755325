import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('channelsMonitoredStats', {
    state: () => ({
        stats: {},

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),
    
    getters: {
        color(store) {
            let used = store.stats?.current?.monitoredChannels / store.stats?.limits?.monitoredChannels
            
            if (used > 0.9) {
                return 'red'
            } else if (used > 0.75) {
                return 'orange'
            } else {
                return 'green'
            }
        },

        monitoringOptions(store) {
            return [
                { name: 'Image Text Extraction', usage: this.stats?.current.imageTextExtraction, budget: this.stats?.limits.imageTextExtraction },
                { name: 'Video Retention', usage: this.stats?.current.videoDownload, budget: this.stats?.limits.videoDownload },
                { name: 'Video Transcription', usage: this.stats?.current.videoTranscription, budget: this.stats?.limits.videoTranscription }
            ]
        }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return api.route('me channels stats index').get().json(res => {
                this.stats = res

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        }
    }
})
