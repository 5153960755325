import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('myDashboards', {
    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me dashboards').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadDetails(id) {
            return api.route('me dashboards details', id).get().json(res => {
                Object.assign(this.find(res.data.id), res.data)
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(dashboard, data) {
            await api.route('me dashboards update', dashboard.id)
                .json({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async delete(dashboard) {
            return useDeleteConfirmationModal().open('Dashboard', dashboard).then(async () => {
                await api.route('me dashboards delete', dashboard.id).delete().res()
                await this.reload()
            })
        }
    }
})
