<template>
    <ui-contextual-menu plain @opened="opened" ref="menu">
        <ui-dropdown-link @click="() => translate(content)" :disabled="! isTranslatable" icon="translate" :spinner="content.translation?.isLoading" keep-open v-tooltip="! isTranslatable ? 'Language not supported.' : ''">
            Translate text
        </ui-dropdown-link>

        <ui-dropdown-link v-if="isDeglyphed" @click="() => loadUnmodified(content)" icon="text-case" :spinner="content.unmodified?.isLoading" keep-open>
            Show unmodified text
        </ui-dropdown-link>

<!--        <ui-dropdown-link :href="$router.resolve({ name: 'connections.content', params: { id: content.id } }).href" icon="compass" target="_blank">-->
<!--            Trace content-->
<!--        </ui-dropdown-link>-->

        <ui-dropdown-separator></ui-dropdown-separator>

        <channel-section v-if="content.publishedBy" inline icon="arrow-up-circle" name="Published by..." :channel="content.publishedBy" type="publishedBy" :stream-store="streamStore"></channel-section>
        <channel-section v-if="content.publishedInto.id != content.publishedBy?.id" inline icon="arrow-down-circle" name="Published in..." :channel="content.publishedInto" type="publishedInto" :stream-store="streamStore"></channel-section>
        <tags-dropdown :item="content"></tags-dropdown>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'content.details', params: { id: content.id } }).href" target="_blank">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="link" @click="copyLink">
            Copy link
        </ui-dropdown-link>

        <ui-dropdown-link @click="openOriginal" icon="external-link" :href="content.link" target="_blank" rel="noreferrer noopener" :disabled="! content.link" prevent>
            Open original
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <!--<ui-dropdown-link v-if="! content.alerts.length" @click="() => follow()" icon="bell">-->
        <!--    Follow...-->
        <!--</ui-dropdown-link>-->
        <!--<ui-dropdown-link v-else @click="() => unfollow()" icon="bell-off">-->
        <!--    Unfollow-->
        <!--</ui-dropdown-link>-->

        <ui-dropdown-link icon="edit" @click="() => createNote(content)">
            Add note...
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('content', content.id)">
            Report
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(content)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import TagsDropdown from '@/components/me/tags/contextual-menu'
import ChannelSection from '@/components/channels/contextual-menus/channel'

import useAlertEditStore from '@/stores/alerts/modals/alert-edit'
import useMeModalsNoteStore from '@/stores/me/modals/note'
import useMyAlertsStore from '@/stores/me/alerts'
import useSupportReportStore from '@/stores/support/report'

import { content } from '@/api'
import { loadUnmodified, removeUnmodified } from '@/helpers/deglyphing'
import { alert } from '@/helpers/modals'
import { isTranslatable, translate, removeTranslation } from '@/helpers/translations'

import { mapActions } from 'pinia'

/* eslint-disable vue/no-mutating-props */
export default {
    components: { ChannelSection, TagsDropdown },

    props: [ 'content', 'streamStore' ],

    computed: {
        isDeglyphed() {
            return this.content.labels.includes('deglyphed')
        },

        isTranslatable() {
            return isTranslatable(this.content)
        }
    },

    methods: {
        async loadUnmodified(content) {
            loadUnmodified(content).then(() => {
                removeTranslation(this.content)
                this.streamStore.triggerLayoutUpdate()
                this.$refs.menu.close()
            })
        },

        async translate(content) {
            translate(content).then(() => {
                removeUnmodified(this.content)
                this.streamStore.triggerLayoutUpdate()
                this.$refs.menu.close()
            })
        },

        follow() {
            useAlertEditStore().open(this.content.alerts.length ? this.content.alerts[0] : { type: 'follow-content', subject: this.content }, this.content, true)
        },

        async unfollow() {
            await useMyAlertsStore().delete(this.content.alerts[0])
            this.content.alerts = []
        },

        copyLink() {
            navigator.clipboard.writeText(
                window.location.origin + this.$router.resolve({ name: 'content.details', params: { id: this.content.id } }).href
            )
        },

        openOriginal() {
            alert({
                title: 'Third-party content',
                body: 'You are accessing a third-party resource, your connection information will be exposed to a third-party provider if you proceed.',
                muteable: { id: 'open-original-link', expires: 30 }
            }).then(() => {
                window.open(this.content.link, '_blank')
            })
        },

        ...mapActions(useMeModalsNoteStore, { createNote: 'create' }),
        ...mapActions(useSupportReportStore, [ 'report' ]),

        opened() {
            content().include([ 'alerts' ]).find(this.content.id, content => {
                this.content.alerts = content.alerts
            })
        }
    }
}
</script>
