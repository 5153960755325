import useAuthPasswordRecoveryResetForm from '@/stores/auth/password-recovery-reset-form'

import { defineStore } from 'pinia'

export default defineStore('authPasswordRecoveryResetPage', {
    actions: {
        beforeEnter(to) {
            useAuthPasswordRecoveryResetForm().setResetToken(to.params.token, to.query.email)
        }
    }
})
