import defineCards from '@/stores/reusable/cards'
import useMyCuratedListsStore from '@/stores/me/curated-lists'

import { sortAlphabetically } from '@/helpers/sorting'

import { defineStore } from 'pinia'

export const useIndexCardsCuratedCategoryStore = defineCards({
    id: 'channelsIndexCardsCuratedCategory',

    source: defineStore('channelsIndexCardsCuratedCategorySource', {
        state: () => ({
            params: {}
        }),

        getters: {
            items(store) {
                let category = useMyCuratedListsStore().find(store.params.id)

                return category
                    ? [ ...category.children, ...category.lists.map(l => ({ ...l, family: 'curated-list' })) ]
                    : useMyCuratedListsStore().categories
            },

            isLoading(store) {
                return useMyCuratedListsStore().isLoading
            }
        },

        actions: {
            initialize(params) {
                this.params = params
                useMyCuratedListsStore().load()
            }
        }
    }),
    layout: 'table',

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => [
                ...sortAlphabetically(items).filter(i => i.family != 'lists'),
                ...sortAlphabetically(items).filter(i => i.family == 'lists')
            ]
        }
    ]
})

export default useIndexCardsCuratedCategoryStore
