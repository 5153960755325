<template>
    <ui-modal name="import-perspective" title="Import perspective data to your graph" :max-width="520" :max-height="680">
        <div class="text-center" v-if="perspectiveLoading">
            <ui-spinner type="clip" class="text-4xl"></ui-spinner>
            <div class="text-xl mt-2">Loading perspective data into your graph.</div>
            <div>Please be patient, this may take a while, especially for larger perspectives</div>
        </div>

        <div v-if="!perspectiveLoading && statsLoadingSuccess">
            <div class="text-center" v-if="statsNodesAffected > 0">
                <ui-icon name="check-circle" class="text-4xl text-green-500"></ui-icon>
                <div class="text-xl">Perspective data successfully loaded</div>
                <div class="text-lg">
                    {{ statsNodesAffected }} {{ statsNodesAffected === 1 ? 'node' : 'nodes' }} affected
                </div>
                <div>Now you can see your perspective data in nodes data table and use it for node styling in Graph styling toolbar</div>
            </div>

            <div class="text-center" v-else>
                <ui-icon name="alert-triangle" class="text-4xl"></ui-icon>
                <div class="text-xl">No Matching Channels</div>
                <div>It seems that none of the channels in your perspective match the channels in your graph.</div>
            </div>
        </div>

        <div v-if="!perspectiveLoading && !statsLoadingSuccess">
            <ui-icon name="alert-triangle" class="text-4xl"></ui-icon>
            <div class="text-xl">Something went wrong</div>
            <div>Failed to load graph data from the perspective. Please try again, and if the issue persists, contact support.</div>
        </div>
    </ui-modal>
</template>

<script>

import useConnectionsDataStore from "@/stores/connections/connections-data"
import { mapActions, mapState } from "pinia"

export default {

    data: () => ({
        selected: null,
    }),

    computed: {
        ...mapState(useConnectionsDataStore, [
            'perspectiveLoading', 'statsLoadingSuccess', 'statsNodesAffected'
        ]),
    },

    methods: {
        ...mapActions(useConnectionsDataStore, [ 'loadPerspectiveStats' ])
    }
}
</script>
