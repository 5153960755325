import defineOverviewStreamStore from '@/stores/content/perspective/overview/stream'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'
import searchFilters from '@/helpers/search-filters'
import { defineStore } from 'pinia'

export const defineStreamWidget = settings => {
    return defineStore(settings.id, {
        state: () => ({
            wid: settings.id,

            type: settings.type,

            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,

            sorting: settings.sorting || 'date-desc',

            perspectiveId: settings.perspectiveId,

            streamStore: null,
            filters: searchFilters(),
            
            globalFilters: searchFilters(),
            refreshInterval: 5,
            lastRefresh: null,
            lastLayoutUpdate: +new Date()
        }),

        getters: {
            perspective(store) {
                return useMyPerspectivesStore().items.find(l => l.id == store.perspectiveId)
            },

            title(store) {
                return store.perspective?.name || 'Stream'
            }
        },

        actions: {
            initialize(filters) {
                this.globalFilters = filters

                this.streamStore = defineOverviewStreamStore({
                    id: `${this.wid}streamWidget`
                })

                this.load()
                this.lastRefresh = +new Date

                return this
            },

            load() {
                this.filters.clear()
                this.filters.fromPerspective(this.perspective?.filters || {})
                this.filters.remove('date')
                this.filters.fromPerspective(this.globalFilters.toPerspective())
                this.streamStore().initialize(this.filters, this.sorting)
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                if (this.streamStore) this.streamStore().$destroy()

                this.$destroy()
            },

            refresh() {
                this.load()
                this.lastRefresh =+ new Date
            },

            added() {
                this.selectPerspective()
            },

            filtersUpdated() {
                this.load()
            },

            layoutUpdated() {
            },

            serialize() {
                return {
                    perspectiveId: this.perspective?.id
                }
            },

            dependencies() {
                return {
                    perspectives: [ this.perspective?.id ]
                }
            },

            selectPerspective() {
                useQuickSearchStore().show({
                    families: [ 'perspectives' ],
                    limit: 50,
                    onSelect: result => {
                        this.perspectiveId = result.id

                        this.load()

                        useQuickSearchStore().hide()
                    },
                    initialResults: sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id, resultType: 'perspective', title: item.name
                    }))
                })
            },

            setSorting(sorting) {
                this.sorting = sorting
                this.streamStore().setSorting(sorting)
            }
        }
    })
}

export default defineStreamWidget
