import useConnectionsStore from '@/stores/features/feature/connections'
import useStreamStore from '@/stores/features/feature/stream'
import useTaggingStore from '@/stores/tagging/tagging'

import defineContentMetricsStore from '@/stores/content/perspective/overview/timeline'
import defineHeatmapStore from '@/stores/features/feature/heatmap'
import defineKeywordsStore from '@/stores/content/perspective/overview/keywords'
import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'
import defineTopDomainsStore from '@/stores/content/perspective/overview/top-domains'
import defineTopHashtagsStore from '@/stores/content/perspective/overview/top-hashtags'
import defineTopLinksStore from '@/stores/content/perspective/overview/top-links'
import defineTopMediaStore from '@/stores/content/perspective/overview/top-media'

import { features } from '@/api'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'

export default defineStore('featuresFeaturePage', {
    state: () => ({
        feature: null,

        selectedTab: 'inspect',

        filters: searchFilters({ values: { date: { type: 'past', date: { past: 1, unit: 'years' } } } }),

        heatmapWidget: defineHeatmapStore({ id: 'featureHeatmapWidget' }),
        keywordsWidget: defineKeywordsStore({ id: `featureKeywordsWidget` }),
        contentMetricsWidget: defineContentMetricsStore({ id: `featureContentMetricsWidget` }),
        topDomainsWidget: defineTopDomainsStore({ id: `featureTopDomainsWidget` }),
        topHashtagsWidget: defineTopHashtagsStore({ id: `featureTopHashtagsWidget` }),
        topLinksWidget: defineTopLinksStore({ id: `featureTopLinksWidget` }),
        topMediaWidget: defineTopMediaStore({ id: `featureTopMediaWidget` }),

        lightbox: defineStreamMediaLightbox({ id: 'featureMediaLightbox', name: 'featureMediaLightbox' })
    }),

    getters: {
        title: store => store.feature ? `${store.feature.name} | Features` : ''
    },

    actions: {
        setup() {
            this.filters.onChange = () => this.loadWidgets()
        },

        async afterEnter(to) {
            useTaggingStore().initialize()

            await this.load(to.params.id)

            useConnectionsStore().load(this.feature)

            this.loadWidgets()
        },

        async afterLeave() {
            this.$reset()
            this.setup()
        },

        async load(id, force) {
            this.feature = await features().find(id)
        },

        loadWidgets() {
            this.contentMetricsWidget().initialize(this.featureSeries())
            this.keywordsWidget().initialize(this.featureSeries())
            this.heatmapWidget().initialize(this.featureSeries())
            this.topDomainsWidget().initialize(this.featureSeries())
            this.topLinksWidget().initialize(this.featureSeries())
            this.topHashtagsWidget().initialize(this.featureSeries())
            this.topMediaWidget().initialize(this.featureSeries())

            useStreamStore().initialize(this.feature)
            useStreamStore().filters.set({
                date: this.filters.value('date'),
                mentions: [{ id: this.feature.id, type: this.feature.type, name: this.feature.name, exclude: false }]
            })
        },

        selectTab(tab) {
            this.selectedTab = tab
        },

        selectMode(mode) {
            this.selectedMode = mode
            this.loadWidgets()
        },

        featureSeries() {
            return {
                name: this.feature.name,
                feature: this.feature,
                filters: searchFilters().fromPerspective({
                    date: this.filters.value('date'),
                    mentions: [{ id: this.feature.id, type: this.feature.type, name: this.feature.name, exclude: false }]
                }),
                color: '#2172f0'
            }
        }
    }
})
