<template>
    <div class="p-4">
        <div class="block text-sm font-medium leading-6 text-gray-900"></div>

        <ui-select :options="algorithmOptions" :value="algorithm" @change="selectMetrics" form class="w-full"></ui-select>

        <div class="text-sm mt-3 text-center">
            <span v-if="algorithm == 'centrality'">Betweenness centrality measures the importance of a node in a graph by quantifying how often it appears on the shortest paths between other node pairs, indicating its role as a connector or bridge within the network.</span>
            <span v-if="algorithm == 'pagerank'">PageRank is a metric that evaluates the importance of a node in a graph based on the number and quality of links to it, with highly-linked nodes contributing more to the importance score of connected nodes.</span>
            <span v-if="algorithm == 'eigenvector'">Eigenvector centrality measures a node’s influence in a graph by assigning higher scores to nodes connected to other highly influential nodes, capturing both direct and indirect connections to reflect overall importance.</span>
        </div>

        <ui-dropdown-separator class="my-4"></ui-dropdown-separator>

        <div v-if="processing" class="flex items-center justify-center mt-2 mb-2">
            <ui-spinner type="clip"></ui-spinner>
            <div class="ml-2">Computing metrics...</div>
        </div>

        <div v-if="!processing && computed[algorithm]" class="flex items-center justify-center mt-2">
            <ui-icon name="check-circle" class="mr-2 text-green-500 text-2xl"></ui-icon>
            <span>Metrics computed</span>
        </div>

        <div v-else-if="!processing && errors[algorithm]" class="flex items-center justify-center mt-2">
            <ui-icon  name="alert-triangle" class="mr-2 text-red-500 text-3xl"></ui-icon>
            <span class="text-center">Unable to compute this metric</span>
        </div>

        <div class="mt-2">
            <ui-button class="w-full" color="blue" icon="play" @click="runMetrics" :disabled="processing || computed[algorithm]">Calculate</ui-button>
        </div>
    </div>
</template>


<script>

import { mapActions, mapState } from "pinia"
import useGraphMetricsStore from "@/stores/connections/graph-metrics.js"


export default {
    data: () => ({
        algorithmOptions: [
            { id: 'centrality', name: 'Betweenness centrality' },
            { id: 'eigenvector', name: 'Eigenvector centrality' },
            { id: 'pagerank', name: 'Pagerank' }
        ]
    }),

    computed: {
        ...mapState(useGraphMetricsStore, [ 'algorithm', 'processing', 'computed', 'errors' ]),
    },

    methods: {
        ...mapActions(useGraphMetricsStore, [ 'selectMetrics', 'runMetrics' ]),
    },
}
</script>
