<template>
    <template v-if="! feedsDiscovered && ! feedsDiscovering">
        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
            Web Feed
        </label>
        
        <ui-input id="value" :placeholder="inputs.type.placeholder" type="text" autocomplete="off" ref="valueField" v-model="inputs.value">
        </ui-input>
    </template>
    
    <template v-else>
        <label class="block text-sm text-gray-700 font-medium mb-1">
            Select Web Feeds to be added
        </label>

        <div class="h-64 overflow-y-auto rounded-md border border-gray-200 shadow-inner p-1 relative">
            <a v-if="feedsDiscovered.length" @click.prevent="selectAllFeeds" href="#" class="flex items-center rounded-sm p-1 hover:bg-gray-50 cursor-default">
                <div class="w-10 text-center">
                    <ui-icon name="check"></ui-icon>
                </div>
                <div>
                    Select all
                </div>
            </a>
            
            <label v-for="feed in feedsDiscovered" :key="feed.url" class="flex items-center rounded-sm p-1 hover:bg-gray-50">
                <div class="w-10 text-center shrink-0">
                    <input type="checkbox" v-model="feedsSelected" :value="feed.url">
                </div>
                <div class="min-w-0">
                    <div class="truncate" v-tooltip="feed.title">{{feed.title}}</div>
                    <div class="text-sm text-gray-500">{{feed.url}}</div>
                </div>
            </label>
            
            <div v-if="! feedsDiscovered.length" class="flex flex-col gap-y-1 items-center justify-center h-full text-sm text-gray-700">
                <div>No Web Feeds have been discovered.</div>
                <a @click.prevent="resetFeedsDiscovery" href="#" class="text-blue-700 hover:text-blue-900">Back</a>
            </div>

            <div v-if="feedsDiscovering" class="flex flex-col gap-y-1 items-center justify-center h-full text-sm text-gray-700 bg-white absolute inset-0">
                <ui-spinner type="clip" class="text-lg"></ui-spinner>
                Discovering Web Feeds...
            </div>
        </div>
    </template>
</template>

<script>
import useChannelsModalsLibraryAddChannelStore from '@/stores/channels/modals/library-add-channel'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    computed: {
        ...mapState(useChannelsModalsLibraryAddChannelStore, [ 'feedsDiscovered', 'feedsDiscovering' ]),
        ...mapWritableState(useChannelsModalsLibraryAddChannelStore, [ 'feedsSelected', 'inputs' ])
    },

    methods: {
        ...mapActions(useChannelsModalsLibraryAddChannelStore, [ 'selectAllFeeds', 'resetFeedsDiscovery' ]),

        focus() {
            this.$refs.valueField.focus()
        }
    }
}
</script>
