import api from '@/api'
import { channels } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export default defineStore('myBookmarks', {
    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = channels()
                .filters('list', 'bookmarks')
                .limit(10)
                .include([ 'lists', 'monitoringOptions', 'channel.metrics' ])
                .get(channels => {
                    this.items = channels.map(channel => ({ ...channel, bookmarked: true }))

                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        isBookmarked(channel) {
            return this.items?.find(t => t.id == channel.id)
        },

        async bookmark(channel) {
            await api.route('me lists channels store', { id: 'bookmarks', channelId: channel.id }).post().res()

            return this.reload()
        },

        async unbookmark(channel) {
            await api.route('me lists channels delete', { id: 'bookmarks', channelId: channel.id }).formData({ _method: 'delete' }).post().res()

            return this.reload()
        }
    }
})
