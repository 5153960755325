import { defineContentPerformanceAnalysisStore } from '@/stores/analysis/analyses/content-performance'

import { content } from '@/api'
import determineGranularity from '@/helpers/determine-granularity'
import number from '@/helpers/number'
import { date } from '@/helpers/datetime'

import { differenceInHours, subDays, subHours, subMonths, subWeeks, subYears } from 'date-fns'
import { defineStore } from 'pinia'

export default settings => defineStore(settings.id, {
    state: () => ({
        series: [],

        isInitialized: false,
        isLoading: false,

        enabledMetrics: [ 'results', 'interactions', 'impact', 'reactions', 'comments', 'shares', 'views' ],
        selectedMetric: 'results',

        supportedMetrics: [
            { id: 'results', name: 'Results', icon: 'hash', aggregate: 'results:count', dataQuality: 'observed' },
            { id: 'interactions', name: 'Interactions', icon: 'interactions', aggregate: 'interactions:sum', dataQuality: 'observed' },
            { id: 'impact', name: 'Impact', icon: 'zap', aggregate: 'impact:sum', dataQuality: 'processed' },
            { id: 'reactions', name: 'Reactions', icon: 'thumbs-up', aggregate: 'reactions:sum', dataQuality: 'observed', secondary: true },
            { id: 'comments', name: 'Comments', icon: 'comment', aggregate: 'comments:sum', dataQuality: 'observed', secondary: true },
            { id: 'shares', name: 'Shares', icon: 'share', aggregate: 'shares:sum', dataQuality: 'observed', secondary: true },
            { id: 'views', name: 'Views', icon: 'views', aggregate: 'views:sum', dataQuality: 'observed', secondary: true }
        ],

        supportedPlatformMetrics: [
            { id: 'facebook', name: 'Facebook', icon: 'badges.channel-facebook-page', metrics: [
                { id: 'like', name: 'Like', icon: 'reactions.like' },
                { id: 'love', name: 'Love', icon: 'reactions.love' },
                { id: 'care', name: 'Care', icon: 'reactions.care' },
                { id: 'haha', name: 'Haha', icon: 'reactions.haha' },
                { id: 'wow', name: 'Wow', icon: 'reactions.wow' },
                { id: 'sad', name: 'Sad', icon: 'reactions.sad' },
                { id: 'angry', name: 'Angry', icon: 'reactions.angry' }
            ] },
            { id: 'x', name: 'X', icon: 'badges.channel-x-user', metrics: [
                { id: 'reposts', name: 'Reposts', icon: 'repeat' },
                { id: 'quotes', name: 'Quotes', icon: 'corner-down-right' }
            ] }
        ],

        analysisStore: defineContentPerformanceAnalysisStore({ id: `${settings.id}Analysis` })
    }),

    getters: {
        analysisOptions: store => ({
            chart: {
                backgroundColor: false,
                type: 'line',
                zoomType: 'xy',
                resetZoomButton: {
                    position: {
                        x: -40,
                        y: -5,
                    }
                },
                spacing: [0, 0, 0, 0]
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: '',
            },
            plotOptions: {
                series: {
                    lineWidth: 3,
                    marker: {
                        enabled: false
//                        symbol: 'circle',
//                        radius: 5
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [
                {
                    id: 'values',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent'
                }
            ],
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () {
                    return store.series.length > 1
                        ? `${date(this.x)}<br/><strong>${this.series.name}: ${number(this.y)}</strong><br/>`
                        : `${date(this.x)}<br/><strong>${number(this.y)}</strong><br/>`
                },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' }
            },
            legend: {
                enabled: false
            },
            series: store.series.map(series => ({
                //                    color: '#9fc2f9',
                //                    color: '#9333ea',
                color: series.color,
                data: store.activeMetric(series).data,
                name: series.name,
                //                    type: 'column',
                type: 'spline',
                yAxis: 0
            })),
            credits: {
                enabled: false
            }
        }),

        isComparing: store => store.series.length > 1,
        isRenderable: store => store.analysisStore().isRenderable
    },

    actions: {
        async initialize(series, analysisData = null) {
            this.series = [ { ...series } ]

            await this.load(analysisData)

            this.isInitialized = true
        },

        async compareWith(series) {
            this.series.push({ ...series })

            this.isLoading = true

            await this.load()
        },

        stopComparing(series) {
            this.series = this.series.filter(s => s.id != series.id)

            this.load()
        },

        async load(analysisData = null) {
            this.isLoading = true

            await Promise.all([
                this.loadAnalysis(analysisData),
                this.loadCurrentMetrics(),
                this.loadPreviousMetrics()
            ])

            this.isLoading = false
        },

        loadAnalysis(analysisData = null) {
            let store = this.analysisStore()

            store.series = this.series.flatMap(series => [
                {
                    type: 'filters',
                    label: 'Results',
                    color: '#000',
                    meta: {
                        aggregator: 'count',
                        metric: 'all'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Interactions',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'interactions'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Impact',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'impact'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Reactions',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'reactions'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Comments',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'comments'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Shares',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'shares'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                },
                {
                    type: 'filters',
                    label: 'Views',
                    color: '#000',
                    meta: {
                        aggregator: 'summary',
                        metric: 'views'
                    },
                    datasetType: 'inline-perspective',
                    datasetFilters: series.filters.toJson()
                }
            ])

            if (this.series[0].filters.value('date')) {
                store.date = this.series[0].filters.value('date')
                store.granularity = determineGranularity(store.date)
            } else {
                store.date = { type: 'past', date: { past: 1, unit: 'years' } }
                store.granularity = 'month'
            }
            
            return analysisData ? store.setData(analysisData) : store.loadSeries()
        },

        async loadCurrentMetrics(metrics = null) {
            metrics = metrics ?? await Promise.all(this.series.map(series => series.currentMetrics ?? this.fetchMetrics(series.filters)))
            metrics.forEach((metrics, index) => this.series[index].currentMetrics = metrics)
        },

        async loadPreviousMetrics(metrics = null) {
            metrics = metrics ?? await Promise.all(this.series.map(series => series.previousMetrics ?? this.fetchMetrics(series.filters, this.previousDateRange())))
            metrics.forEach((metrics, index) => this.series[index].previousMetrics = metrics)
        },

        fetchMetrics(filters, dateRange) {
            filters = filters.toPerspective()

            if (dateRange) filters['date'] = dateRange

            return content()
                .filters(filters)
                .query({ aggregates: 'results:count,interactions:sum,impact:sum,comments:sum,shares:sum,views:sum,reactions:sum,platformMetrics:sum' })
                .get()
        },

        previousDateRange() {
            let dateFilter = this.series[0].filters.value('date')

            if (! dateFilter) return

            if (dateFilter.type == 'past') {
                if (dateFilter.date.unit == 'days') {
                    return { type: 'in-range', date: { gte: subDays(new Date(), dateFilter.date.past * 2), lte: subDays(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'hours') {
                    return { type: 'in-range', date: { gte: subHours(new Date(), dateFilter.date.past * 2), lte: subHours(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'weeks') {
                    return { type: 'in-range', date: { gte: subWeeks(new Date(), dateFilter.date.past * 2), lte: subWeeks(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'months') {
                    return { type: 'in-range', date: { gte: subMonths(new Date(), dateFilter.date.past * 2), lte: subMonths(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'years') {
                    return { type: 'in-range', date: { gte: subYears(new Date(), dateFilter.date.past * 2), lte: subYears(new Date(), dateFilter.date.past) } }
                }
            } else if (dateFilter.type == 'in-range') {
                return { type: 'in-range', date: { gte: subHours(dateFilter.date.gte, differenceInHours(dateFilter.date.lte, dateFilter.date.gte)), lte: dateFilter.date.gte } }
            }
        },

        metrics(series) {
            let current, previous

            return this.supportedMetrics.map((metric, index) => ({
                ...metric,
                current: current = series?.currentMetrics?.[metric.aggregate],
                previous: previous = series?.previousMetrics?.[metric.aggregate],
                change: current && previous ? Math.round(Math.abs(current - previous) / current * 100) : null,
                data: this.analysisStore().analysisSeries[this.series?.indexOf(series) * this.supportedMetrics.length + index]?.values || [],
            })).filter(metric => this.enabledMetrics.includes(metric.id))
        },

        moreMetrics(series) {
            let current, previous

            return this.supportedMetrics.map((metric, index) => ({
                ...metric,
                current: current = series?.currentMetrics?.[metric.aggregate],
                previous: previous = series?.previousMetrics?.[metric.aggregate],
                change: current && previous ? Math.round(Math.abs(current - previous) / current * 100) : null,
                data: this.analysisStore().analysisSeries[this.series?.indexOf(series) * this.supportedMetrics.length + index]?.values || [],
            })).filter(metric => ! this.enabledMetrics.includes(metric.id))
        },

        activeMetric(series) {
            return this.metrics(series).find(metric => metric.id == this.selectedMetric)
        },

        platformMetrics(series) {
            let current, previous

            return this.supportedPlatformMetrics.map(platform => ({
                ...platform,
                metrics: platform.metrics.map(metric => ({
                    ...metric,
                    current: current = series.currentMetrics?.['platformMetrics:sum']?.[platform.id][metric.id],
                    previous: previous = series.previousMetrics?.['platformMetrics:sum']?.[platform.id][metric.id],
                    change: current && previous ? Math.round(Math.abs(current - previous) / current * 100) : null
                }))
            }))
        }
    }
})
