<template>
    <div class="h-full relative">
        <div class="absolute inset-0 z-10 flex items-center justify-center bg-gray-100/10 backdrop-blur-sm rounded-md">
            <div class="bg-gray-200/80 text-gray-900 px-6 py-4 rounded-lg shadow-xs w-80 text-center">
                <div class="font-medium">Keywords not available</div>
                <div class="text-sm leading-tight">We are working on improvements to our keywords feature.</div>
            </div>
        </div>

        <!--<div v-if="store.isLoading" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-100/10 backdrop-blur-sm rounded-md">-->
        <!--    <div class="bg-gray-200/80 text-gray-900 font-medium px-6 py-4 rounded-lg shadow-xs flex items-center">-->
        <!--        <ui-spinner type="clip" class="mr-2"></ui-spinner>-->
        <!--        Updating keywords...-->
        <!--    </div>-->
        <!--</div>-->

        <!--<div class="h-full" ref="contentContainer" v-if="store.hasValues">-->
        <!--    <div class="flex items-center justify-center h-full overflow-y-auto">-->
        <!--        <div class="flex flex-wrap justify-center items-center" ref="content">-->
        <!--        <p class="truncate mr-3" :style="{ fontSize: fontSize(value) + 'rem', lineHeight: 2.5 + 'rem' }" :key="keyword" v-for="value, keyword in store.analysisSeries">-->
        <!--            {{ keyword }}-->
        <!--        </p>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</div>-->

        <!--<div v-else-if="! store.isLoading" class="h-full flex flex-col items-center justify-center text-gray-700">-->
        <!--    <ui-icon name="frown" class="mb-2 text-3xl text-gray-600"></ui-icon>-->
        <!--    <p>Empty keywords...</p>-->
        <!--</div>-->
    </div>
</template>

<script>
export default {
    methods: {
        fontSize(value) {
            const weights = Object.values(this.store.analysisSeries)
            const minWeight = Math.min(...weights)
            const maxWeight = Math.max(...weights)

            return ((value - minWeight) / (maxWeight - minWeight) + 1.2).toFixed(2)
        }
    }
}
</script>
