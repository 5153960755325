<template>
    <a @click.prevent="click" href="#" class="inline-flex">
        <slot>
            <ui-icon name="help-circle" class="text-gray-700 hover:text-gray-900"></ui-icon>
        </slot>
    </a>
</template>

<script>
import useSupportHelpStore from '@/stores/support/user-guide.js'

export default {
    props: [ 'page', 'section' ],

    methods: {
        click() {
            if (this.page) useSupportHelpStore().showOverlay(this.page, this.section)
        }
    }
}
</script>
