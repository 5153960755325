import api from '@/api'

import { marked } from 'marked'
import { defineStore } from 'pinia'

export default defineStore('supportWhatsNew', {
    state: () => ({
        releases: [],

        title: null,
        content: null,
        updatedAt: null,

        isShowingOverlay: false,
        initialized: false
    }),

    actions: {
        async initialize() {
            if (this.initialized) return

            this.releases = await api.route('support whats-new index').get().json()

            this.initialized = true
        },

        index() {
            this.title = this.content = this.updatedAt = null
        },

        async show(release) {
            this.initialize()

            this.title = this.content = this.updatedAt = null

            await this.loadRelease(release)
        },

        async showOverlay(release) {
            this.show(release)

            this.isShowingOverlay = true
        },

        closeOverlay() {
            this.isShowingOverlay = false
        },

        loadRelease(release) {
            return api.route('support whats-new details', release).get().json(res => {
                this.title = res.title
                this.updatedAt = res.updatedAt
                this.content = marked.parse(res.content)
            })
        }
    }
})
