import useMyAnalysesStore from '@/stores/me/analyses'
import useMyNotificationsStore from '@/stores/me/notifications'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import hasDatasetConcern from './concerns/has-dataset-concern'

import api from '@/api'
import safeColors from '@/helpers/safe-colors'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'

export const defineContentPublishTimesAnalysisStore = settings => {
    return defineStore(settings.id, {
        state: () => ({
            analysis: null,

            id: null,
            name: 'Untitled analysis',
            type: 'content-publish-times',

            series: [],
            styles: {},

            date: { type: 'past', date: { past: 1, unit: 'years' }, },
            granularity: false,

            isTakingTooLong: false,

            isLoading: false,
            loadingPromise: null,
            abortLoading: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: [],
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 10,
                hasColors: false,
                hasLabels: true
            },

            styleOptions: [],
            styleDefaults: {},

            exportable: false,
            printable: false,

            passive: settings.passive || false,

            analysisRef: null,
            taskId: null,

            heatmapTypes: [
                { id: 'daily', name: 'Daily' },
                { id: 'hourly', name: 'Hourly' }
            ]
        }),

        getters: {
            heatmapOptions: store => ({
                styles: store.styles,
                isLoading: store.isLoading,
                series: store.analysisSeries,
                seriesGeneratedAt: store.seriesGeneratedAt
            }),

            effectiveStyles: store => ({
                ...store.styleDefaults,
                ...store.styles
            }),

            analysisConfiguration: store => ({
                series: store.series.map(series => ({
                    color: series.color,
                    datasetId: series.datasetId,
                    datasetType: series.datasetType,
                    datasetFilters: series.datasetFilters,
                    label: series.label,
                    meta: series.meta
                })),
                styles: store.effectiveStyles,
                date: store.date,
                granularity: store.series[0].meta.type
            }),

            selectedHeatmapType: store => {
                return store.heatmapTypes.find(m => m && m.id == (store.series[0].meta.type || 'daily'))
            }
        },

        actions: {
            async initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.date = analysis.configuration.date || this.date

                this.series = analysis.configuration.series || this.series
                this.styles = analysis.configuration.styles || this.styles

                this.loadSeries()
            },

            async loadSeries() {
                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isTakingTooLong = true
                    }
                }, 5000)

                return this.loadingPromise = new Promise((accept, reject) => {
                    api.route('me analyses series')
                        .json({
                            type: this.type,
                            configuration: this.analysisConfiguration,
                            id: this.analysis?.id
                        })
                        .signal(this.abortLoading = new AbortController())
                        .post()
                        .error(422, () => {
                            this.isLoading = false
                        })
                        .json(response => {
                            this.setData(response.data)
                            this.seriesGeneratedAt = response.preparedAt ? new Date(response.preparedAt) : null

                            if (response.taskId) {
                                useMyNotificationsStore().onTaskCompletion(response.taskId, task => {
                                    this.setData(task.payload.data)

                                    this.isLoading = this.isTakingTooLong = false
                                    this.taskId = null

                                    accept(this.analysisSeries)
                                })

                                this.taskId = response.taskId
                            } else {
                                this.isLoading = this.isTakingTooLong = false

                                accept(this.analysisSeries)
                            }
                        })
                })
            },

            loadSeriesDebounced: debounce(function () {
                this.loadSeries()
            }, 500),

            setData(data) {
                this.analysisSeries = data || []
            },

            addSeries(data) {
                this.series.push({
                    label: data.label,
                    color: data.color,
                    datasetType: data.datasetType,
                    datasetId: data.datasetId,
                    meta: data.meta || { type: 'daily' },
                    values: data.values || []
                })

                this.isDirty = true
                this.loadSeries()
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    label: data.label || series.label,
                    color: data.color || series.color,
                    datasetType: data.datasetType || series.datasetType,
                    datasetId: data.datasetId || series.datasetId,
                    meta: data.meta || series.meta,
                    values: data.values || series.values
                })

                this.isDirty = true
                this.loadSeries()
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.series.splice(index, 1)

                    this.isDirty = true
                    this.loadSeries()
                }
            },

            isValidSeries(data) {
                return data.label && data.datasetType && data.datasetId
            },

            async fromQuery(query) {
                if (query.date) this.date = searchFilters().unserializeFrom('uri', 'date', query.date)

                await useMyPerspectivesStore().initialize()

                let perspective = useMyPerspectivesStore().find(query['perspective'])

                if (perspective) {
                    this.addSeries({
                        label: perspective.name,
                        color: safeColors.data().safeColors[0],
                        datasetType: 'perspective',
                        datasetId: perspective.id
                    })
                }
            },

            setDate(date) {
                this.date = date
                this.isDirty = true

                this.loadSeries()
            },

            setStyle(name, value) {
                this.styles[name] = value
                this.isDirty = true
            },

            setAnalysisRef(analysis) {
                this.analysisRef = analysis
            },

            setHeatmapType(type) {
                this.series[0].meta = { type }
                this.isDirty = true

                this.analysisSeries = []
                this.loadSeries()
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'lists': this.series.filter(s => s.datasetType == 'list').map(s => s.datasetId),
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            ...hasDatasetConcern.actions
        }
    })
}

const useAnalysisAnalysesContentPublishTimesStore = defineContentPublishTimesAnalysisStore({ id: 'analysisAnalysesContentPublishTimes' })

export default useAnalysisAnalysesContentPublishTimesStore
