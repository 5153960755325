<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-xs rounded-lg p-4 mb-4">
        <div class="flex items-center justify-between pb-4">
            <h3 class="text-lg font-medium text-gray-700">
                History
            </h3>
        </div>

        <history-records></history-records>
    </div>
</template>

<script>
import HistoryRecords from './partials/history-records'

export default {
    props: { full: Boolean },

    components: { HistoryRecords }
}
</script>
