<template>
    <div v-if="store.search && store.items.length" class="mt-3 max-h-96 overflow-y-scroll -mx-3">
        <search-result v-for="result, index in store.items" :key="index" :result="result" :ref="`result-${index}`" :store="myQuickSearchStore"></search-result>
    </div>
</template>

<script>
import SearchResult from '@/components/quick-search/result'

import useMyQuickSearchStore from '@/stores/me/quick-search'

import { mapStores } from 'pinia'

export default {
    components: {
        SearchResult
    },

    computed: {
        ...mapStores(useMyQuickSearchStore)
    }
}
</script>