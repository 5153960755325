<template>
    <help-content :title="title" :content="content"></help-content>
</template>

<script>
import HelpContent from './content'

import useSupportHelpStore from '@/stores/support/user-guide'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        HelpContent
    },

    computed: {
        ...mapState(useSupportHelpStore, [ 'title', 'content' ]),
    },

    methods: {
        ...mapActions(useSupportHelpStore, [ 'click' ])
    }
}
</script>
