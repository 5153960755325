import useMeModalsNoteFormStore from '@/stores/me/modals/note-form'

import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export default defineStore('myModalsNote', {
    state: () => ({
        item: null,
        note: null,

        isEditing: false
    }),

    actions: {
        open(item, note) {
            this.item = item
            this.note = note

            this.isEditing = false

            useModal().show('note')
        },

        create(item) {
            this.open(item)

            useMeModalsNoteFormStore().initialize(item)

            this.isEditing = true
        },

        edit() {
            useMeModalsNoteFormStore().initialize(this.item, this.note)

            this.isEditing = true
        },

        async remove() {
            await api.route('me notes delete', { id: this.note.id }).delete().res()

            this.item.notes = this.item.notes.filter(n => n.id != this.note.id)

            useModal().hide('note')
        }
    }
})
