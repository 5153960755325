<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-if="store.analysis?.type == 'content-top-channels'">
            <ui-table v-if="store.view == 'table'" :store="store.analysisStore()"></ui-table>
            <ui-analysis v-else class="h-full" :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-keywords'">
            <keywords-analysis :store="store.analysisStore()"></keywords-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-performance'">
            <ui-analysis :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-publish-times'">
            <hourly-heatmap v-if="store.view == 'hourly-heatmap'" :compact="true" :store="store.analysisStore().heatmapOptions" ref="widgetAnalysis"></hourly-heatmap>
            <daily-heatmap v-else :compact="true" :store="store.analysisStore().heatmapOptions" ref="widgetAnalysis"></daily-heatmap>
        </template>

        <template v-else-if="store.analysis?.type == 'content-pull-push'">
            <ui-analysis :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-sentiment'">
            <ui-analysis :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-top-tags'">
            <ui-table v-if="store.view == 'table'" :store="store.analysisStore()"></ui-table>
            <ui-analysis v-else class="h-full" :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'content-top-platforms'">
            <ui-table v-if="store.view == 'table'" :store="store.analysisStore()"></ui-table>
            <ui-analysis v-else class="h-full" :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'channel-audience'">
            <ui-analysis :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-else-if="store.analysis?.type == 'channel-publish-times'">
            <hourly-heatmap v-if="store.view == 'hourly-heatmap'" :compact="true" :store="store.analysisStore().heatmapOptions" ref="widgetAnalysis"></hourly-heatmap>
            <daily-heatmap v-else :compact="true" :store="store.analysisStore().heatmapOptions" ref="widgetAnalysis"></daily-heatmap>
        </template>

        <template v-else-if="store.analysis?.type == 'content-top-iptc-topics'">
            <ui-table v-if="store.view == 'table'" :store="store.analysisStore()"></ui-table>
            <ui-analysis v-else class="h-full" :store="store.analysisStore()" ref="widgetAnalysis"></ui-analysis>
        </template>

        <template v-slot:actions>
            <ui-dropdown-link @click="store.selectAnalysis()" icon="navigation.analysis">Select analysis...</ui-dropdown-link>

            <template v-if="[ 'content-top-channels', 'content-top-iptc-topics', 'content-top-platforms' ].includes(store.analysis?.type)">
                <ui-dropdown-separator></ui-dropdown-separator>

                <div class="text-xs font-medium text-gray-700 px-4 my-2 cursor-pointer">
                    Visualization
                </div>

                <ui-dropdown-link @click="store.toggleView('timeline')" :selected="store.view == 'timeline'">Timeline</ui-dropdown-link>
                <ui-dropdown-link @click="store.toggleView('table')" :selected="store.view == 'table'">Table</ui-dropdown-link>
            </template>

            <template v-if="[ 'content-publish-times', 'channel-publish-times' ].includes(store.analysis?.type)">
                <ui-dropdown-separator></ui-dropdown-separator>

                <div class="text-xs font-medium text-gray-700 px-4 my-2 cursor-pointer">
                    Type
                </div>

                <ui-dropdown-link @click="store.toggleView('hourly-heatmap');store.setOption('type', 'hourly')" :selected="store.view == 'hourly-heatmap'">Hourly</ui-dropdown-link>
                <ui-dropdown-link @click="store.toggleView('daily-heatmap');store.setOption('type', 'daily')" :selected="store.view == 'daily-heatmap'">Daily</ui-dropdown-link>
            </template>

            <template v-if="[ 'content-sentiment' ].includes(store.analysis?.type)">
                <ui-dropdown-separator></ui-dropdown-separator>

                <div class="text-xs font-medium text-gray-700 px-4 my-2 cursor-pointer">
                    Type
                </div>

                <ui-dropdown-link @click="store.setOption('type', 'content')" :selected="store.options.type == 'content'">Content</ui-dropdown-link>
                <ui-dropdown-link @click="store.setOption('type', 'replies')" :selected="store.options.type == 'replies'">Replies</ui-dropdown-link>
            </template>

            <template v-if="[ 'content-sentiment' ].includes(store.analysis?.type)">
                <ui-dropdown-separator></ui-dropdown-separator>

                <div class="text-xs font-medium text-gray-700 px-4 my-2 cursor-pointer">
                    Visualization
                </div>

                <ui-dropdown-link @click="store.setOption('analysis.style', 'ratio')" :selected="store.options['analysis.style'] == 'ratio'">Relative</ui-dropdown-link>
                <ui-dropdown-link @click="store.setOption('analysis.style', 'absolute')" :selected="store.options['analysis.style'] == 'absolute'">Absolute</ui-dropdown-link>
            </template>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>
    </dashboard-widget>
</template>

<script>
import DailyHeatmap from '@/components/analysis/analyses/heatmaps/daily'
import DashboardWidget from './widget'
import HourlyHeatmap from '@/components/analysis/analyses/heatmaps/hourly'
import KeywordsAnalysis from '@/components/analysis/analyses/keywords/keywords'

export default {
    components: { DailyHeatmap, DashboardWidget, HourlyHeatmap, KeywordsAnalysis },
    
    watch: {
        'store.lastLayoutUpdate'() {
            this.$nextTick(() => {
                if (this.$refs.widgetAnalysis) this.$refs.widgetAnalysis.reflow()
            })
        }
    }
}
</script>
