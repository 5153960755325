<template>
    <router-link :to="{ name: 'channels', query: { show: `curated-category|id:${category.id}` } }" class="block hover:bg-linear-to-r from-transparent via-gray-50 to-transparent transition">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <ui-icon :name="category.icon" class="text-blue-500 shrink-0 text-[30px]"></ui-icon>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{category.name}}
                    </h1>
                </div>
            </div>

            <div class="flex items-center space-x-3 px-3">
                <div class="text-sm text-gray-700">
                    {{category.listsRecursiveCount}} {{category.listsRecursiveCount == 1 ? 'list' : 'lists'}}
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: { cardsStore: {}, mode: { default: 'card' }, category: {}, to: {} }
}
</script>
