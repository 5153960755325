import api from '@/api'

import { marked } from 'marked'
import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export default defineStore('supportUserGuide', {
    state: () => ({
        pages: [],

        title: null,
        content: null,
        updatedAt: null,

        isShowingOverlay: false,
        initialized: false
    }),

    actions: {
        async initialize() {
            if (this.initialized) return

            this.pages = await api.route('support user-guide index').get().json()

            this.initialized = true
        },

        index() {
            this.title = this.content = this.updatedAt = null
        },

        async show(page, section) {
            this.initialize()

            this.title = this.content = this.updatedAt = null

            await this.loadPage(page)

            nextTick(() => setTimeout(() => this.showSection(section)), 250)
        },

        async showOverlay(page, section) {
            this.show(page, section)

            this.isShowingOverlay = true
        },

        closeOverlay() {
            this.isShowingOverlay = false
        },

        loadPage(page) {
            return api.route('support user-guide details', page).get().json(res => {
                this.title = res.title
                this.updatedAt = res.updatedAt
                this.content = marked.parse(res.content)
            })
        },

//        scrollTop() {
//            document.querySelector('#app-content').scrollTo(0, 0)
//        },

        showSection(section) {
            document.querySelector(".help-text [id='" + section + "']")?.scrollIntoView(true)
//            document.querySelector('#app-content').scrollBy(0, -50)
        },

//        parseLink(link) {
//            return link.substring(1).split('#')
//        },
//
//        click(event) {
//            event.preventDefault()
//
//            if (event.target.hasAttribute('href')) {
//                if (event.target.getAttribute('href').startsWith('/')) {
//                    const [page, section] = this.parseLink(event.target.getAttribute('href'))
//
//                    if (this.isOverlayOpen) {
//                        this.loadPage(page)
//
//                        if (section) {
//                            setTimeout(() => {
//                                this.setSection(section)
//                            }, 1000)
//                        }
//                    } else {
//                        useRouter().push({ name: 'help.page', params: { page, section } })
//                    }
//                }
//
//                if (event.target.getAttribute('href').startsWith('#')) {
//                    this.setSection(event.target.getAttribute('href').substring(1))
//                }
//            }
//
//            return event
//        }
    }
})
