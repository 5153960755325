import useChannelsIndexCardsStore from '@/stores/channels/index-cards'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('channelsModalsListAddChannel', {
    state: () => ({
        list: {}
    }),

    actions: {
        open(list, addedCallback = null) {
            this.$reset()

            this.list = list

            useQuickSearchStore().show({
                families: [ 'channels' ],
                limit: 50,
                multiSelect: true,
                onSelect: async results => {
                    useQuickSearchStore().hide()

                    await Promise.all(results.map(result => useMyChannelListsStore().addToList(this.list, result)))

                    useChannelsIndexCardsStore().source().reload()
                },
                initialResults: this.loadSuggestions()
            })
        },

        loadSuggestions() {
            return api.route('me lists suggestions', this.list.id)
                .query({ include: 'channel.metrics' })
                .get()
                .error(500, err => this.error = err?.json?.message || 'unknown')
                .json(res => {
                    return res.data.channels.map(channel => ({
                        id: channel.id,
                        resultType: 'channel',
                        type: channel.type,
                        title: channel.name,
                        avatar: channel.avatar,
                        flags: [ channel.monitored ? 'monitored' : null, channel.verified ? 'verified' : null ].filter(v => v),
                        username: channel.username,
                        followers: channel.followers
                    }))
                })
        }
    }
})
