import { createPinia as createBasePinia } from 'pinia'

export default function createPinia() {
    let pinia = createBasePinia()

    pinia.use(({ store }) => {
        store.$destroy = () => {
            store.$dispose()
            delete pinia.state.value[store.$id]
        }
    })

    return pinia
}
