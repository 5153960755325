import api from '@/api'

export const asyncResource = settings => ({
    data: null,
    res: null,

    method: 'get',

    abortController: null,
    pending: false,
    isFetching: false,
    isFetchingFirst: false,
    isFetchingNext: false,

    appending: false,
    prepending: false,

    collection: true,
    paginated: false,

    onResponse: () => {},

    async request(api, state) {
    },

    transform(data) {
        return data
    },

    async fetch(state, options) {
        options = this.resolveOptions(options)

        this.abort()

        this.pending = this.isFetching = true
        this.error = null

        let res = await this.request(api, state, options.payload)
            .signal(this.abortController = new AbortController())
            [options.method]()
            .badRequest(err => this.setError('bad-request'))
            .unauthorized(err => this.setError('unauthorized'))
            .forbidden(err => this.setError('forbidden'))
            .notFound(err => this.setError('not-found'))
            .timeout(err => this.setError('timeout'))
            .internalError(err => this.setError('internal-error'))
            .json()

        if (! res) return []

        this.res = res

        let transformed = this.transform(res.data, state)

        if (options.collection && options.appending) {
            this.data = this.data || []
            this.data.push(...transformed)
        } else if (options.collection && options.prepending) {
            this.data = this.data || []
            this.data.unshift(...transformed)
        } else {
            this.data = transformed
        }

        options.onResponse(transformed, this.data, state)

        this.abortController = null

        if (options.paginated && res.data.length && res.sort) {
            return await this.fetch(state, { ...options, collection: true, appending: true, payload: { after: res.sort } })
        }

        this.pending = this.isFetching = this.isFetchingFirst = this.isFetchingNext = false

        return transformed
    },

    async fetchFirst(store, options) {
        this.isFetchingFirst = true

        return await this.fetch(store, { ...options, collection: true, appending: false, paginated: false })
    },

    async fetchNext(store, options) {
        if (! this.res?.sort) return []

        this.isFetchingNext = true

        return await this.fetch(store, { ...options, collection: true, appending: true, paginated: false, payload: { after: this.res.sort } })
    },

    abort() {
        if (this.abortController) {
            this.abortController.abort()
            this.pending = false
        }
    },

    reset() {
        this.abort()
        this.pending = false
        this.data = null
    },

    setError(error) {
        this.error = error
        this.pending = false
        this.data = null
    },

    resolveOptions(options) {
        return {
            method: this.method,
            collection: this.collection,
            appending: this.appending,
            paginated: this.paginated,
            payload: {},
            onResponse: this.onResponse,
            ...options
        }
    },

    ...settings
})

export default asyncResource
