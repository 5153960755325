import useTaggingStore from '@/stores/tagging/tagging'

import { defineStore } from 'pinia'

import { useModal } from '@/helpers'

export default defineStore('contentModalsReviewOverlay', {
    state: () => ({
        streamStore: null,
        
        contentIndex: 0,
        
        tagGroup: null
    }),
    
    getters: {
        content: store => store.streamStore?.items.data?.[store.contentIndex],
        
        itemsReviewed: store => store.contentIndex + 1,
        itemsLeft: store => store.streamStore?.itemsCount - store.itemsReviewed,
        itemsTotal: store => store.streamStore?.itemsCount,
        
        tagGroups: store => useTaggingStore().tagGroups,
        tags: store => store.tagGroup?.tags || useTaggingStore().flattenedTags
    },

    actions: {
        open(streamStore) {
            this.streamStore = streamStore
            
            this.contentIndex = 0
            
            useModal().show('content-review-overlay')
        },

        close() {
            useModal().hide('content-review-overlay')
        },
        
        next() {
            if (this.itemsLeft) this.contentIndex++

            if (this.contentIndex == this.streamStore.items.data?.length && this.itemsLeft) {
                this.streamStore.loadMore()
            }
        },
        
        previous() {
            if (this.contentIndex > 0) this.contentIndex--
        },
        
        keyPressed(key) {
            if (key == 'Escape') {
                this.close()
            } else if (key == 'ArrowRight') {
                this.next()
            } else if (key == 'ArrowLeft') {
                this.previous()
            } else if (parseInt(key)) {
                if (parseInt(key) <= this.tags.length) this.toggleTag(this.tags[parseInt(key) - 1])
            }
        },
        
        toggleTag(tag) {
            useTaggingStore().toggleTagOn(this.content, tag)
        },
        
        hasTag(tag) {
            return useTaggingStore().isAppliedTo(this.content, tag)
        },
        
        selectTagGroup(group) {
            this.tagGroup = group
        }
    }
})
