import { createApp } from 'vue'
import Main from './Main.vue'

let app = createApp(Main)

import createPinia from '@/pinia'
import { vfmPlugin } from 'vue-final-modal'
import FloatingVue from 'floating-vue'
import VueClickAway from 'vue3-click-away'

app.use(createPinia())
app.use(vfmPlugin({ key: '$modal' }))
app.use(FloatingVue, { delay: 200, themes: { 'link': { $extend: 'menu', $resetCss: true } } })
app.use(VueClickAway)

import { marked } from 'marked'
import customHeadingId from 'marked-custom-heading-id'

marked.use(customHeadingId())

import registerComponents from '@/components'
import registerHelpers from '@/helpers'

registerComponents(app)
registerHelpers(app)

import setupAnalytics from '@/analytics'
import setupBugsnag from '@/bugsnag'
import { setupRouter } from '@/router'
import setupShortcuts from '@/shortcuts'

import routes from './routes'

setupAnalytics(app)
setupBugsnag(app)
setupRouter(app, routes)
setupShortcuts()

import '@/assets/styles/tailwind.css'
import '@/assets/styles/fonts.css'
import '@/assets/styles/app.css'

import 'floating-vue/dist/style.css'
import '@/assets/styles/tooltips.css'

app.mixin({ props: [ 'store' ] })
app.mount('#app')

import useMyStore from '@/stores/me/my'

useMyStore().initialize()
