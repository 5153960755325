import useContentPerspectiveStreamStore from '@/stores/content/perspective/stream'
import useIndexCardsStore from '@/stores/content/index-cards'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyCuratedTopicsStore from '@/stores/me/curated-topics'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTopicsStore from '@/stores/me/topics'
import useTaggingStore from '@/stores/tagging/tagging'

import { defineStore } from 'pinia'

export default defineStore('contentIndexPage', {
    getters: {
        title: () => 'Content',

        streamStore: store => useContentPerspectiveStreamStore(),

        showFilters: () => true
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            this.streamStore.whilePaused(store => {
                store.reset()
                store.initialize(to)
            })
            
            useIndexCardsStore().initialize(to.query.show)

            useMyBookmarksStore().initialize()
            useMyChannelListsStore().initialize()
            useMyCuratedTopicsStore().initialize()
            useMyPerspectivesStore().initialize()
            useMyTopicsStore().initialize()
            useTaggingStore().initialize()
        },

        async afterLeave(to, from) {
            if (this.streamStore.triggeredNavigation) {
                return this.streamStore.triggeredNavigation--
            }

            this.streamStore.whilePaused(store => store.reset())
        }
    }
})
