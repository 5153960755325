import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'

export default defineStore('myAnalyses', {
    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me analyses').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadDetails(id) {
            return api.route('me analyses details', id).get().json(res => {
                Object.assign(this.find(res.data.id), res.data)
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(analysis, data) {
            await api.route('me analyses update', analysis.id)
                .formData({ _method: 'put', ...data })
                .post()
                .json(res => Object.assign(analysis, res.data))

            return this.reload()
        },

        async delete(analysis) {
            return useDeleteConfirmationModal().open('Analysis', analysis, 'me analyses used-by').then(async () => {
                await api.route('me analyses delete', analysis.id).delete().res()
                await this.reload()
            })
        },

        async duplicate(analysis) {
            await api.route('me analyses store')
                .formData({
                    name: analysis.name,
                    type: analysis.type,
                    configuration: JSON.stringify(analysis.configuration)
                })
                .post()
                .json()

            return this.reload()
        }
    }
})
