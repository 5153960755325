<template>
    <ui-modal name="password-confirmation" title="Confirm Account Changes" @opened="opened">
        <ui-form :store="accountSettingsFormStore" v-slot="{ inputs, submitting }">
            <label for="currentPassword" class="block text-sm text-gray-600 mb-2">
                Please enter your current password to confirm the changes:
            </label>

            <div>
                <input type="password" name="currentPassword" id="passwordConfirmationCurrentPassword" class="shadow-xs focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-xs placeholder-gray-400" ref="passwordField" v-model="inputs.current_password">
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button @click="$modal.hide('password-confirmation')">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="blue" :action="save" :disabled="submitting">
                    Confirm
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import {mapStores, mapActions} from 'pinia'
import useAccountSettingsFormStore from "@/stores/settings/account-form"

export default {
    methods: {
        ...mapActions(useAccountSettingsFormStore, ['save' ]),

        opened() {
            this.$refs.passwordField.focus()
        }
    },

    computed: {
        ...mapStores(useAccountSettingsFormStore)
    }
}
</script>
