import api from '../../api'
import { arrayWrap } from '@/helpers/array'

import useMyStore from '@/stores/me/my'

import { defineStore } from 'pinia'

export default defineStore('tagging', {
    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,

        selectedGroup: null,

        streamTags: []
    }),

    getters: {
        tagGroups(store) {
            return store.items
        },

        tags(store) {
            return store.selectedGroup ? store.selectedGroup.tags : store.flattenedTags
        },

        flattenedTags(store) {
            return store.items.flatMap(g => g.tags)
        }
    },

    actions: {
        async initialize() {
            await this.load()

            this.selectedGroup = this.tagGroups.find(g => g.id == useMyStore().preferences['tagging.selectedGroup'])
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me tags').get().json(res => {
                this.items = res.data.map(g => ({ ...g, tags: this.buildTagsTree(g.tags) }))

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        find(id) {
            return this.flattenedTags.find(t => t.id == id)
        },

        findGroup(id) {
            return this.items.find(t => t.id == id)
        },

        appliedTo(item) {
            return arrayWrap(item).reduce((applied, item) => {
                return [ ...applied, ...(item.tags || []).map(t => this.find(t.id)).filter(t => ! applied.includes(t)) ]
            }, [])
        },

        isAppliedTo(item, tag) {
            return this.appliedTo(item).find(t => t.id == tag.id)
        },

        clearTagsOn(item) {
            return arrayWrap(item).some(item => {
                api.route('monitor content tags delete', [ item.id ]).delete().res(() => {
                    item.tags = []
                })
            })
        },

        toggleTagOn(item, tag) {
            let method = this.isAppliedTo(item, tag) ? 'delete' : 'post'

            return arrayWrap(item).some(item => {
                // Consider the tag optimistically applied
                method == 'delete' ? item.tags = item.tags.filter(t => t.id != tag.id) : item.tags = [ ...item.tags, { ...tag } ]

                api.route('monitor content tags store', [ item.id, tag.id ])[method]().res().catch(() => {
                    // Revert the tag if the request failed
                    method == 'delete' ? item.tags = [ ...item.tags, { ...tag } ] : item.tags = item.tags.filter(t => t.id != tag.id)
                })
            })
        },

        appliedToStream(stream) {
            return this.streamTags
        },

        isAppliedToStream(stream, tag) {
            return this.appliedToStream(stream).find(t => t.id == tag.id)
        },

        async clearTagsOnStream(stream) {
            if (! this.streamTags) this.loadStreamTags(stream)

            this.streamTags.forEach(tag => this.toggleTagOnStream(stream, tag))
        },

        toggleTagOnStream(stream, tag) {
            let route = this.isAppliedToStream(stream, tag) ? `monitor content bulk-tags delete` : `monitor content bulk-tags store`
            let method = this.isAppliedToStream(stream, tag) ? 'delete' : 'post'

            return api.route(route, { id: tag.id }).query({ filters: stream.filters.toJson() })[method]().res(() => {
                [ ...(stream.items.data || []) ].forEach(item => {
                    if (method == 'delete') {
                        item.tags = item.tags.filter(t => t.id != tag.id)
                    } else {
                        item.tags = [ ...item.tags, { ...tag } ]
                    }
                })

                if (method == 'delete') {
                    this.streamTags = this.streamTags.filter(t => t.id != tag.id)
                } else {
                    this.streamTags = [ ...this.streamTags, { ...tag } ]
                }
            })
        },

        selectGroup(group) {
            this.selectedGroup = group

            useMyStore().updatePreferences({ 'tagging.selectedGroup': group })
        },

        loadStreamTags(stream) {
            return api.route('monitor content bulk-tags index').query({ filters: stream.filters.toJson() }).get().json(res => {
                this.streamTags = res.data
            })
        },

        resetStreamTags(stream) {
            this.streamTags = []
        },

        buildTagsTree(tags) {
            return tags
                .filter(tag => ! tag.parentId)
                .map(tag => ({ ...tag, children: tags.filter(c => c.parentId == tag.id) }))
        }
    }
})
