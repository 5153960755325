<template>
    <ui-modal name="alert-edit" title="Alert" :max-width="640" max-height="90vh" @opened="opened" @closed="cancel" unstyled content-class="bg-white border border-gray-200 shadow-xl flex flex-col h-auto w-full rounded-xl">
        <div class="flex items-center justify-between px-10 pt-6">
            <h1 class="text-lg font-medium text-gray-700">
                Alert
            </h1>

            <a href="#" class="text-xl text-gray-700 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('alert-edit')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>
        <ui-form :store="alertsModalsAlertEditStore" v-slot="{ inputs, submitting, errors }" class="flex-1 min-h-0 overflow-y-auto divide-y divide-gray-200 rounded-b-xl">
            <div class="px-10 py-8 space-y-4">
                <div>
                    <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                        Name
                    </label>

                    <div>
                        <ui-input type="text" name="name" id="name" :placeholder="defaultName" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                    </div>
                </div>

                <div>
                    <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                        Type
                    </label>

                    <div v-if="type" class="flex items-center w-full px-5 py-2 border border-gray-300 rounded-lg text-lg">
                        <div>
                            <ui-icon :name="type.icon" class="text-3xl"></ui-icon>
                        </div>
                        <div class="px-4">
                            <h1 class="text-lg font-medium">{{type.name}}</h1>
                            <p class="text-sm text-gray-700">{{type.description}}</p>
                        </div>
                    </div>
                </div>

                <div>
                    <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                        Subject
                    </label>

                    <div class="border border-gray-300 rounded-lg group relative">
                        <ui-async v-if="inputs.subject" :guards="subject">
                            <component v-if="subject" :is="subjectComponent" :channel="subject" :content="subject" :list="subject" :perspective="subject" mode="simple" class="p-2">
                            </component>

                            <a v-if="! lockSubjectSelection" @click.prevent="selectSubject" class="items-center w-full px-5 py-4 cursor-pointer text-gray-700 hover:text-gray-900 bg-gray-50/95 bg-blur rounded-lg text-lg group absolute inset-0 transition flex opacity-0 hover:opacity-100" href="#">
                                <div class="pr-4">
                                    <h1 class="text-lg font-medium">Choose a subject</h1>
                                </div>
                                <div class="ml-auto">
                                    <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                                </div>
                            </a>
                        </ui-async>

                        <a v-else @click.prevent="selectSubject" class="flex items-center w-full px-5 py-4 cursor-pointer text-gray-700 hover:text-gray-900 text-lg group" href="#">
                            <div class="pr-4">
                                <h1 class="text-lg font-medium">Choose a subject</h1>
                            </div>
                            <div class="ml-auto">
                                <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="px-10 py-8 bg-gray-50 border-t border-gray-100 rounded-b-xl">
                <div class="flex justify-center items-center h-8 mb-6">
                    <div class="border border-gray-300 rounded-md font-medium flex h-full">
                        <a @click.prevent="activeTab = 'delivery'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'delivery' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                            Delivery
                        </a>
                        <a @click.prevent="activeTab = 'recipients'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'recipients' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                            Recipients
                        </a>
                        <a @click.prevent="activeTab = 'settings'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'settings' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                            Settings
                        </a>
                    </div>
                </div>

                <edit-settings v-if="activeTab == 'settings'" :store="alertsModalsAlertEditStore"></edit-settings>
                <edit-recipients v-if="activeTab == 'recipients'" :store="alertsModalsAlertEditStore"></edit-recipients>
                <edit-delivery v-if="activeTab == 'delivery'" :store="alertsModalsAlertEditStore"></edit-delivery>

                <div class="flex justify-end space-x-4 mt-8 p-3">
                    <ui-button color="red" class="mr-auto" :action="deleteAlert" :disabled="submitting" v-if="alert?.id">
                        Delete
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        {{ alert?.id ? 'Save' : 'Create' }}
                    </ui-button>
                </div>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import EditDelivery from './edit-delivery'
import EditRecipients from './edit-recipients'
import EditSettings from './edit-settings'

import ChannelCard from '@/components/channels/stream-cards/channel'
import ContentCard from '@/components/content/stream-cards/content'
import ListCard from '@/components/channels/stream-cards/list'
import PerspectiveCard from '@/components/content/stream-cards/perspective'

import useAlertEditStore from '@/stores/alerts/modals/alert-edit'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        EditDelivery, EditRecipients, EditSettings
    },

    data: () => ({
        activeTab: 'delivery'
    }),

    computed: {
        ...mapStores(useAlertEditStore),
        ...mapState(useAlertEditStore, [ 'alert', 'defaultName', 'lockSubjectSelection', 'subject', 'type' ]),

        subjectComponent() {
            return {
                'channels': ChannelCard,
                'content': ContentCard,
                'lists': ListCard,
                'perspectives': PerspectiveCard
            }[useAlertEditStore().inputs.subject?.family]
        }
    },

    methods: {
        ...mapActions(useAlertEditStore, [ 'cancel', 'save', 'selectSubject' ]),
        ...mapActions(useAlertEditStore, { deleteAlert: 'delete' }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
