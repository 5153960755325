import { asyncResource, content } from '@/api'
import { date } from '@/helpers/datetime'
import number from '@/helpers/number'

import { defineStore } from 'pinia'

export default defineStore('alertsTriggerSentimentShift', {
    state: () => ({
        trigger: null,

        content: asyncResource({
            method: 'post',
            request: (api, store, payload) => content()
                .ids(store.trigger.meta.content)
                .query(payload)
                .sorting('date-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date)
    }),

    getters: {
        analysisOptions: store => ({
            boost: { useGPUTranslations: true },
            chart: {
                type: 'spline',
                resetZoomButton: { position: { x: 0, y: 0 } },
                spacing: [2, 0, 2, 0],
                zoomType: 'x'
            },
            credits: { enabled: false },
            exporting: {
                chartOptions: {
                    chart: {
                        spacing: [40, 40, 40, 40]
                    }
                },
                buttons: {
                    contextButton: { enabled: false }
                }
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: 'rgb(44, 64, 76)', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '12px', fontWeight: '500', textOverflow: 'ellipsis'
                },
                labelFormatter: function () { return `<span style="color:${this.color}">${this.name}</span>` },
                margin: 20,
                padding: 0
            },
            plotOptions: {
                column: {
                    marker: { enabled: false },
                    fillOpacity: 1
                },
                series: {
                    animation: true,
                    connectNulls: true,
                    dataLabels: { enabled: false },
                    enableMouseTracking: true,
                    marker: {
                        enabled: false,
                        enabledThreshold: 0,
                        fillColor: '#fff',
                        lineColor: null,
                        lineWidth: 2,
                        radius: 4,
                        symbol: 'circle'
                    },
                    states: {
                        hover: { lineWidthPlus: 0 }
                    },
                    stacking: 'normal',
                    turboThreshold: 2500,
                    borderRadius: { radius: 8 }
                }
            },
            series: [
                {
                    name: 'Positive',
                    type: 'column',
                    color: '#10b981',
                    threshold: 0,
                    dashStyle: 'solid',
                    data: [ ...store.trigger.meta.snapshots ].reverse().map(s => ({ x: Date.parse(s.date), y: s.content.positive })),
                    yAxis: 'sentiment',
                    opacity: 0.75
                },
                {
                    name: 'Negative',
                    type: 'column',
                    color: '#f43f5e',
                    threshold: 0,
                    dashStyle: 'solid',
                    data: [ ...store.trigger.meta.snapshots ].reverse().map(s => ({ x: Date.parse(s.date), y: -s.content.negative })),
                    yAxis: 'sentiment',
                    opacity: 0.75
                },
                {
                    name: 'Neutral',
                    type: 'column',
                    color: '#9ca3af',
                    threshold: 0,
                    visible: false,
                    dashStyle: 'solid',
                    data: [ ...store.trigger.meta.snapshots ].reverse().map(s => ({ x: Date.parse(s.date), y: s.content.neutral })),
                    yAxis: 'sentiment',
                    opacity: 0.75
                },
                {
                    name: 'Average',
                    type: 'spline',
                    color: '#6366f1',
                    threshold: 0,
                    dashStyle: 'solid',
                    data: [ ...store.trigger.meta.snapshots ].reverse().map(s => ({ x: Date.parse(s.date), y: s.content.average })),
                    yAxis: 'ratio',
                    opacity: 1
                }
            ],
            title: {
                text: ''
            },
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () { return `${date(this.x)}<br><strong>${number(this.y)}</strong>` },
                shadow: false,
                style: { color: '#fff', textAlign: 'center' }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [{
                gridLineColor: '#e0e5e8',
                id: 'sentiment',
                min: - Math.max(...store.trigger.meta.snapshots.flatMap(snapshot => [ snapshot.content.positive, snapshot.content.negative, snapshot.content.neutral ])),
                max: Math.max(...store.trigger.meta.snapshots.flatMap(snapshot => [ snapshot.content.positive, snapshot.content.negative, snapshot.content.neutral ])),
                labels: {
                    enabled: false,
                    padding: 0,
                    style: { color: '#b8c3c9' },
                    x: 1,
                    y: 14,
                    zIndex: 1
                },
                startOnTick: false,
                endOnTick: false,
                tickPixelInterval: 50,
                title: { text: '' }
            }, {
                gridLineWidth: 0,
                id: 'ratio',
                min: -1,
                max: 1,
                labels: {
                    enabled: false,
                    padding: 0,
                    style: { color: '#b8c3c9' },
                    x: 1,
                    y: 14,
                    zIndex: 1
                },
                opposite: true,
                startOnTick: false,
                endOnTick: false,
                tickPixelInterval: 50,
                title: { text: '' }
            }]
        })
    },

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.content.reset()
            this.content.fetchFirst(this)
        },

        async loadMore(infiniteScroll) {
            if (this.content.isFetching) return infiniteScroll?.loaded()

            let items = await this.content.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})
