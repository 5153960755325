<template>
    <ui-modal name="export-options" title="Export content">
        <label for="limit" class="block text-sm font-medium text-gray-700">
            Pick the maximum amount of rows
        </label>
        <div class="mt-2">
            <ui-input id="limit" name="limit" type="number" max="1000" v-model="limit"></ui-input>
        </div>
        <div class="mt-2 text-sm text-gray-700">
            The maximum amount for this export is 1000
        </div>

        <div class="flex items-center">
            <div class="w-2/3 mr-3">
                <label for="filename" class="block text-sm font-medium text-gray-700 mt-4">
                    Pick the output file name
                </label>
                <div class="mt-2">
                    <ui-input id="filename" name="filename" type="text" @keydown="validateFilename" v-model="filename" :errors="filenameError"></ui-input>
                </div>
            </div>
            <div class="w-1/3">
                <label for="format" class="block text-sm font-medium text-gray-700 mt-4">
                    Pick the output file format
                </label>
                <div class="mt-2">
                    <select id="format" name="format" class="shadow-xs focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-xs h-9" v-model="format">
                        <option value="csv">CSV</option>
                        <option value="xlsx">XLSX</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="format === 'xlsx'" class="mt-2 text-sm text-gray-700">
            XLSX format limits the maximum amount of characters in a cell to 32,767. Some of the longer content may be truncated.
        </div>

        <label class="flex items-center space-x-3 text-gray-700 mt-4">
            <ui-switch v-model="includeNotes" size="sm"></ui-switch>
            <span>Include notes</span>
        </label>

        <div v-if="includeNotes" class="pl-4">
            <label class="flex items-center space-x-3 text-gray-700 mt-4">
                <ui-switch v-model="includePersonalNotes" size="sm"></ui-switch>
                <span>Include personal notes</span>
            </label>
        </div>

        <label class="flex items-center space-x-3 text-gray-700 mt-4">
            <ui-switch v-model="utc" size="sm"></ui-switch>
            <span>Export dates in UTC instead of the local timezone</span>
        </label>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="blue" icon="download" @click.prevent="doExport" :disabled="isExporting">
                {{ isExporting ? 'Exporting...' : 'Export' }}
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useModalsExportOptionsStore from '../../stores/modals/export-options'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    data: () => ({
        filenameError: null
    }),

    computed: {
        ...mapState(useModalsExportOptionsStore, [
            'isExporting'
        ]),

        ...mapWritableState(useModalsExportOptionsStore, [
            'limit',
            'filename',
            'format',
            'utc',
            'includeNotes',
            'includePersonalNotes'
        ])
    },

    methods: {
        ...mapActions(useModalsExportOptionsStore, [
            'doExport'
        ]),

        validateFilename(event) {
            if (/^[A-Za-z0-9_]+$/.test(event.key)) {
                this.filenameError = null
            } else {
                this.filenameError = [ 'Only letters, numbers and underscores are allowed' ]
                event.preventDefault()
            }

            if (this.filename.length > 96) {
                this.filenameError = [ 'The filename cannot be longer than 96 characters' ]
                event.preventDefault()
            }
        }
    }
}
</script>
