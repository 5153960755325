<template>
    <help-content :title="title" :content="content"></help-content>
</template>

<script>
import HelpContent from './content'

import useSupportWhatsNewStore from '@/stores/support/whats-new'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        HelpContent
    },

    computed: {
        ...mapState(useSupportWhatsNewStore, [ 'title', 'content' ]),
    },

    methods: {
        ...mapActions(useSupportWhatsNewStore, [ 'click' ])
    }
}
</script>
